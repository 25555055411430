import { colors, responsiveFontSizes } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { createTheme as createMuiTheme } from '@mui/material/styles';

import { THEMES } from '../constants';
import customColors, { grey } from '../styles/colors';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';
import { components } from './overrides';

const baseOptions = {
  direction: 'ltr',
  typography,
  props: {
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
      fullWidth: true,
    },
  },
  components: {
    ...components,
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#3C3C3B',
          fontSize: '0.75rem',
        },
      },
    },
  },
};
const themesOptions = [
  {
    name: THEMES.LIGHT,
    styleOverrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
      MuiButton: {
        root: {
          color: 'primary',
        },
      },
    },
    palette: {
      primary: {
        light: customColors['brand-light'],
        main: customColors['brand-color'],
        dark: customColors['brand-dark'],
        contrastText: customColors['#fff'],
      },
      secondary: {
        light: customColors['secondary-light'],
        main: customColors['secondary-color'],
        dark: customColors['secondary-dark'],
        contrastText: customColors['secondary-contrast-text'],
      },
      error: {
        light: customColors['state-error-light'],
        main: customColors['state-error'],
        dark: customColors['state-error-dark'],
      },
      warning: {
        light: customColors['state-warning-light'],
        main: customColors['state-warning'],
        dark: customColors['state-warning-dark'],
      },
      info: {
        light: customColors['state-info-light'],
        main: customColors['state-info'],
        dark: customColors['state-info-dark'],
      },
      success: {
        light: customColors['state-success-light'],
        main: customColors['state-success'],
        dark: customColors['state-success-dark'],
      },
      grey,
      purple: customColors['acc-purple'],
      'dark-green': customColors['acc-dark-green'],
      'light-green': customColors['acc-light-green'],
      pink: customColors['acc-pink'],
      yellow: customColors['acc-yellow'],
      darkGray: customColors['acc-dark-gray'],
      darkerGreen: customColors['acc-darker-green'],
      white: '#FFFFFF',
      black: customColors['brand-black'],
      text: {
        primary: '#1B1B1A',
        secondary: '#686767',
        tertiary: grey[600],
        disabled: 'rgba(0, 0, 0, 0.38)',
      },
      background: {
        default: '#fafafa',
        grey: '#ECECEC',
        green: '#76ab2b33',
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34',
      },
      primary: {
        main: '#8a85ff',
      },
      secondary: {
        main: '#8a85ff',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
      },
    },
    shadows: strongShadows,
  },
  {
    name: THEMES.UNICORN,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d',
      },
      primary: {
        main: '#a67dff',
      },
      secondary: {
        main: '#a67dff',
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4',
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    deepmerge(baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
