import React from 'react';
import customColors from '../../styles/colors';

function ProjectIcon(props) {
  const {
    height = 24,
    width = 24,
    strokeColor = customColors['secondary-dark'],
    strokeWidth = 1.5,
    fill = 'none',
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1,0,0,1,0,0)">
        <path
          d="M5.25,2.249h-3a1.5,1.5,0,0,0-1.5,1.5v18a1.5,1.5,0,0,0,1.5,1.5h19.5a1.5,1.5,0,0,0,1.5-1.5v-18a1.5,1.5,0,0,0-1.5-1.5H11.25"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M11.25,11.249,8.25,9l-3,2.25V1.5A.75.75,0,0,1,6,.749h4.5a.75.75,0,0,1,.75.75Z"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M5.25 18.749L15.75 18.749"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M5.25 14.249L18.75 14.249"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M18.75 9.749L14.25 9.749"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        ></path>
      </g>
    </svg>
  );
}

export default ProjectIcon;
