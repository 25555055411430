import { RangeCap } from './TimerangePicker.types';
import { differenceInDays, addMonths } from 'date-fns';
import { Range } from 'types';

// Function to check if the current range exceeds the rangeCap
export const isRangeExceedingCap = (range: Range, rangeCap: RangeCap) => {
  const { from, to } = range ?? {};
  const { days, weeks, months } = rangeCap ?? {};

  if (!from || !to) {
    return false;
  }

  const totalDays = differenceInDays(to, from);

  if (days) {
    return totalDays > days;
  } else if (weeks) {
    const maxDays = weeks * 7;
    return totalDays > maxDays;
  } else if (months) {
    // Check if the end date is beyond the allowed month range
    const maxEndDate = addMonths(from, months);
    return to > maxEndDate;
  }

  return false;
};

export const formatRangeCap = (
  rangeCap?: RangeCap
): { msg: string; options: { value: number; metric: string } } | null => {
  if (!rangeCap) {
    return null;
  }
  const { days, weeks, months } = rangeCap ?? {};
  let options = { value: 0, metric: '' };
  if (days) {
    options = { value: days, metric: 'day(s)' };
  }
  if (weeks) {
    options = { value: weeks, metric: 'week(s)' };
  }
  if (months) {
    options = { value: months, metric: 'month(s)' };
  }
  return { msg: 'main:widgets.timerangePicker.capExceeded', options };
};
