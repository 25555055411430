import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Breadcrumbs, Grid, Link, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';

export function Breadcrumb() {
  const { pathname } = useLocation();

  const pathItems = pathname.split('/');
  const { t } = useTranslation();
  const getTranslation = (item) => {
    const translation = t(`breadCrumbs.${item}`);
    // check if translation exits otherwise return the item and log a warning
    if (translation.startsWith('breadCrumbs')) {
      process.env.NODE_ENV !== 'production' &&
        console.warn(
          `Translation for ${item} does not exist! Please add a translation to the translation file,
        so that breadcrumb can be translated correctly.`
        );
      return item;
    } else {
      return translation;
    }
  };

  return (
    <Box sx={{ overFlow: 'hidden', pb: 2 }}>
      <Grid container>
        <Grid item>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {pathItems.map(
              (item, i) =>
                item !== '' && (
                  <Link
                    key={`breadcrumb-${i}`}
                    variant="body1"
                    color="inherit"
                    to={`/${pathItems.slice(1, i + 1).join('/')}`}
                    component={RouterLink}
                  >
                    {getTranslation(item)}
                  </Link>
                )
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
    </Box>
  );
}
