import { useMutation, MutationFunction } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAxios } from 'hooks/useAxios';
import { mutateEdgeMapper, getEdgeMapper } from '../microgrid.utils';
import { ApiError } from 'types';
import { Edge, EdgeCreate } from '../microgrid.types';
import { keysToCamelCase } from 'utils/formatters';

export function useCreateEdge(projectId: string, microgridId: string) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { post } = useAxios();

  const path = `/projects/${projectId}/microgrids/${microgridId}/edges/`;

  const fetchData: MutationFunction<EdgeCreate, Edge> = async (edge) => {
    const response = await post(path, mutateEdgeMapper(edge));
    return getEdgeMapper(keysToCamelCase(response.data));
  };

  const {
    mutateAsync: createEdge,
    status,
    error,
    data,
    isLoading,
    isError,
    ...others
  } = useMutation<EdgeCreate, ApiError, Edge>(fetchData, {
    onError: (error, vars) => {
      enqueueSnackbar(
        t('genericErrors.create', {
          entity: t('entities:microgrid.nameSingular').toLowerCase(),
          identifier: vars?.organisation?.name,
        }) + `: ${error}`,
        { variant: 'error' }
      );
    },
  });

  return {
    createEdge,
    status,
    error,
    data,
    isLoading,
    isError,
    ...others,
  };
}
