import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton as MuiIB, Tooltip } from '@mui/material';

const IconButton = styled(MuiIB)(() => ({
  height: 18,
  width: 18,
  color: 'white',
}));

export default function Glossary() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const glossaryUrl = `/glossary`;

  return (
    <Tooltip title={t('navigation.glossary')}>
      <IconButton
        display="flex"
        onClick={() => navigate(glossaryUrl)}
        size="large"
      >
        <InfoOutlinedIcon alt="Glossary" />
      </IconButton>
    </Tooltip>
  );
}
