import customColors from 'styles/colors';

export const components = {
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        '&.MuiButton-contained': {
          color: 'white',
        },
      },
    },
    variants: [
      {
        props: { variant: 'contained', color: 'primary' },
        style: {
          color: 'white',
        },
      },
      {
        props: { variant: 'outlined', color: 'secondary' },
        style: {
          borderColor: '#000000d9',
          color: '#000000d9',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            borderColor: '#000000d9',
          },
        },
      },
    ],
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: 12,
        overflow: 'hidden',
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      colorPrimary: {
        color: '#6ba643',
      },
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        margin: 20,
        fontSize: 16,
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 32,
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontSize: '1rem',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      message: {
        width: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: 'auto',
      },
      filledSuccess: {
        backgroundColor: 'rgba(156,216,113, 0.15)',
        color: customColors['brand-black'],
      },
      filledInfo: {
        backgroundColor: 'rgba(105,193,255, 0.15)',
        color: customColors['brand-black'],
      },
      filledWarning: {
        backgroundColor: 'rgba(255,241,78, 0.15)',
        color: customColors['brand-black'],
      },
      filledError: {
        backgroundColor: 'rgb(255,72,78, 0.15)',
        color: customColors['brand-black'],
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        backgroundColor: '#FFFFFF',
      },
      columnHeaderTitle: { fontWeight: 600 },
    },
  },
};
