import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  ButtonBase,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import useAuth from 'hooks/useAuth';

import LanguageSelector from '../LanguageSelector';

const PREFIX = 'Account';

const classes = {
  avatar: `${PREFIX}-avatar`,
  popover: `${PREFIX}-popover`,
};

const StyledDiv = styled('div')(({ theme }) => ({
  [`& .${classes.avatar}`]: {
    height: 24,
    width: 24,
    marginRight: theme.spacing(1),
  },

  [`& .${classes.popover}`]: {
    width: 200,
  },
}));

export function Account() {
  const navigate = useNavigate();
  const ref = useRef(null);
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogout = () => {
    try {
      handleClose();
      logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  const userUrl = `/users/${user?.username}`;

  return (
    <StyledDiv>
      <Tooltip title={t('navigation.account')}>
        <IconButton
          style={{ display: 'flex' }}
          component={ButtonBase}
          onClick={handleOpen}
          ref={ref}
          size="large"
          data-cy="user-account-button"
        >
          <Avatar alt="User" className={classes.avatar} src={user?.avatar} />
        </IconButton>
      </Tooltip>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem sx={{ pointerEvents: 'none' }}>
          <Typography variant="h6" color="inherit">
            {user?.firstName}
          </Typography>
        </MenuItem>
        <Divider />
        <LanguageSelector />
        <MenuItem component={Link} to={userUrl}>
          {t('navigation.account')}
        </MenuItem>
        <MenuItem onClick={handleLogout} data-cy="logout-button">
          {t('navigation.logout')}
        </MenuItem>
        <MenuItem disabled>
          <Typography variant="body1">
            {t('page.version', {
              version: process.env.REACT_APP_VERSION || 'Development',
            })}
          </Typography>
        </MenuItem>
      </Menu>
    </StyledDiv>
  );
}
