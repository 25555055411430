import React from 'react';
import customColors from '../../styles/colors';

function UnitControlIcon(props) {
  const {
    height = 24,
    width = 24,
    strokeColor = customColors['secondary-dark'],
    strokeWidth = 1.5,
    fill = 'none',
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.70002 12V6.112C5.69924 5.84912 5.76756 5.59065 5.89814 5.36249C6.02872 5.13433 6.21697 4.94449 6.44402 4.812L14.7 0L22.956 4.816C23.1831 4.94849 23.3713 5.13833 23.5019 5.36649C23.6325 5.59465 23.7008 5.85312 23.7 6.116V18C23.7 18.3978 23.542 18.7794 23.2607 19.0607C22.9794 19.342 22.5978 19.5 22.2 19.5H12.763"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.888 19.904C3.63399 19.1582 4.64565 18.7393 5.7005 18.7393C6.75535 18.7393 7.76701 19.1582 8.513 19.904"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.20001 17.239C2.39349 16.0455 4.01219 15.3751 5.70001 15.3751C7.38784 15.3751 9.00653 16.0455 10.2 17.239"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.70001 21.75C5.77418 21.75 5.84668 21.772 5.90835 21.8132C5.97002 21.8544 6.01808 21.913 6.04647 21.9815C6.07485 22.05 6.08228 22.1254 6.06781 22.1982C6.05334 22.2709 6.01762 22.3377 5.96518 22.3902C5.91273 22.4426 5.84591 22.4783 5.77317 22.4928C5.70043 22.5073 5.62503 22.4998 5.55651 22.4715C5.48798 22.4431 5.42942 22.395 5.38821 22.3333C5.34701 22.2717 5.32501 22.1992 5.32501 22.125C5.32501 22.0255 5.36452 21.9302 5.43485 21.8598C5.50517 21.7895 5.60056 21.75 5.70001 21.75Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7 5.25V9"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.518 7.31799C10.8887 7.94733 10.4601 8.74916 10.2865 9.62207C10.1128 10.495 10.202 11.3998 10.5426 12.222C10.8832 13.0443 11.4599 13.7471 12.2 14.2416C12.94 14.736 13.81 15 14.7 15C15.59 15 16.4601 14.736 17.2001 14.2416C17.9401 13.7471 18.5169 13.0443 18.8575 12.222C19.1981 11.3998 19.2872 10.495 19.1136 9.62207C18.9399 8.74916 18.5113 7.94733 17.882 7.31799"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UnitControlIcon;
