import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
  AppBar as MuiAppbar,
  Box,
  IconButton,
  SvgIcon,
  Toolbar as MuiToolbar,
  Tooltip,
} from '@mui/material';
import { MenuIcon } from '../../icons';
import { THEMES } from '../../../constants';
import { GreenerLogo } from 'components/assets/GreenerLogo';
import Projects from './Projects';
import { ManagementSelection } from './ManagementSelection';
import UnitControl from './UnitControl';
import { NavLink } from 'react-router-dom';
import TopBarSearch from './TopBarSearch';
import Glossary from './Glossary';
import { Account } from './Account/';

const AppBar = styled(MuiAppbar)(({ theme }) => ({
  position: 'fixed',
  ...(theme.name === THEMES.LIGHT
    ? {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.dark,
      }
    : {}),
  ...(theme.name === THEMES.ONE_DARK
    ? {
        backgroundColor: theme.palette.background.default,
      }
    : {}),
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  color: theme.palette.white,
  backgroundColor: theme.palette.secondary,
  display: 'flex',
  justifyContent: 'space-between',
}));

export function Topbar({ toggleSidebar, openSearchDrawer, ...rest }) {
  const { t } = useTranslation();

  return (
    <AppBar {...rest}>
      <Toolbar color="secondary" variant="dense">
        <Box>
          <Tooltip title={t('navigation.sidebar')}>
            <IconButton color="inherit" onClick={toggleSidebar} size="large">
              <SvgIcon style={{ fontSize: 18 }}>
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Projects />
          <UnitControl />
        </Box>
        <Box component="div" sx={{ display: { xs: 'none', md: 'inline' } }}>
          <Box>
            <NavLink to="/">
              <GreenerLogo />
            </NavLink>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TopBarSearch openSearchDrawer={openSearchDrawer} />
          <ManagementSelection />
          <Glossary />
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
