import { ManagementLayout } from './Management.layout';
import ErrorFallback from 'components/fallback/ErrorFallback';
import './Users/translations';

export const managementRoutes = [
  {
    path: '*',
    element: <ManagementLayout />,
    errorElement: <ErrorFallback />,
    children: [
      {
        path: 'users',
        lazy: () => import('pages/Management/Users'),
      },
      {
        path: 'users/:username',
        lazy: () => import('pages/Management/Users/[User]'),
      },
      {
        path: 'add-user',
        lazy: () => import('pages/Management/Users/AddUser'),
      },
      {
        path: 'user-roles',
        lazy: () => import('pages/Management/UserRoles'),
      },
      {
        path: 'user-roles/:userRoleExtId',
        lazy: () => import('pages/Management/UserRoles/[UserRole]'),
      },
      {
        path: 'add-user-role',
        lazy: () => import('pages/Management/UserRoles/AddUserRole'),
      },
      {
        path: 'organisations',
        lazy: () => import('pages/Management/Organisations'),
      },
      {
        path: 'organisations/:organisationExtId',
        lazy: () => import('pages/Management/Organisations/[Organisation]'),
      },
      {
        path: 'add-organisation',
        lazy: () => import('pages/Management/Organisations/AddOrganisation'),
      },

      {
        path: 'greener-units/',
        lazy: () => import('pages/Management/GreenerUnits'),
      },
      {
        path: 'greener-units/:greenerUnitExtId',
        lazy: () => import('pages/Management/GreenerUnits/[GreenerUnit]'),
      },
      {
        path: 'add-greener-unit',
        lazy: () => import('pages/Management/GreenerUnits/AddGreenerUnit'),
      },

      {
        path: 'greener-eyes',
        lazy: () => import('pages/Management/GreenerEyes'),
      },
      {
        path: 'greener-eyes/:greenerEyeExtId',
        lazy: () => import('pages/Management/GreenerEyes/[GreenerEye]'),
      },

      {
        path: 'add-greener-eye',
        lazy: () => import('pages/Management/GreenerEyes/AddGreenerEye'),
      },
      {
        path: 'watcher-types',
        lazy: () => import('pages/Management/WatcherTypes'),
      },
      {
        path: 'watcher-types/:externalIdentifier',
        lazy: () => import('pages/Management/WatcherTypes/[WatcherType]'),
      },
      {
        path: 'add-watcher-type',
        lazy: () => import('pages/Management/WatcherTypes/AddWatcherType'),
      },
      {
        path: 'device-models',
        lazy: () => import('pages/Management/DeviceModels'),
      },
      {
        path: 'device-models/:id',
        lazy: () => import('pages/Management/DeviceModels/[DeviceModel]'),
      },
      {
        path: 'add-device-model',
        lazy: () => import('pages/Management/DeviceModels/AddDeviceModel'),
      },
    ],
  },
];
