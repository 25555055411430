import { QueryKeys } from './services.types';
/**
 * This function is used to generate query keys for useQuery based on the path provided.
 * The path is split by '/' and the resulting array is used as the main key.
 * So for example, if the path is '/projects/1/microgrids/1', the main key will be ['projects', '1', 'microgrids', '1'].
 * The list and detail keys are generated by appending 'list' and 'detail' to the main key.
 * @param {string} path
 * @returns object with mainKey, list and detail keys
 */
export const getQueryKeys = (path: string): QueryKeys => {
  const mainKey = path.split('/').filter((item) => item);
  return {
    mainKey,
    list: (filters) => [...mainKey, 'list', { filters }],
    detail: [...mainKey, 'detail'],
  };
};
