import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  TextField,
  Typography,
  Divider,
} from '@mui/material';
import { XCircle as XIcon } from 'react-feather';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { READ_SEARCH, SEARCH } from '../../../services/gql/search';
import { SearchIcon } from '../../icons';

const PREFIX = 'SearchDrawer';

const classes = {
  drawer: `${PREFIX}-drawer`,
};

const StyledDrawer = styled(Drawer)(() => ({
  [`& .${classes.drawer}`]: {
    width: 500,
    maxWidth: '100%',
  },
}));

function SearchResultHeader({ title }) {
  return (
    <>
      <Typography variant={'h5'}>{title + ': '}</Typography>
      <Divider style={{ marginBottom: '1em' }} />
    </>
  );
}

function SearchResult({ title, subtitle = null, description, to }) {
  let titleText = title;
  if (subtitle) {
    titleText = title + ' (' + subtitle + ')';
  }

  return (
    <Grid container direction="row" spacing={2} style={{ marginLeft: '1em' }}>
      <Grid item spacing={1}>
        <Link variant="h5" color="textPrimary" component={RouterLink} to={to}>
          {titleText}
        </Link>
      </Grid>
      <Grid item spacing={1}>
        <Typography
          variant="h5"
          color="textPrimary"
          style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function SearchDrawer({
  isSearchDrawerOpen,
  setIsSearchDrawerOpen,
  ...props
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchString, setSearchString] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [processedResults, setProcessedResults] = useState({
    projects: [],
    units: [],
  });

  const handleClose = () => {
    setIsSearchDrawerOpen(false);
  };

  const { refetch } = useGQLQuery(
    [SEARCH, searchString],
    READ_SEARCH,
    { searchString: searchString },
    { enabled: false, suspense: false } // Disable fetch on render, only allow refetch.
  );

  const handleKeyUp = async (event) => {
    if (event.code === 'Enter') {
      await handleSearch();
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const { data } = await refetch();
      setProcessedResults(data.search);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('main:genericErrors.somethingWentWrong'), {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDrawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      ModalProps={{ BackdropProps: { invisible: true } }}
      onClose={handleClose}
      open={isSearchDrawerOpen}
      variant="temporary"
      {...props}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" color="textPrimary">
              {t('main:navigation.search')}
            </Typography>
            <IconButton onClick={handleClose} size="large">
              <SvgIcon fontSize="small" color="secondary">
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              data-cy="drawer-search-bar"
              InputProps={{
                autoFocus: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => setSearchString(event.target.value)}
              onKeyUp={handleKeyUp}
              placeholder={t('main:search.placeholder')}
              value={searchString}
              variant="outlined"
            />
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSearch}
              data-cy="drawer-search-button"
            >
              Search
            </Button>
          </Box>
          <Box mt={4}>
            {isLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                {/* Render project search results */}
                {processedResults.projects?.length > 0 && (
                  <SearchResultHeader title={t('main:navigation.projects')} />
                )}
                {processedResults.projects.map((result, i) => (
                  <Box key={i} mb={2}>
                    <SearchResult
                      title={result.externalIdentifier}
                      description={result.name}
                      to={'/projects/' + result.externalIdentifier}
                    />
                  </Box>
                ))}
                <br />
                {/* Render unit search results */}
                {processedResults.units?.length > 0 && (
                  <SearchResultHeader title={t('main:navigation.units')} />
                )}
                {processedResults.units.map((result, i) => (
                  <Box key={i} mb={2}>
                    <SearchResult
                      title={result.externalIdentifier}
                      description={result.name}
                      to={'/assets/' + result.externalIdentifier}
                    />
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>
      </PerfectScrollbar>
    </StyledDrawer>
  );
}
