import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import { Grid, Button, Typography } from '@mui/material';
import Panel from 'components/panels/Panel/Panel';
import MaintenanceIcon from './maintenance.svg';

const ErrorFallback = ({
  error,
  resetError,
}: {
  error: Sentry.ReportDialogOptions | undefined;
  resetError: () => void;
}) => {
  return (
    <Panel>
      <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item>
          <Typography variant="h4">
            {i18n.t('components:errorFallback.title')}
          </Typography>
        </Grid>
        <Grid item>
          <img src={MaintenanceIcon} alt="Maintenance" />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="contained"
              sx={{ color: 'white' }}
              onClick={() => {
                /* When resetError() is called it will remove the Fallback component */
                /* and render the Sentry ErrorBoundary's children in their initial state */
                resetError();
              }}
            >
              {i18n.t('components:errorFallback.resetButton')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => Sentry.showReportDialog(error)}
            >
              {i18n.t('components:errorFallback.reportButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Panel>
  );
};

export default ErrorFallback;
