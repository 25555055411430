import * as yup from 'yup';
import i18next from 'i18next';

const yupValidationMessages = () => {
  yup.setLocale({
    mixed: {
      required: i18next.t('validations.required'),
      email: i18next.t('validations.email.invalid'),
      notType: function notType(_ref) {
        switch (_ref.type) {
          case 'number':
            return i18next.t('validations.number.notType');
          case 'date':
            return i18next.t('validations.date.notType');
          default:
            return i18next.t('validations.notType');
        }
      },
    },
    string: {
      email: i18next.t('validations.email.invalid'),
    },
  });
};

export default yupValidationMessages;
