import { useEffect } from 'react';
import nprogress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: theme.spacing(3),
}));

export function LoadingScreen() {
  useEffect(() => {
    nprogress.start();

    return () => {
      nprogress.done();
    };
  }, []);

  return (
    <StyledDiv data-testid='loading-screen'>
      <Box>
        <CircularProgress />
      </Box>
    </StyledDiv>
  );
}
