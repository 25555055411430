import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Avatar as MuiAvatar } from '@mui/material';
import React from 'react';

const Avatar = styled(MuiAvatar)({
  height: 'auto',
  minHeight: 104, // 0.5 * (240 - 16*2) = 104
  maxHeight: 188, // 0.9 * (240 - 16*2) = 187.2
  width: '90%',
  display: 'inline-block',
  marginTop: 10,
});

const Fallback = styled(Typography)({
  alignItems: 'inherit',
  textAlign: 'center',
  fontSize: 50,
  display: 'inline-block',
  width: '100%',
  height: '100%',
  transform: 'translate(0, 25%)',
});

export default function ProjectLogo({ projectLogoUrl, projectName }) {
  const representativeCharacter = (projectName || projectLogoUrl).charAt(0);

  return (
    <Avatar src={projectLogoUrl} variant="rounded">
      <Fallback variant="h5">{representativeCharacter}</Fallback>
    </Avatar>
  );
}
