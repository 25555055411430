import axios from 'axios';
import { BASEURL } from '../../utils/constants';
import useAuth from '../useAuth';

export const useAxios = () => {
  const { getTokenSilently } = useAuth();

  const request = async ({
    method,
    path,
    body,
  }: {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    path: string;
    body?: Record<string, unknown> | null;
  }) => {
    const token = await getTokenSilently();

    const config: Record<string, Record<string, unknown>> = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    if (body) config.data = body;

    try {
      return await axios({ method, url: BASEURL + path, ...config });
    } catch (error) {
      console.error(`Error in request: ${error}`);
      throw error;
    }
  };

  const get = async (path: string) => {
    return await request({ method: 'GET', path });
  };

  const post = async (path: string, body: Record<string, unknown>) => {
    return await request({ method: 'POST', path, body });
  };

  const put = async (path: string, body: Record<string, unknown>) => {
    return await request({ method: 'PUT', path, body });
  };

  const patch = async (path: string, body: Record<string, unknown>) => {
    return await request({ method: 'PATCH', path, body });
  };

  const del = async (path: string) => {
    return await request({ method: 'DELETE', path });
  };

  return { get, post, patch, put, del, axiosRequest: request };
};
