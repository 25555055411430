import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format } from 'date-fns';
import Typography from '@mui/material/Typography';
import { SHORT_DATE_TIME_FORMAT } from 'utils/constants';
import { ButtonBase } from '../buttons';
import { TimeButtonProps } from '../buttons.types';

function getButtonText(
  range: { from: Date | number; to: Date | number },
  selectedOption?: { value: number; label: string },
  rangeFormatter = SHORT_DATE_TIME_FORMAT
): string {
  if (selectedOption) {
    return selectedOption.label;
  }
  const isValidRange = !!range.from && !!range.to;

  return isValidRange
    ? `${format(range.from, rangeFormatter)} - ${format(
        range.to,
        rangeFormatter
      )}`
    : 'Invalid Date Range';
}

export function TimeButton({
  range,
  size = 'small',
  type = 'button',
  selectedOption,
  rangeFormatter,
  ...rest
}: TimeButtonProps) {
  return (
    <ButtonBase
      color="secondary"
      size={size}
      type={type}
      variant="outlined"
      {...rest}
      sx={{
        color: 'text.primary',
        ':hover': { borderColor: 'black' },
        paddingX: 1,
        borderColor: 'secondary.main',
        borderRadius: 1,
        transition: (theme) =>
          theme.transitions.create('borderColor', {
            duration: theme.transitions.duration.shortest,
          }),
      }}
      startIcon={<AccessTimeIcon />}
    >
      <Typography noWrap>
        {getButtonText(range, selectedOption, rangeFormatter)}
      </Typography>
    </ButtonBase>
  );
}
