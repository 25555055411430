import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './main/en.json';
import nl from './main/nl.json';

export const SUPPORTED_LNGS = ['en', 'nl'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: SUPPORTED_LNGS,
    fallbackLng: 'en',
    defaultNS: 'main',
    cleanCode: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.addResourceBundle('en', 'main', en);
i18n.addResourceBundle('nl', 'main', nl);

export default i18n;
