import { useQuery } from 'react-query';
import { GraphQLClient, Variables } from 'graphql-request';
import { BASEURL } from '../utils/constants';
import useAuth from './useAuth';
import { GQLQuery } from './hooks.types';

const gqlEndpoint = `${BASEURL}/admin/gql`;
const gqlOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const graphQLClient = new GraphQLClient(gqlEndpoint, gqlOptions);

export const useGQLRequest = <T, V extends Variables>() => {
  const { getTokenSilently } = useAuth();

  return async (query: string, variables: V): Promise<T> => {
    const token = await getTokenSilently();
    const requestHeaders = {
      Authorization: 'Bearer ' + token,
    };

    //@ts-expect-error graphql request library needs update
    return graphQLClient.request<T, V>(query, variables, requestHeaders);
  };
};

export const formatQueryKey = (key: (string | number)[] | string) =>
  Array.isArray(key) ? key.join('_') : key;

export const useGQLQuery = <T, E = Error>(
  key: (string | number)[] | string,
  query: string,
  variables?: Record<string, unknown>,
  config?: Record<string, unknown>
): GQLQuery<T> => {
  const { getTokenSilently } = useAuth();

  const fetchData = async () => {
    const requestHeaders = {
      Authorization: 'Bearer ' + (await getTokenSilently()),
    };
    return await graphQLClient.request<T>(query, variables, requestHeaders);
  };

  // Convert key array to string if it is not already a string
  const keyStr = formatQueryKey(key);

  const { data, isLoading, ...args } = useQuery<T, E>(
    keyStr,
    fetchData,
    config
  );

  return {
    data: data,
    loading: isLoading,
    ...args,
  };
};
