import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { useAxios } from '../useAxios';
import { ReactQueryConfig } from 'hooks/hooks.types';
import { AxiosError } from 'axios';
import { keysToCamelCase } from 'utils/formatters';
import { useMemo } from 'react';

export function useAxiosQuery<R>(
  key: (string | { filters: string })[],
  path: string,
  {
    method = 'GET',
    body = null,
    mapper,
    ...reactQueryConfig
  }: ReactQueryConfig<R> = {}
): {
  data: R | undefined;
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
} {
  const { enqueueSnackbar } = useSnackbar();
  const { axiosRequest } = useAxios();

  const fetchData = async () => {
    let data;
    const response = await axiosRequest({ method, path, body });

    data = response?.data;

    if (mapper) {
      data = mapper(data);
    }

    return keysToCamelCase(data) as R;
  };

  const { isError, isSuccess, isLoading, data, ...args } = useQuery<
    R,
    AxiosError
  >(key, () => fetchData(), {
    onError: (error) => {
      enqueueSnackbar(
        `Something went wrong with getting data from the database: ${error?.message}`,
        { variant: 'error' }
      );
    },
    useErrorBoundary: (error) => (error?.response?.status ?? 0) >= 500,
    retry: 3,
    ...reactQueryConfig,
  });

  const transformedKeyData = useMemo(() => {
    if (!data) return [];
    else return keysToCamelCase(data);
  }, [data]);

  return {
    data: transformedKeyData,
    isSuccess,
    isError,
    isLoading,
    ...args,
  };
}
