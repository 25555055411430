export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  CUSTOM: 'CUSTOM',
};

export const PHONE_NUMBER_REGEX = /^[+]*[(]?[0-9]{0,4}[)]?[-\s./0-9]*$/;

export const WEBSOCKET_URL =
  process.env.REACT_APP_WEBSOCKET_URI || 'ws://localhost:8080/';

export const MAPBOX_ATTRIBUTION =
  '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
export const MAPBOX_URL = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}`;

// Feature Flags
export const FEATURE_FLAGS = {
  PROJECT_SETUP_SCHEMATICS: false,
  PROJECT_CONFIGURATION_DIAGRAM: false,
  USER_REPORTS: true,
  ASSET_FEED: true,
};
