import { gql } from 'graphql-request';

export const GREENER_UNIT_KEY = (greenerUnitIdentifier) => [
  'project',
  'externalIdentifier',
  greenerUnitIdentifier,
];
export const READ_GREENER_UNIT = gql`
  query getGreenerUnit($externalIdentifier: String!) {
    greenerUnit(externalIdentifier: $externalIdentifier) {
      greenerUnitId
      externalIdentifier
      name
      description
      owningOrganisationObjectId
      defaultGreenerEyeId
      watcherTypeId
      owningOrganisationId
      defaultGreenerEye {
        greenerEyeId
        externalIdentifier
        name
        description
        owningOrganisationId
      }
      watcherType {
        watcherTypeId
        externalIdentifier
        deviceType {
          deviceTypeId
          externalIdentifier
          consumesPower
          producesPower
        }
      }
      owningOrganisation {
        organisationId
        externalIdentifier
        name
        description
        mainContactId
        countryId
      }
    }
  }
`;
