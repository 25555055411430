import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAxios } from 'hooks/useAxios';
import { getQueryKeys } from 'services/queryKeys';
import { Edge } from '../microgrid.types';

export function useRemoveEdge(projectId: string, microgridId: string) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { del } = useAxios();
  const path = `/projects/${projectId}/microgrids/${microgridId}/`;

  const request = async (edge: Edge) => {
    await del(path + `edges/${edge.id}/`);
    return edge.id;
  };

  const {
    mutateAsync: removeEdge,
    status,
    error,
    data,
    isLoading,
    isError,
    ...others
  } = useMutation((edge: Edge) => request(edge), {
    onSuccess: (data: string) => {
      // ✅ remove the cache
      const queryKeys = getQueryKeys(path + `edges/${data}/`);
      queryClient.removeQueries(queryKeys.detail + `edges/${data}/`, {
        exact: true,
      });
    },
    onError: (error, edge) => {
      enqueueSnackbar(
        t('genericErrors.create', {
          entity: t('entities:microgrid.nameSingular').toLowerCase(),
          identifier: edge.label,
        }) + `: ${error}`,
        { variant: 'error' }
      );
    },
  });
  return {
    removeEdge,
    status,
    error,
    data,
    isLoading,
    isError,
    ...others,
  };
}
