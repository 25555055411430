import { Helmet } from 'react-helmet-async';
import {
  Outlet,
  useParams,
  useNavigate,
  useOutletContext,
  generatePath,
} from 'react-router-dom';
import { useGQLQuery } from 'hooks/useGQLQuery';
import { GREENER_UNIT_KEY, READ_GREENER_UNIT } from 'services/gql/units';
import { useTranslation } from 'react-i18next';
import { UnitDetailTopbar } from 'components/layout/UnitDetailTopbar';
import { sortBy } from 'lodash';
import { useTimerange } from 'hooks/useTimerange';
import { useAssetSidebar } from './[Asset].sidebar';
import { GreenerUnit } from 'types';
import { routes } from 'routes';
import { matchRoutes, useLocation } from 'react-router-dom';

export const AssetLayout = () => {
  useAssetSidebar();
  const { unitExternalIdentifier } = useParams();
  const { units } = useOutletContext<{ units: GreenerUnit[] }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);

  const unit = useGQLQuery(
    GREENER_UNIT_KEY(unitExternalIdentifier),
    READ_GREENER_UNIT,
    { externalIdentifier: unitExternalIdentifier }
  ).data.greenerUnit;

  const { range, ...timerangeProps } = useTimerange();

  function onChangeUnit(newSelectedUnit: GreenerUnit) {
    const matched = matchedRoutes?.find(
      (m) => m.pathnameBase === location.pathname
    );

    if (matched) {
      let newPath = '/assets/:unitExternalIdentifier';

      if (matched.pathnameBase.includes('basic-graphs')) {
        newPath = '/assets/:unitExternalIdentifier/basic-graphs';
      } else if (matched.pathnameBase.includes('advanced-graphs')) {
        newPath = '/assets/:unitExternalIdentifier/advanced-graphs';
      }

      navigate(
        generatePath(newPath, {
          unitExternalIdentifier: newSelectedUnit.externalIdentifier,
        })
      );
    }
  }

  const type = unit?.watcherType.deviceType.externalIdentifier;

  return (
    <>
      <Helmet title={t('unitControl:domain')} />
      <UnitDetailTopbar
        unitSelectorProps={{
          units: sortBy(units, ['externalIdentifier']),
          onChange: onChangeUnit,
          selectedUnitExtId: unitExternalIdentifier,
        }}
        timerangePickerProps={{
          range,
          ...timerangeProps,
        }}
      />
      <Outlet context={{ range, type, unitExternalIdentifier, unit }} />
    </>
  );
};
