import { gql } from 'graphql-request';

export const PROJECT_ORGANISATIONS_KEY = (projectId) => [
  'project-organisations',
  projectId,
  'id',
];

export const READ_PROJECT_ORGANISATIONS = gql`
  query getProjectOrganisations($projectId: Int!) {
    projectOrganisations(projectId: $projectId) {
      projectId
      organisationId
      organisationRoleId
      organisation {
        organisationId
        externalIdentifier
        name
      }
      organisationRole {
        organisationRoleId
        externalIdentifier
        name
        description
      }
    }
  }
`;

export const CREATE_PROJECT_ORGANISATIONS = gql`
  mutation CreateProjectOrgs(
    $projectId: Int!
    $projectOrganisations: [ProjectOrganisationInput]
  ) {
    createProjectOrganisations(
      projectId: $projectId
      projectOrganisations: $projectOrganisations
    ) {
      projectOrganisations {
        organisationId
        projectId
      }
    }
  }
`;

export const DELETE_PROJECT_ORGANISATION = gql`
  mutation DeleteProjectOrg($projectId: Int!, $organisationId: Int!) {
    deleteProjectOrganisation(
      projectId: $projectId
      organisationId: $organisationId
    ) {
      projectId
      organisationId
    }
  }
`;
