import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Dimensions, Breakpoints } from './hooks.types';

export default function useScreenSize(): {
  dimensions: Dimensions;
  breakpoints: Breakpoints;
  isWindowSmaller: (breakpoint: keyof Breakpoints) => boolean;
} {
  const [dimensions, setDimensions] = useState<Dimensions>({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [isResizing, setIsResizing] = useState<boolean>(false);

  const breakpoints: Breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  };

  const isWindowSmaller = (breakpoint: keyof Breakpoints): boolean => {
    return dimensions.width < breakpoints[breakpoint];
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });

      setIsResizing(false);
    }, 500);

    const trackResize = () => {
      setIsResizing(true);
      debouncedHandleResize();
    };

    window.addEventListener('resize', trackResize);

    return () => {
      window.removeEventListener('resize', trackResize);
    };
  }, [setIsResizing]);

  return { dimensions, breakpoints, isWindowSmaller, isResizing };
}
