import {
  isNaN,
  camelCase,
  snakeCase,
  mapKeys,
  transform,
  isArray,
  isObject,
  isDate,
} from 'lodash';
import { format } from 'date-fns';

import { AGGREGATORS, SHORT_DATE_TIME_FORMAT } from './constants';
import { AGGREGATOR_FUNCTIONS } from 'utils/analytics';
import { isEmptyObject } from './helpers';

export function formatCurrents(floatArray) {
  return (
    floatArray
      .map((e) => (e || e === 0 ? e.toFixed(0) : 'unknown'))
      .join(', ') + ' A'
  );
}

export function formatFrequency(freq) {
  return freq || freq === 0 ? freq.toFixed(2) + ' Hz' : 'unknown';
}

/**
 * Returns the same array of objects with each tsStart and tsEnd parsed as Date javascript objects
 * @param {Array.<Object>|Object} data
 * @param {string} data.tsStart
 * @param {string} data.tsEnd
 * @returns {Array.<Object>}
 */
export function parseTsStartAndTsEnd(data) {
  let isArgumentArray = true;
  if (!(data instanceof Array)) {
    data = [data];
    isArgumentArray = false;
  }

  const result = data?.map((obj) => {
    return {
      ...obj,
      tsStart: new Date(obj.tsStart),
      tsEnd: obj.tsEnd ? new Date(obj.tsEnd) : null,
    };
  });

  if (!isArgumentArray && result?.length > 0) {
    return result[0];
  }

  return result;
}

/**
 * Returns a string representation of a given date. Returns '-' for null.
 * @param {Date|string|number} dt Date object or number or string representing a date
 * @param dtFormat the format of the date string to be returned. Default 'yyyy-MM-dd HH:mm'
 * @returns {string} String representation of the date.
 */
export function representDate(dt, dtFormat = SHORT_DATE_TIME_FORMAT) {
  if (!dt) return '-';
  return format(new Date(dt), dtFormat);
}

/**
 * Formats timeseries data so that it can be used in graphing components.
 * The given graphConfig determines which fields to pick from the given tsData object, and further assigns properties
 * such as 'label', 'unit', and 'color'.
 *
 * @param tsData
 * @param graphConfig
 * @returns {*[]}
 */
export function formatTimeseriesDataForGraphs(tsData, graphConfig) {
  const formattedData = [];
  if (!tsData || isEmptyObject(tsData)) {
    return formattedData;
  }

  for (let field of Object.keys(graphConfig)) {
    // Ensure we are parsing a proper graphConfig entry; configuration stored in an object.
    if (
      !graphConfig.hasOwnProperty(field) ||
      typeof graphConfig[field] !== 'object'
    ) {
      continue;
    }

    // Verify the field is present in the data as well.
    if (!tsData.hasOwnProperty(field)) {
      console.error(
        `Cannot find field ${field} in the data. Known data attributes: ${Object.keys(
          tsData
        ).join(', ')}`
      );
      continue;
    }

    const columnData = tsData[field];
    const columnConfig = graphConfig[field];

    if (Array.isArray(columnData)) {
      // This is an array, without aggregators.
      formattedData.push({
        points: columnData,
        unit: columnConfig.unit,
        ...graphConfig[field],
      });

      // We are done for this iteration, there is no further nesting for aggregators.
      continue;
    }

    // This entry is an object containing aggregators. Loop over them.
    for (let aggregator of Object.keys(columnConfig)) {
      // Ensure we are parsing a graph config property, configuration stored in an object.
      if (
        !columnConfig.hasOwnProperty(aggregator) ||
        typeof columnConfig[aggregator] !== 'object'
      ) {
        continue;
      }

      // Verify the field is present in the data as well.
      if (!columnData[aggregator]) {
        console.warn(
          `Could not find ${field} (${aggregator}) in the provided data! Known entries: ${JSON.stringify(
            columnData
          )}`
        );
        continue;
      }

      const currentGraphConfig = graphConfig[field][aggregator];
      const currentGraphData = columnData[aggregator];

      formattedData.push({
        points: currentGraphData,
        unit: columnConfig.unit,
        aggregator,
        ...currentGraphConfig,
      });
    }
  }

  return formattedData;
}

/**
 * Returns a string in which an energy is represented with an energy unit.
 * @param {number} num representing energy
 * @returns {string} formatted number with unit
 */
export function formatEnergyWithUnit(num) {
  if (typeof num === 'number' && !isNaN(num)) {
    return Math.abs(Math.round(num)) > 999
      ? (num / 1000).toFixed(1) + ' MWh'
      : num.toFixed() + ' kWh';
  } else {
    return '- kWh';
  }
}

/**
 * Returns a string with a percentage value.
 * @param {number} num representing a percentage
 * @returns {string} formatted number with unit
 */
export function formatPercentage(num, decimals = 1) {
  if (typeof num === 'number' && !isNaN(num)) {
    return `${Math.round(num * 100, decimals)} %`;
  } else {
    return '- %';
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// Aggregator formatters
// ---------------------------------------------------------------------------------------------------------------------

export function formatLegendValueForGraphs(allData, aggregator, unit) {
  if (!allData || !allData.hasOwnProperty(aggregator)) {
    return null;
  }

  const data = allData[aggregator];
  const [value, timestamp] = calculateAggregateWithTimestamp(aggregator, data);

  return {
    aggregator,
    value,
    unit,
    timestamp: timestamp ? new Date(timestamp) : null,
  };
}

export function calculateAggregateWithTimestamp(aggregator, data) {
  const yValues = data.map((point) => point.y);
  const aggregateValue = calculateAggregate(aggregator, yValues);
  let aggregateTimestamp = null;
  if (aggregator === AGGREGATORS.MIN || aggregator === AGGREGATORS.MAX) {
    const aggregateIndex = yValues.indexOf(aggregateValue);
    aggregateTimestamp = new Date(data[aggregateIndex].x);
  }

  return [aggregateValue, aggregateTimestamp];
}

/**
 * Calculates an aggregated value over an array.
 *
 * @param {String} aggregator, defining how to aggregate the values of the array.
 * @param {Array} arr, array to aggregate values of.
 * @returns {Number} The aggregated value.
 */
function calculateAggregate(aggregator, arr) {
  const agg = aggregator.toLowerCase();
  if (!AGGREGATOR_FUNCTIONS.hasOwnProperty(agg)) {
    console.error(`Unknown aggregator function: ${aggregator}`);
    throw new Error(`Unknown aggregator function: ${aggregator}`);
  }

  // Filter out nulls
  const filtered = arr.filter((element) => element !== null);

  return AGGREGATOR_FUNCTIONS[agg](filtered);
}

export function formatCoordinateAsPoint({ lat, lng }) {
  if (!lng || !lat) return;

  return `POINT(${lng} ${lat})`;
}

export const buildGraphConfigKey = (field, agg) => {
  return field + '_' + agg;
};

/**
 * Recursively transform object to camel-cased keys
 **/
export const keysToCamelCase = (obj) => {
  if (!obj) return;
  return transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] =
      isObject(value) && !isDate(value) ? keysToCamelCase(value) : value;
  });
};

export const keysToSnakeCase = (obj) => mapKeys(obj, (v, k) => snakeCase(k));

//Format with dot notation every 3 digits - design choice
export const formatNumber = (number) => {
  if (!typeof number === 'Number') return number;
  return new Intl.NumberFormat('de-DE').format(number);
};
