// These functions are used to determine whether a user can see a page
export const canSeeGreenerUnitPages = (userRole) => {
  return userRole?.canManageGreenerUnits;
};
export const canSeeGreenerEyesPages = (userRole) => {
  return userRole?.canManageGreenerUnits;
};
export const canSeeWatcherTypePages = (userRole) => {
  return userRole?.canManageGreenerUnits;
};

export const canManageGreenerUnitInstances = (userRole) => {
  return userRole?.canManageGreenerUnitInstances;
};

export const canCreateProjects = (userRole) => {
  return (
    userRole.canManageEventProjects ||
    userRole.canManageTradingProjects ||
    userRole.canManageGridDeferralProjects
  );
};

export const canPlanUnits = (userRole) => {
  return userRole.canAssignGreenerUnitInstances;
};

export const canEditProject = (userRole, projectType) => {
  switch (projectType.externalIdentifier) {
    case 'event':
      return userRole.canManageEventProjects;
    case 'trading':
      return userRole.canManageTradingProjects;
    case 'grid_deferral':
      return userRole.canManageGridDeferralProjects;
    case 'offshore':
      return userRole.canManageGridDeferralProjects;
    case 'construction':
      return userRole.canManageGridDeferralProjects;
    case 'electric_vehicles':
      return userRole.canManageGridDeferralProjects;
    case 'other':
      return userRole.canManageGridDeferralProjects;
    default:
      return false;
  }
};

export const canEditUnitsInProject = (userRole, projectType) => {
  return (
    userRole.canManageGreenerUnits && canEditProject(userRole, projectType)
  );
};

export const canManageMicrogrids = (userRole) => {
  return userRole.canManageMicrogrids;
};

export const canSeeAnalyticalData = (userRole) => {
  return userRole.canSeeAnalyticalData;
};

export const canRequestProjectReports = (userRole) => {
  return userRole.canRequestProjectReports;
};

//Flag of internal features in the absence of a user role

export const isGreenerEmployee = (user) =>
  user.organisation.externalIdentifier === 'greener';
