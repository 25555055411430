import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAxios } from 'hooks/useAxios';
import { mutateEdgeMapper, getEdgeMapper } from '../microgrid.utils';
import { Edge } from '../microgrid.types';
import { keysToCamelCase } from 'utils/formatters';

export function useUpdateEdge(projectId: string, microgridId: string) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { patch } = useAxios();
  const path = `/projects/${projectId}/microgrids/${microgridId}/edges/`;

  const fetchData = async (edge: Edge) => {
    const { data } = await patch(path + `${edge.id}/`, mutateEdgeMapper(edge));
    return getEdgeMapper(keysToCamelCase(data));
  };

  const {
    mutateAsync: updateEdge,
    status,
    error,
    data,
    isLoading,
    isError,
    ...others
  } = useMutation((edge: Edge) => fetchData(edge), {
    onError: (error, vars) => {
      enqueueSnackbar(
        t('genericErrors.create', {
          entity: t('entities:microgrid.nameSingular').toLowerCase(),
          identifier: vars.label,
        }) + `: ${error}`,
        { variant: 'error' }
      );
    },
  });

  return {
    updateEdge,
    status,
    error,
    data,
    isLoading,
    isError,
    ...others,
  };
}
