/**
 * This module contains queries and mutations for 'searching' through the backend.
 */

import { gql } from 'graphql-request';

export const SEARCH = 'search';

export const READ_SEARCH = gql`
  query SearchData($searchString: String50!) {
    search(searchString: $searchString) {
      projects {
        name
        externalIdentifier
      }
      units {
        name
        externalIdentifier
      }
    }
  }
`;
