import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuItemRoot, MenuItemChildren } from './SidebarMenus';

function SidebarItem({ icon, items, path, openSidebar, isSidebarOpen, name }) {
  const location = useLocation();
  const isExpandable = items && items.length > 0;
  const [isItemOpen, setIsItemOpen] = useState(false);
  const [previousPath, setPreviousPath] = useState(null);
  const [previousItems, setPreviousItems] = useState(null);

  // Check whether the path changed and look for a matching sidebarItem.path to set it to open.
  // It might happen that the sidebar items change but the path doesn't (ie: when using the unit selector in the asset control pages), so that extra check is added.
  if (location.pathname !== previousPath || previousItems !== items) {
    setIsItemOpen(!!items?.find((item) => item.path === location.pathname));
    setPreviousPath(location.pathname);
    setPreviousItems(items);
  }

  const handleClick = () => {
    // Open the sidebar.
    openSidebar();
    // open / close the item
    if (isExpandable) setIsItemOpen(!isItemOpen);
  };

  return (
    <>
      <MenuItemRoot
        path={path}
        handleClick={handleClick}
        icon={icon}
        isExpandable={isExpandable}
        isItemOpen={isItemOpen}
        name={name}
      />
      {
        // Only render MenuItemChildren when the sidebar is open.
        isSidebarOpen && (
          <MenuItemChildren
            isExpandable={isExpandable}
            isItemOpen={isItemOpen}
            items={items}
          />
        )
      }
    </>
  );
}

export default SidebarItem;
