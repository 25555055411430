import { createBrowserRouter } from 'react-router-dom';
import ErrorFallback from 'components/fallback/ErrorFallback';
import { Layout } from './components/layout/MainLayout';
import { managementRoutes } from 'pages/Management/Management.routes';
import { projectsRoutes } from 'pages/Projects/Projects.routes';
import { assetsRoutes } from 'pages/Assets/Assets.routes';

// Workaround to ensure we don't pass invalid properties to React.Fragment
function Fragment({ children }) {
  return <>{children} </>;
}

export const routes = [
  {
    path: '/login',
    lazy: () => import('./pages/Login'),
  },
  {
    element: <Layout />,
    path: '*',
    errorElement: <ErrorFallback />,
    children: [
      {
        index: true,
        lazy: () => import('./pages/Home'),
      },
      ...projectsRoutes,
      ...managementRoutes,
      ...assetsRoutes,
      {
        path: 'notifications',
        lazy: () => import('./pages/Notifications'),
      },
      {
        path: 'account',
        lazy: () => import('./pages/Account'),
      },
      {
        path: 'analytics/*',
        lazy: () => import('./pages/Analytics'),
      },
      {
        path: 'glossary',
        lazy: () => import('./pages/Glossary'),
      },
    ],
  },
];

export const browserRouter = createBrowserRouter(routes);
