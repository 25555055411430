import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { ButtonBase, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { SettingsIcon } from '../../../icons';
import Organizations from './Organizations';
import Roles from './Roles';
import Users from './Users';
import Units from './Units';
import GreenerEyes from './GreenerEyes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import WatcherTypes from './WatcherTypes';
import { DeviceModels } from './DeviceModels';
import useAuth from 'hooks/useAuth';
import {
  canSeeGreenerEyesPages,
  canSeeGreenerUnitPages,
  canSeeWatcherTypePages,
} from 'utils/authorization';

const PREFIX = 'index';

const classes = {
  popover: `${PREFIX}-popover`,
};

const StyledDiv = styled('div')({
  [`& .${classes.popover}`]: {
    width: 200,
  },
});

export function ManagementSelection() {
  const ref = useRef(null);
  const { t } = useTranslation();
  const { user } = useAuth();

  const [isOpen, setOpen] = useState(false);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <StyledDiv>
      <Tooltip title={t('navigation.management')}>
        <IconButton
          display="flex"
          component={ButtonBase}
          onClick={handleOpen}
          ref={ref}
          color="inherit"
          size="large"
          data-cy="top-bar-management"
        >
          <SettingsIcon style={{ fontSize: 16 }} />
        </IconButton>
      </Tooltip>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          onClick={handleClose}
          data-cy="management-users-item"
          component={Link}
          to="/users"
        >
          <Users />
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          data-cy="management-roles-item"
          to="/user-roles"
        >
          <Roles />
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          data-cy="management-organisations-item"
          to="/organisations"
        >
          <Organizations />
        </MenuItem>

        {canSeeGreenerUnitPages(user?.role) && (
          <MenuItem
            onClick={handleClose}
            component={Link}
            data-cy="management-greener-units-item"
            to="/greener-units"
          >
            <Units />
          </MenuItem>
        )}
        {canSeeGreenerEyesPages(user?.role) && (
          <MenuItem
            onClick={handleClose}
            component={Link}
            data-cy="management-greener-eyes-item"
            to="/greener-eyes"
          >
            <GreenerEyes />
          </MenuItem>
        )}
        {canSeeWatcherTypePages(user?.role) && (
          <MenuItem
            onClick={handleClose}
            component={Link}
            data-cy="management-watcher-types-item"
            to="/watcher-types"
          >
            <WatcherTypes />
          </MenuItem>
        )}
        {canSeeGreenerUnitPages(user?.role) && (
          <MenuItem
            onClick={handleClose}
            component={Link}
            data-cy="management-greener-units-item"
            to="/device-models"
          >
            <DeviceModels />
          </MenuItem>
        )}
      </Menu>
    </StyledDiv>
  );
}
