// Copyright 2021 Greener Power Solutions                 //
// This file is part of the Greener Power Solutions,      //
// and may not be altered, copied, redistributed, or sold //
// without specific permission.                           //

import { isEqual } from 'lodash';

export function addTrueKeysToArray(obj) {
  const result = [];
  Object.entries(obj).forEach(([key, value]) => value && result.push(key));
  return result;
}

export function removeDuplicates(array) {
  return [...new Set(array)];
}

/**
 * No-op; an operation that does nothing.
 * Can be used to replace falsy functions.
 * See https://stackoverflow.com/questions/21634886/what-is-the-javascript-convention-for-no-operation
 */
export const noop = () => {};

/**
 * Checks an object is empty, i.e. has no attributes.
 *
 * See https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
 */
export function isEmptyObject(obj) {
  return (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  ); // Otherwise classes such as Date fail.
}

/**
 * Checks if any of the field properties have changed. And filters out the ones that haven't.
 * @param {Object} data - The data object that contains the new values
 * @param {Object} initialData - The selected microgrid object
 * @returns {Object} - The filtered object with only the changed values
 */
export function removeUnchangedValues(data, initialData) {
  const values = {};
  Object.keys(data).forEach((key) => {
    if (!isEqual(data[key], initialData[key])) {
      values[key] = data[key];
    }
  });
  return values;
}

/**
 * Determines if a feature should be render or not based on the current environment and on the flag passed as argument
 * @param {bool} flag - if what's currently being evaulated is a feature or not
 * @returns {bool}
 */
export const determineShowFeature = (
  flag,
  condition = process.env.NODE_ENV === 'development'
) => condition || !flag;
