import React, { forwardRef } from 'react';

import SvgIcon from '@mui/material/SvgIcon';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

// ICONS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// COMPONENTS
import SidebarItem from '.';
import ListItem from '@mui/material/ListItem';
import { NavLink } from 'react-router-dom';

const SidebarItemComponent = ({ className, onClick, path, children, sx }) => {
  if (!path) {
    return (
      <ListItem
        sx={sx}
        className={className}
        children={children}
        onClick={onClick}
      />
    );
  }

  return (
    <ListItem
      sx={sx}
      className={className}
      children={children}
      component={forwardRef((props, ref) => (
        <NavLink end {...props} innerRef={ref} />
      ))}
      to={path}
    />
  );
};

export function MenuItemRoot(props) {
  const {
    path,
    handleClick,
    icon: Icon,
    isExpandable,
    isItemOpen,
    name,
    isActive,
  } = props;

  return (
    <SidebarItemComponent
      sx={{
        cursor: 'pointer',
        color: 'black',
        '&.active': {
          background: (theme) => theme.palette.secondary.light,
        },
      }}
      path={path}
      onClick={handleClick}
      isActive={isActive}
    >
      {Icon && (
        <ListItemIcon sx={{ marginRight: '1rem' }}>
          <SvgIcon style={{ fontSize: 20 }}>
            <Icon />
          </SvgIcon>
        </ListItemIcon>
      )}
      <ListItemText primary={name} inset={!Icon} />
      {isExpandable && !isItemOpen && <NavigateNextIcon />}
      {isExpandable && isItemOpen && <ExpandMoreIcon />}
    </SidebarItemComponent>
  );
}

export function MenuItemChildren({ isExpandable, isItemOpen, items = [] }) {
  return (
    isExpandable && (
      <Collapse in={isItemOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item, index) => (
            <SidebarItem {...item} key={index} />
          ))}
        </List>
      </Collapse>
    )
  );
}
