import { IconButton, Tooltip } from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';

export default function TopBarSearch({ openSearchDrawer }) {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('navigation.search')}>
      <IconButton
        data-cy="top-bar-search"
        color="inherit"
        onClick={openSearchDrawer}
        size="large"
      >
        <SearchIcon size={16} />
      </IconButton>
    </Tooltip>
  );
}
