import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSidebarItems } from 'hooks/useSidebarItems';
import { GenericSidebarHeader } from 'components/layout/Sidebar';
import { useTranslation } from 'react-i18next';
import UnitControlIcon from 'components/icons/UnitControlIcon';
import { GraphIcon } from 'components/icons';

export function useAssetSidebar() {
  const { t } = useTranslation();
  const { dispatchSidebar } = useSidebarItems();
  const { unitExternalIdentifier } = useParams();

  useEffect(() => {
    dispatchSidebar({
      type: 'REGULAR',
      payload: {
        sidebarHeader: (
          <GenericSidebarHeader
            title={t('unitControl:domain')}
            subtitle={unitExternalIdentifier}
          />
        ),
        items: [
          {
            name: t('unitControl:navigation.unitDetails'),
            path: `/assets/${unitExternalIdentifier}`,
            icon: UnitControlIcon,
          },
          {
            name: t('unitControl:navigation.basicGraphs'),
            path: `/assets/${unitExternalIdentifier}/basic-graphs`,
            icon: GraphIcon,
          },
          {
            name: t('unitControl:navigation.advancedGraphs'),
            path: `/assets/${unitExternalIdentifier}/advanced-graphs`,
            icon: GraphIcon,
          },
        ],
      },
    });
  }, [dispatchSidebar, unitExternalIdentifier, t]);
}
