export const BASEURL =
  process.env.REACT_APP_BACKEND_URI || 'http://localhost:8080';

export const MAPBOX_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places';

export const DEFAULT_TIME_RANGES = {
  ONE_HOUR: 3_600_000,
  THREE_HOURS: 10_800_000,
  SIX_HOURS: 21_600_000,
  NINE_HOURS: 32_400_000,
  TWELVE_HOURS: 43_200_000,
  ONE_DAY: 86_400_000,
  SEVEN_DAYS: 604_800_000,
  THIRTY_DAYS: 2_592_000_000,
};

export const STRING_LENGTHS = {
  SHORT: 12,
  MEDIUM: 50,
  LARGE: 255,
};

export const FEEDBACK_LEVEL = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

/* Dates */
export const DATE_TIME_FORMAT = 'dd-MM-yyyy HH:mm:ss';
export const SHORT_DATE_TIME_FORMAT = 'dd-MM-yyyy HH:mm';
export const SHORTEST_DATE_TIME_FORMAT = 'dd-MM HH:mm';
export const DATE_FORMAT = 'dd-MM-yyyy';
export const HOUR_MINUTE_FORMAT = 'HH:mm';
export const BACKEND_API_TIMESERIES_REQUEST_DATE_FORMAT =
  'yyyy-MM-dd HH:mm:ss.SSSXX';
export const EVENTS_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

/* Units */
export const DEVICE_TYPES = {
  BATTERY: 'battery',
  GENERATOR: 'diesel_genset',
  ENERGY_METER: 'energy_meter',
};

/* Asset Types */
export const ASSET_TYPES = {
  ...DEVICE_TYPES,
  GRID: 'grid',
  DISTRIBUTION_BOX: 'distribution_box',
  LOAD: 'load',
  SOLAR_PANEL: 'solar_panel',
  WIND_TURBINE: 'wind_turbine',
  HYDROGEN_GENERATOR: 'hydrogen_generator',
  ELECTRICAL_SUBSTATION: 'electrical_substation',
};

export const ICON_TYPES = {
  ...DEVICE_TYPES,
  LOCATION: 'location',
  PROJECT: 'greener-logo',
};

/* Roles */
export const ORGANISATION_ROLES_IDS = {
  MANAGER: -1,
  SUPPLIER: -2,
  CONTRACTOR: -3,
  CLIENT: -4,
};

export const DEVICE_TYPE_IDS = {
  BATTERY: -1,
  GENERATOR: -2,
  ENERGY_METER: -3,
};

/* Use symbols; we don't really care about the actual value, and want to avoid accidental equalities. */
export const PROJECT_CONFIGURATIONS = {
  STANDALONE: Symbol('standalone'),
  CYCLING_GENSETS: Symbol('cycling gensets'),
  LOADSHARING_GENSETS: Symbol('loadsharing gensets'),
  GRID_PEAKSHAVING_UPS: Symbol('grid peakshaving as UPS'),
  GRID_PEAKSHAVING_AFE: Symbol('grid peakshaving in AFE'),
  TRADING: Symbol('trading'),
  NOT_IMPLEMENTED: Symbol('not implemented'),
  UNKNOWN: Symbol('unknown'),
};

/* Units' configuration */
export const CONFIG_TABLES = {
  BATTERY: 'batteryConfig',
  WATCH: 'watchConfig',
};

export const WATCH_TABLE_INDEXES = {
  IS_READ_ONLY: 0,
  MODBUS_IP_ADDRESS: 1,
  MODBUS_PORT: 2,
  MODBUS_MESSAGE_PROTOCOL: 3,
  LOCATION_DESCRIPTION: 4,
  DISABLE_IOT_DATA_PROCESSOR: 5,
  DISABLE_DATA_PUSH: 6,
  ENABLE_EPHEMERAL_CONFIG: 7,
  ENABLE_ERROR_AND_WARNING_GUARD: 8,
  REPORT_ERRORS: 9,
  CACHED_ERROR_TIME: 10,
  STATUS_UPDATE_MESSAGE_TARGET: 11,
  ENABLE_TIMESERIES_DATA_GUARD: 12,
  ENABLE_STATE_DATA_GUARD: 13,
  OPERATOR_PROGRAM_MODE: 14,
  OPERATOR_CHAT_ID: 15,
};

export const maxTimeWithoutResponse = 60000; /* 60000 = 60 seconds = ts_heartbeat frequency */

export const PROJECT_UNIT_INSTANCES_DATE_STATUS = {
  future: 'future',
  active: 'active',
  inBetween: 'inBetween',
  past: 'past',
};

export const PROJECT_DATE_STATUS = {
  active: 'active',
  future: 'future',
  past: 'past',
};

export const TIME_COLUMN_NAME = 'time';

export const BATTERY_STATE_MODEL = {
  SYSTEM_STATUS: 'system_status',
  ALARM: 'alarm',
  WARNINIG: 'warning',
  REMOTE_CONTROL: 'remote_control',
  SYSTEM_MODE: 'system_mode',
  ACCEPT_VALUES: 'accept_values',
  CONVERTER_MODE: 'converter_mode',
  CONVERTER_STARTED: 'converter_started',
  INPUT_CONNECTED: 'input_connected',
  SYSTEM_ON_BACKUP_BATTERY: 'system_on_backup_battery',
  LAST_TURNED_ON_GEN_SET: 'last_turned_on_gen_set',
  LAST_TURNED_OFF_GEN_SET: 'last_turned_off_gen_set',
};

export const BATTERY_TS_MODEL = {
  ACTIVE_POWER_CONVERTER: 'active_power_converter',
  ACTIVE_POWER_IN: 'active_power_in',
  ACTIVE_POWER_OUT: 'active_power_out',
  CONSTANT_POWER_SETPOINT: 'constant_power_setpoint',
  CURRENT_L1_IN: 'current_l1_in',
  CURRENT_L1_OUT: 'current_l1_out',
  CURRENT_L2_IN: 'current_l2_in',
  CURRENT_L2_OUT: 'current_l2_out',
  CURRENT_L3_IN: 'current_l3_in',
  CURRENT_L3_OUT: 'current_l3_out',
  FREQUENCY_IN: 'frequency_in',
  FREQUENCY_OUT: 'frequency_out',
  MAX_INPUT_CURRENT: 'max_input_current',
  REACTIVE_POWER_CONVERTER: 'reactive_power_converter',
  REACTIVE_POWER_IN: 'reactive_power_in',
  REACTIVE_POWER_OUT: 'reactive_power_out',
  SOC: 'soc',
  SOH: 'soh',
  TEMPERATURE: 'temperature',
  VOLTAGE_L1_L2_IN: 'voltage_l1_l2_in',
  VOLTAGE_L1_L2_OUT: 'voltage_l1_l2_out',
  VOLTAGE_L2_L3_IN: 'voltage_l2_l3_in',
  VOLTAGE_L2_L3_OUT: 'voltage_l2_l3_out',
  VOLTAGE_L3_L1_IN: 'voltage_l3_l1_in',
  VOLTAGE_L3_L1_OUT: 'voltage_l3_l1_out',
};

export const ENERGY_METER_TS_MODEL = {
  ACTIVE_POWER_TOTAL: 'active_power_total',
  REACTIVE_POWER_TOTAL: 'reactive_power_total',
  APPARENT_POWER_TOTAL: 'apparent_power_total',
  POWER_FACTOR_TOTAL: 'power_factor_total',
  CURRENT_TOTAL: 'current_total',
  FREQUENCY: 'frequency',
  ACTIVE_POWER_L1: 'active_power_l1',
  REACTIVE_POWER_L1: 'reactive_power_l1',
  APPARENT_POWER_L1: 'apparent_power_l1',
  POWER_FACTOR_L1: 'power_factor_l1',
  VOLTAGE_L1_N: 'voltage_l1_n',
  VOLTAGE_L1_L2: 'voltage_l1_l2',
  CURRENT_L1: 'current_l1',
  THDV_L1_N: 'thdv_l1_n',
  THDA_L1: 'thda_l1',
  ACTIVE_POWER_L2: 'active_power_l2',
  REACTIVE_POWER_L2: 'reactive_power_l2',
  APPARENT_POWER_L2: 'apparent_power_l2',
  POWER_FACTOR_L2: 'power_factor_l2',
  VOLTAGE_L2_N: 'voltage_l2_n',
  VOLTAGE_L2_L3: 'voltage_l2_l3',
  CURRENT_L2: 'current_l2',
  THDV_L2_N: 'thdv_l2_n',
  THDA_L2: 'thda_l2',
  ACTIVE_POWER_L3: 'active_power_l3',
  REACTIVE_POWER_L3: 'reactive_power_l3',
  APPARENT_POWER_L3: 'apparent_power_l3',
  POWER_FACTOR_L3: 'power_factor_l3',
  VOLTAGE_L3_N: 'voltage_l3_n',
  VOLTAGE_L3_L1: 'voltage_l3_l1',
  CURRENT_L3: 'current_l3',
  THDV_L3_N: 'thdv_l3_n',
  THDA_L3: 'thda_l3',
  CURRENT_N: 'current_n',
  ACTIVE_ENERGY_IMPORTED: 'active_energy_imported',
  ACTIVE_ENERGY_EXPORTED: 'active_energy_exported',
  REACTIVE_ENERGY_IMPORTED: 'reactive_energy_imported',
  REACTIVE_ENERGY_EXPORTED: 'reactive_energy_exported',
};

export const ENERGY_METER_STATE_MODEL = {
  DEVICE_IDENTITY_CODE: 'device_identity_code',
  PHASE_SEQUENCE: 'phase_sequence',
};

export const AGGREGATORS = {
  COUNT: 'COUNT',
  DISTINCT: 'DISTINCT',
  INTEGRAL: 'INTEGRAL',
  MEAN: 'MEAN',
  MEDIAN: 'MEDIAN',
  MODE: 'MODE',
  SPREAD: 'SPREAD',
  STDDEV: 'STDDEV',
  SUM: 'SUM',
  FIRST: 'FIRST',
  LAST: 'LAST',
  MAX: 'MAX',
  MIN: 'MIN',
  PERCENTILE: 'PERCENTILE',
  SAMPLE: 'SAMPLE',
};

export const ALFEN_SYSTEM_STATUS = {
  OFF: 0,
  ON: 1,
  BUSY: 2,
  WAITING_FOR_RESET: 3,
  SUSPENDED: 4,
  RESET_BUSY: 5,
  STARTING_UP: 6,
};

export const SCHEMATICS_DEVICE_TYPES = {
  BATTERY: 'battery',
  ENERGY_METER: 'energy_meter',
  GENSET: 'genset',
  SOLAR_PANEL: 'solarPanel',
  WINDMILL: 'windmill',
  GRID: 'grid',
};

export const DIRECT_COMMANDS = {
  TURN_BATTERIES_ON: 'turn_batteries_on',
  TURN_BATTERIES_OFF: 'turn_batteries_off',
  START_ESI: 'start_esi',
  STOP_ESI: 'stop_esi',
  SEND_CONNECT_REQUEST: 'send_connect_request',
  SEND_DISCONNECT_REQUEST: 'send_disconnect_request',
  PERFORM_SYSTEM_RESET: 'perform_system_reset',

  SET_MAX_INPUT_CURRENT: 'set_max_input_current',
  SET_CONSTANT_POWER_SETPOINT: 'set_constant_power_setpoint',

  SET_SYSTEM_MODE: 'set_system_mode',
  TURN_OFF_24V_BATTERIES: 'turn_off_24v_batteries',
};

export const SYSTEM_MODES = {
  STAND_BY: { label: 'standby', id: 0 },
  PQ: { label: 'pq', id: 1 },
  PF: { label: 'pf', id: 2 },
  PFQU: { label: 'pfqu', id: 3 },
  PFQUPQ: { label: 'pfqupq', id: 4 },
  PEAKSHAVE: { label: 'peakshave', id: 5 },
  MICROGRID: { label: 'microgrid', id: 6 },
};

export const GRAPHS_TYPES = {
  AREA: 'area',
  LINE: 'line',
  BAR: 'bar',
};

export const PROJECT_TYPES = {
  EVENT: 'event',
  TRADING: 'trading',
  GRID_DEFERRAL: 'grid_deferral',
  OFFSHORE: 'offshore',
  CONSTRUCTION: 'construction',
  ELECTRIC_VEHICLES: 'electric_vehicles',
  OTHER: 'other',
};
