import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { getQueryKeys } from 'services/queryKeys';

import { useCreateNode } from './useCreateNode';
import { useCreateEdge } from './useCreateEdge';
import { useUpdateNode } from './useUpdateNode';
import { useUpdateEdge } from './useUpdateEdge';
import { Edge, Node } from '../microgrid.types';

export function useMutateMicrogridSetup({
  projectId,
  microgridId,
}: {
  projectId: string;
  microgridId: string;
}) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { createNode, isLoading: nodesLoading } = useCreateNode(
    projectId,
    microgridId
  );
  const { createEdge, isLoading: edgesLoading } = useCreateEdge(
    projectId,
    microgridId
  );
  const { updateNode, isLoading: updateNodesLoading } = useUpdateNode(
    projectId,
    microgridId
  );
  const { updateEdge, isLoading: updateEdgesLoading } = useUpdateEdge(
    projectId,
    microgridId
  );

  const MicroGridsKeys = getQueryKeys(`/projects/${projectId}/microgrids/`);

  const mutateMicrogridSetup = useCallback(
    async (nodes: Node[], edges: Edge[]) => {
      try {
        const newNodesPromises = (await Promise.all(
          nodes.filter((node) => node.isNew).map((node) => createNode(node))
        )) as Node[];
        const updatedNodesPromises = await Promise.all(
          nodes.filter((node) => !node.isNew).map((node) => updateNode(node))
        );
        // replace the old ids with the id retrieved from the backend for the new nodes
        const edgesPromises = await Promise.all(
          edges.map((edge) => {
            const newEdge = { ...edge };
            const source = newNodesPromises.find(
              (node) => node.data.externalId === newEdge.source
            );
            const target = newNodesPromises.find(
              (node) => node.data.externalId === newEdge.target
            );
            if (source) newEdge.source = source.id;
            if (target) newEdge.target = target.id;
            if (newEdge.isNew) return createEdge(newEdge);
            else return updateEdge(newEdge);
          })
        );

        await queryClient.invalidateQueries(MicroGridsKeys.list());

        enqueueSnackbar(
          t('genericSuccesses.create', {
            entity: t('entities:microgrid.nameSingular').toLowerCase(),
            identifier: microgridId,
          }),
          { variant: 'success' }
        );

        return {
          nodes: [...newNodesPromises, ...updatedNodesPromises],
          edges: edgesPromises,
        };
      } catch (error) {
        enqueueSnackbar(
          `Something went wrong while creating nodes for the configuration: ${error}`,
          { variant: 'error' }
        );
        return error;
      }
    },
    [
      createNode,
      updateNode,
      createEdge,
      updateEdge,
      queryClient,
      MicroGridsKeys,
      enqueueSnackbar,
      t,
      microgridId,
    ]
  );
  return {
    mutateMicrogridSetup,
    isLoading:
      nodesLoading || edgesLoading || updateNodesLoading || updateEdgesLoading,
  };
}
