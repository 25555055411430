import { styled, useTheme } from '@mui/material/styles';
import { Button, ButtonProps, IconButton } from '@mui/material';
import {
  SaveOutlined,
  BackspaceOutlined,
  EditOutlined,
  AddCircleOutlineOutlined,
  DeleteOutlineOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { LoadingButton as MuiLoadingButton } from '@mui/lab';
import { LoadingButtonProps, AddButtonProps } from './buttons.types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactNode } from 'react';

export const ButtonBase = styled(
  ({ variant = 'contained', children, ...props }: ButtonProps) => (
    <Button
      {...props}
      variant={variant}
      sx={{ ...(variant === 'contained' && { color: '#fff' }) }}
    >
      {children}
    </Button>
  )
)``;

export const LoadingButton = ({
  loading,
  children,
  ...props
}: LoadingButtonProps) => {
  return (
    <MuiLoadingButton
      loading={loading}
      variant="contained"
      color="primary"
      data-cy="loading-button"
      {...props}
    >
      {children}
    </MuiLoadingButton>
  );
};

export function SaveButton({ children, ...rest }: ButtonProps) {
  const { t } = useTranslation();
  const buttonContent = children ? children : t('components:form.save');

  return (
    <Button
      variant="contained"
      color="primary"
      endIcon={<SaveOutlined />}
      {...rest}
    >
      {buttonContent}
    </Button>
  );
}

export function DiscardButton({ children, ...rest }: ButtonProps) {
  const { t } = useTranslation();
  const buttonContent = children ? children : t('components:form.discard');

  return (
    <Button variant="text" startIcon={<BackspaceOutlined />} {...rest}>
      {buttonContent}
    </Button>
  );
}

export function EditButton({ children, ...rest }: ButtonProps) {
  const { t } = useTranslation();
  const buttonContent = children ? children : t('components:form.edit');

  return (
    <Button variant="outlined" startIcon={<EditOutlined />} {...rest}>
      {buttonContent}
    </Button>
  );
}

interface RemoveButtonProps extends ButtonProps {
  children?: ReactNode;
  onClick?: () => void;
}

export function RemoveButton({
  children,
  onClick,
  ...rest
}: RemoveButtonProps) {
  const { t } = useTranslation();
  const buttonContent = children ? children : t('components:form.remove');

  return (
    <Button
      color="error"
      variant="text"
      onClick={onClick}
      startIcon={<DeleteOutlineOutlined />}
      {...rest}
    >
      {buttonContent}
    </Button>
  );
}

export function AddButton({ onClick, children, ...props }: AddButtonProps) {
  const { t } = useTranslation();

  const buttonContent = children ? children : t('components:form.add');

  return (
    <Button
      onClick={onClick}
      data-cy="add-button"
      variant="contained"
      color="primary"
      startIcon={<AddCircleOutlineOutlined />}
      {...props}
    >
      {buttonContent}
    </Button>
  );
}

/**
 * Button that display text or not based on the given breakpoint
 */
export const ResponsiveButton = ({
  icon: Icon,
  breakpoint = 'xs',
  children,
  ...rest
}: {
  icon: () => JSX.Element;
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children: ReactNode;
} & Partial<ButtonProps>) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(breakpoint));

  return matches ? (
    <Button
      {...rest}
      startIcon={<Icon />}
      sx={{
        fontSize: 12,
      }}
    >
      {children}
    </Button>
  ) : (
    <IconButton {...rest}>
      <Icon />
    </IconButton>
  );
};
