import { gql } from 'graphql-request';

export const MINIMAL_PROJECT_GREENER_UNIT_INSTANCES_KEY = (
  projectId: string | number
) => ['greenerUnitInstances', 'projectId', projectId];

export const READ_MINIMAL_PROJECT_GREENER_UNIT_INSTANCES = gql`
  query getProjectUnitInstances($projectId: Int!) {
    projectGreenerUnitInstances(projectId: $projectId) {
      projectId
      projectUnitInstanceId
      tsStart
      tsEnd
      greenerUnit {
        greenerUnitId
        externalIdentifier
      }
      greenerEye {
        greenerEyeId
        externalIdentifier
      }
    }
  }
`;

export const PROJECT_GREENER_UNIT_INSTANCES_KEY = (
  projectId: string | number
) => ['greenerUnitInstances', 'projectId', projectId];

export const READ_PROJECT_GREENER_UNIT_INSTANCES = gql`
  query getProjectUnitInstances($projectId: Int!) {
    projectGreenerUnitInstances(projectId: $projectId) {
      projectId
      projectUnitInstanceId
      tsStart
      tsEnd
      greenerUnit {
        greenerUnitId
        externalIdentifier
        name
        description
        owningOrganisationObjectId
        defaultGreenerEyeId
        watcherTypeId
        owningOrganisationId
        watcherType {
          watcherTypeId
          externalIdentifier
          deviceType {
            deviceTypeId
            externalIdentifier
            consumesPower
            producesPower
          }
        }
      }
      greenerEye {
        greenerEyeId
        externalIdentifier
      }
    }
  }
`;

// Create multiple
export const CREATE_PROJECT_UNIT_INSTANCES = gql`
  mutation CreateProjectUnitInstances(
    $projectId: Int!
    $projectGreenerUnitInstances: [PGUIInput]!
  ) {
    createProjectGreenerUnitInstances(
      projectId: $projectId
      projectGreenerUnitInstances: $projectGreenerUnitInstances
    ) {
      projectGreenerUnitInstances {
        greenerUnitId
        projectUnitInstanceId
        projectId
      }
    }
  }
`;

// Create single
export const CREATE_PROJECT_GREENER_UNIT_INSTANCE = gql`
  mutation createGUI(
    $projectGreenerUnitInstance: PGUIInput!
    $projectId: Int!
  ) {
    createProjectGreenerUnitInstance(
      projectGreenerUnitInstance: $projectGreenerUnitInstance
      projectId: $projectId
    ) {
      projectGreenerUnitInstance {
        projectId
        projectUnitInstanceId
        tsStart
        tsEnd
        greenerUnitId
        greenerUnit {
          externalIdentifier
        }
        greenerEyeId
      }
    }
  }
`;

export const UPDATE_PROJECT_GREENER_UNIT_INSTANCE = gql`
  mutation updateGUI(
    $projectGreenerUnitInstance: PGUIInput!
    $projectGreenerUnitInstanceId: Int!
    $projectId: Int!
  ) {
    updateProjectGreenerUnitInstance(
      projectGreenerUnitInstance: $projectGreenerUnitInstance
      projectGreenerUnitInstanceId: $projectGreenerUnitInstanceId
      projectId: $projectId
    ) {
      projectGreenerUnitInstance {
        projectId
        projectUnitInstanceId
        tsStart
        tsEnd
        greenerUnitId
        greenerEyeId
        greenerUnit {
          greenerUnitId
          externalIdentifier
        }
        greenerEye {
          greenerEyeId
          externalIdentifier
        }
      }
    }
  }
`;

export const DELETE_PROJECT_GREENER_UNIT_INSTANCE = gql`
  mutation deleteGUI($projectGreenerUnitInstanceId: Int!, $projectId: Int!) {
    deleteProjectGreenerUnitInstance(
      projectGreenerUnitInstanceId: $projectGreenerUnitInstanceId
      projectId: $projectId
    ) {
      projectId
      projectGreenerUnitInstanceId
    }
  }
`;
