import { SvgIcon } from '@mui/material';
import { UsersIcon } from '../../../icons';
import { useTranslation } from 'react-i18next';

export default function Users() {
  const { t } = useTranslation();
  return (
    <>
      <SvgIcon
        fontSize="small"
        color="inherit"
        style={{ marginRight: '0.5em' }}
      >
        <UsersIcon />
      </SvgIcon>
      {` ${t('navigation.users')}`}
    </>
  );
}
