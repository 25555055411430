import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { useRemoveNode } from './useRemoveNode';
import { useRemoveEdge } from './useRemoveEdge';
import { Node, Edge } from '../microgrid.types';

export function useRemoveMicrogridSetup({
  projectId,
  microgridId,
}: {
  projectId: string;
  microgridId: string;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { removeNode } = useRemoveNode(projectId, microgridId);
  const { removeEdge } = useRemoveEdge(projectId, microgridId);

  const removeMicrogridSetup = useCallback(
    async ({ nodes, edges }: { nodes: Node[]; edges: Edge[] }) => {
      try {
        const edgesPromises = await Promise.all(
          edges.map((edge) => removeEdge(edge))
        );
        const nodesPromises = await Promise.all(
          nodes.map((node) => removeNode(node))
        );
        return {
          removedNodeIds: nodesPromises,
          removedEdgeIds: edgesPromises,
        };
      } catch (error) {
        enqueueSnackbar(
          `Something went wrong while removing nodes for the configuration: ${error}`,
          { variant: 'error' }
        );
        return error;
      }
    },
    [removeNode, removeEdge, enqueueSnackbar]
  );

  return { removeMicrogridSetup };
}
