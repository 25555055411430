import {
  generatePath,
  useNavigate,
  useLocation,
  useParams,
  Outlet,
} from 'react-router';
import { UnitDetailTopbar } from 'components/layout/UnitDetailTopbar';
import { determineUnitAvailableDateRange } from 'utils/units';
import useProject from 'hooks/useProject';
import { useTimerange } from 'hooks/useTimerange';
import { getUnitInstancesDateStatus } from 'utils/units';
import { GREENER_UNIT_KEY, READ_GREENER_UNIT } from 'services/gql/units';
import { useGQLQuery } from 'hooks/useGQLQuery';

export const ProjectMonitoringLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectSlug, unitExternalIdentifier } = useParams();
  const {
    projectUnits: units,
    projectUnitInstances: instances,
    project,
  } = useProject();

  const splitPath = location.pathname.split('/');
  const postFix = splitPath[splitPath.length - 1];

  const availableRange = determineUnitAvailableDateRange(
    project,
    instances,
    unitExternalIdentifier
  );

  const unit = useGQLQuery(
    GREENER_UNIT_KEY(unitExternalIdentifier),
    READ_GREENER_UNIT,
    {
      externalIdentifier: unitExternalIdentifier,
    }
  ).data?.greenerUnit;

  const { range, ...timerangeProps } = useTimerange(availableRange);

  function createOnChangeUnitFunction(pathPostFix) {
    function onChangeUnit(unit) {
      const newPath = generatePath(
        '/projects/:projectSlug/monitoring/:unitExternalIdentifier' +
          `/${pathPostFix}`,
        {
          projectSlug,
          unitExternalIdentifier: unit.externalIdentifier,
        }
      );
      navigate(newPath, { replace: true });
    }
    return onChangeUnit;
  }

  const assetSelected = units.find(
    (unit) => unit.externalIdentifier === unitExternalIdentifier
  );

  const assetDateStatus = getUnitInstancesDateStatus(
    instances,
    unitExternalIdentifier
  );

  const type = unit?.watcherType.deviceType.externalIdentifier;

  return (
    <>
      <UnitDetailTopbar
        unitSelectorProps={{
          units,
          onChange: createOnChangeUnitFunction(postFix),
          selectedUnitExtId: unitExternalIdentifier,
        }}
        timerangePickerProps={{
          range,
          availableRange,
          ...timerangeProps,
        }}
        showRangePicker={!location.pathname.includes('asset')}
      />
      <Outlet
        context={{
          unit,
          type,
          unitExternalIdentifier,
          assetSelected,
          range,
          assetDateStatus,
        }}
      />
    </>
  );
};
