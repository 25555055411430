import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getQueryKeys } from 'services/queryKeys';
import {
  generateErrorMessages,
  mutateMicrogridInfoMapper,
} from '../microgrid.utils';
import { ErrorDetail, Microgrid } from '../microgrid.types';
import useAxiosMutation from 'hooks/useAxiosMutation';
import { UseMutationConfig } from 'hooks/hooks.types';

export function useUpdateMicrogrid({
  projectId,
  onSuccess,
  onError,
}: {
  projectId?: number;
  onError?: (error: ErrorDetail, vars: UseMutationConfig) => void;
  onSuccess?: (data: Microgrid) => void;
}) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const path = `/projects/${projectId}/microgrids/`;
  const microgridsQueryKeys = getQueryKeys(path);

  const {
    mutateAsync: updateMicrogridMutation,
    data,
    isError,
    error,
    isLoading,
    ...others
  } = useAxiosMutation({
    onError: (error: ErrorDetail, vars: UseMutationConfig) => {
      !!onError && onError(error, vars);

      const generatedErrors = generateErrorMessages(
        error.response?.data?.detail,
        t
      );

      // The generateErrorMessages function should only be fired when the user tries to perform a microgrid validation
      if (!!generatedErrors?.length) {
        enqueueSnackbar(
          generateErrorMessages(error.response?.data?.detail, t)
            .map((error) => `\u2022 ${error}`)
            .join('\n'),
          {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
          }
        );
        return;
      }

      enqueueSnackbar(
        t('genericErrors.update', {
          entity: t('entities:microgrid.nameSingular').toLowerCase(),
          identifier: null,
        }) +
          ' ' +
          error.response?.data?.detail,
        { variant: 'error' }
      );
    },
    onSuccess: ({ data }: { data: Microgrid }) => {
      queryClient.setQueryData(
        microgridsQueryKeys.list(),
        (oldData: Microgrid[] = []) => {
          const newData = [...oldData];

          const targetIndex = newData.findIndex(
            (setup: Microgrid) => setup.microgridId === data.microgridId
          );

          newData[targetIndex] = data;

          return newData;
        }
      );

      !!onSuccess && onSuccess(data);
      enqueueSnackbar(
        t('genericSuccesses.update', {
          entity: t('entities:microgrid.nameSingular').toLowerCase(),
          identifier: data.name,
        }),
        { variant: 'success' }
      );
    },
  });

  const updateMicrogrid = async (microgrid: Partial<Microgrid>) => {
    const mappedMicrogrid = mutateMicrogridInfoMapper(microgrid);

    return await updateMicrogridMutation({
      method: 'PATCH',
      path: `${path}${microgrid.microgridId}/`,
      body: mappedMicrogrid,
    });
  };

  return {
    updateMicrogrid,
    data,
    isError,
    error,
    isLoading,
    ...others,
  };
}
