import React from 'react';
import customColors from '../../styles/colors';

export default function GraphsIcon({
  height = 24,
  width = 24,
  strokeColor = customColors['secondary-dark'],
  strokeWidth = 1.5,
  fill = 'none',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
    >
      <g>
        <path
          d="M1.510 2.253 L22.510 2.253 L22.510 21.753 L1.510 21.753 Z"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M1.51 6.753L22.51 6.753"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M1.51,15.753H6.583a1.5,1.5,0,0,0,1.342-.829L9.7,11.373a.75.75,0,0,1,1.383.1l1.855,5.565a.75.75,0,0,0,1.382.1L16.1,13.583a1.5,1.5,0,0,1,1.341-.83H22.51"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
      </g>
    </svg>
  );
}
