import { Outlet, useParams } from 'react-router-dom';
import { ProjectProvider } from 'contexts/ProjectContext';
import { SocketProvider } from 'contexts/SocketContext';
import { SidebarItems } from './[Project].sidebar';
import '../translations';

export const ProjectLayout = () => {
  const { projectSlug } = useParams();

  return (
    <ProjectProvider projectSlug={projectSlug}>
      <SidebarItems />
      <SocketProvider>
        <Outlet />
      </SocketProvider>
    </ProjectProvider>
  );
};
