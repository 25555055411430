export type greenerprojects__rest__api__microgrid__models__User = {
  username: string;
};

export type Edge = {
  id: string;
  label?: string;
  source: string;
  sourceHandle: string;
  target: string;
  targetHandle: string;
  organisation?: { name: string };
  type?: string;
  isNew?: boolean;
};

export type Node = {
  data: (
    | BatteryData
    | GridConnectionData
    | GeneratorData
    | EnergyMeterData
    | LoadData
    | DistributionBoxData
  ) & { icon?: string };
  position?: { x: number; y: number };
  height?: number;
  width?: number;
  id: string;
  label?: string;
  positionAbsolute?: { x: number; y: number };
  type: NodeTypes;
  isNew?: boolean;
};

export type Microgrid = {
  projectId: number;
  name: string;
  description: string;
  createdByUser: greenerprojects__rest__api__microgrid__models__User;
  tsStart: Date;
  tsEnd?: Date;
  tsCreated: string;
  tsLastModified: string;
  label?: string;
  microgridId: string;
  nodes?: Array<Node>;
  edges?: Array<Edge>;
  status?: string;
  visibleToClients: boolean;
};

export type MicrogridCreate = {
  name: Microgrid['name'];
  description: Microgrid['description'];
  tsStart: Microgrid['tsStart'];
  tsEnd?: Microgrid['tsEnd'];
  label?: Microgrid['label'];
};

export enum ConnectionFitType {
  '_0' = 0,
  '_1' = 1,
}

export enum ConnectorType {
  EE_1P = 'ee-1p',
  CEE_3P = 'cee-3p',
  POWERLOCK = 'powerlock',
  CABLE_LUG = 'cable_lug',
  SCREW_TERMINAL = 'screw_terminal',
}

export type Connection = {
  id: string;
  rated_current?: number;
  /**
   *  0: hole, 1: pin
   */
  connection_fit?: ConnectionFitType;
  connector_type?: ConnectorType;
};

export type LoadData = {
  externalId: string;
  greenerUnitExtId?: string;
  /**
   * In amps
   */
  ratedCurrent?: number;
  /**
   * In kW
   */
  ratedActivePower?: number;
  label: string;
  connections: Array<Connection>;
};

export enum NodeTypes {
  LOAD = 'LOAD',
  BATTERY = 'BATTERY',
  GRID = 'GRID',
  ENERGY_METER = 'ENERGY_METER',
  DIESEL_GENSET = 'DIESEL_GENSET',
  DISTRIBUTION_BOX = 'DISTRIBUTION_BOX',
  ANONYMOUS_NODE = 'ANONYMOUS_NODE',
}

export type BatteryData = {
  externalId: string;
  greenerUnitExtId?: string;
  /**
   * In kWh
   */
  storageCapacity?: number;
  /**
   * In kVA
   */
  ratedApparentPower?: number;
  /**
   * In kW
   */
  ratedActivePower?: number;
  label: string;
  connections: Array<Connection>;
  /**
   * Set to True if this battery is in the lead.
   */
  isPrimary?: boolean;
  isBatterySwap?: boolean;
};

export type AnonymousData = {
  label: string;
  description?: string;
};

export type DistributionBoxData = {
  externalId: string;
  greenerUnitExtId?: string;
  /**
   * In amps
   */
  ratedCurrent?: number;
  label: string;
  connections: Array<Connection>;
};

export type GridConnectionData = {
  externalId: string;
  label: string;
  /**
   * In kVA
   */
  ratedApparentPower?: number;
  /**
   * In amps
   */
  ratedCurrent?: number;
  /**
   * In kW, maximum peak power allowed by the grid operator
   */
  contractedActivePower?: number;
  greenerUnitExtId?: string;
  connections: Array<Connection>;
};
export type GeneratorData = {
  externalId: string;
  greenerUnitExtId?: string;
  /**
   * In kVA
   */
  ratedApparentPower?: number;
  /**
   * In kW
   */
  ratedActivePower?: number;
  relayId?: number;
  activePowerSetting?: number;
  controlledByGreenerUnitExtId?: string;
  label: string;
  connections: Array<Connection>;
};
export type EnergyMeterData = {
  externalId: string;
  greenerUnitExtId?: string;
  /**
   * In amps
   */
  ratedCurrent?: number;
  label: string;
  connections: Array<Connection>;
};

export type NodeRead = {
  meta?: Record<string, unknown>;
  id: string;
  type: NodeTypes;
  data:
    | BatteryData
    | GridConnectionData
    | GeneratorData
    | EnergyMeterData
    | LoadData
    | DistributionBoxData;
};

export enum EdgeMaterial {
  CU = 'cu',
  AL = 'al',
}
export type EdgeCreate = {
  /**
   * FROM node id
   */
  source_id?: string;
  /**
   * FROM connection id
   */
  source_connection_id?: string;
  /**
   * TO node id
   */
  target_id?: string;
  /**
   * TO connection id
   */
  target_connection_id?: string;
  meta?: Record<string, unknown>;
  label?: string;
  rated_current?: number;
  material?: EdgeMaterial;
  /**
   * Cable diameter in mm.
   */
  diameter?: number;
  /**
   * Length of the cable in m.
   */
  length?: number;
};

export type EdgeRead = {
  /**
   * FROM node id
   */
  sourceId?: string;
  /**
   * FROM connection id
   */
  sourceConnectionId?: string;
  /**
   * TO node id
   */
  targetId?: string;
  /**
   * TO connection id
   */
  targetConnectionId?: string;
  meta?: Record<string, unknown>;
  label?: string;
  ratedCurrent?: number;
  material?: EdgeMaterial;
  /**
   * Cable diameter in mm.
   */
  diameter?: number;
  /**
   * Length of the cable in m.
   */
  length?: number;
  id: string;
};

export interface ErrorObject {
  microgrid_compatibility_errors: {
    greener_unit_not_registered: string[];
    greener_unit_not_registered_for_complete_period: string[];
  };
  microgrid_edge_errors: {
    connections_with_multiple_edges: string[];
    edge_id_not_unique: string[];
    edges_with_invalid_connections: string[];
    edges_with_invalid_nodes: string[];
  };
  microgrid_node_errors: {
    microgrid_genset_node_misses_rated_apparent_power: string[];
    microgrid_greener_unit_ext_id_not_unique: string[];
    microgrid_grid_node_misses_rated_current: string[];
    microgrid_has_no_nodes: string[];
    microgrid_node_misses_greener_unit_ext_id: string[];
    microgrid_nodes_not_unique: string[];
    microgrid_nodes_without_edges: string[];
  };
  project_microgrid_compatibility_errors: {
    incompatible_project_dates: string[];
    overlapping_valid_microgrids: string[];
  };
  project_microgrid_errors: { project_microgrid_invalid_dates: string };
}

export type UpdateNodeData = {
  externalId?: string;
  label?: string;
  connections?: Array<Connection>;
  greenerUnitExtId?: string;
  /**
   * In kWh
   */
  storageCapacity?: number;
  /**
   * In amps
   */
  ratedCurrent?: number;
  /**
   * In kVA
   */
  ratedApparentPower?: number;
  /**
   * In kW
   */
  ratedActivePower?: number;
  /**
   * In kW
   */
  relayId?: number;
  activePowerSetting?: number;
  controlledByGreenerUnitExtId?: string;
  connectionsNumber: number;
  isPrimary?: boolean;
};

export type UpdateNode = {
  meta?: Record<string, unknown>;
  data: UpdateNodeData;
};

export type ErrorDetail = { response: { data: { detail: ErrorObject } } };

export type ErrorVars = {
  body: { status: string };
  path: string;
  method: string;
};
