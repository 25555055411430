import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { useAxios } from './useAxios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { keysToCamelCase } from 'utils/formatters';
import { AxiosMutationReturn, UseMutationConfig } from './hooks.types';

/**
 * @param {string | array} key - used by React Query for caching
 * @param {string} path
 * @param {object} object
 * @property {string} object.method - request method
 * @property {object} object.body - request body
 * @property {object} object.config
 * @returns {object}
 */

export default function useAxiosMutation<T, E>(
  config: {
    onSuccess?: (response: {
      data: T;
      response: Partial<AxiosResponse>;
    }) => void;
    onError?: (error: E, vars: UseMutationConfig) => void;
  } = {}
): AxiosMutationReturn {
  const { axiosRequest } = useAxios();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { mutateAsync, data, isError, error, isLoading, ...others } =
    useMutation<AxiosResponse<unknown>, E, UseMutationConfig>(axiosRequest, {
      onError: () => {
        enqueueSnackbar(t('genericErrors.somethingWentWrong'), {
          variant: 'error',
        });
      },
      ...config,
      onSuccess: ({ data, ...response }) => {
        const { onSuccess } = config;
        // Convert keys to camelCase
        if (typeof onSuccess === 'function') {
          onSuccess({ data: keysToCamelCase(data), response });
        }
      },
    });

  return {
    mutateAsync,
    data: keysToCamelCase(data?.data),
    isError,
    error,
    isLoading,
    ...others,
  };
}
