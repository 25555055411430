import { useState, useMemo } from 'react';
import { LoadingScreen } from 'components/fallback/LoadingScreen';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useBreakpoint } from 'hooks/useBreakpoint';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Sidebar, { drawerWidth } from '../Sidebar';
import { Topbar } from '../Topbar';
import { Breadcrumb } from '../Breadcrumb';
import useScreenSize from 'hooks/useScreenSize';
import SearchDrawer from '../Topbar/SearchDrawer';
import '../../translations';
import { PWAWrapper } from 'PWAWrapper';

const baseMixin = {
  overflowX: 'hidden',
  overflowY: 'scroll',
  pb: 5,
  height: '100%',
};

const lgMixin = (isSidebarOpen, dimensions) => ({
  marginLeft: isSidebarOpen ? `${drawerWidth}px` : '40px',
  maxWidth: isSidebarOpen
    ? dimensions.width - drawerWidth
    : dimensions.width - 40, // Subtract the width of the sidebar when opened/closed
  transition: 'all 500ms ease 0s',
  ...baseMixin,
});

const mdMixin = {
  width: '95%',
  marginLeft: '5%',
  ...baseMixin,
};

const smMixin = {
  right: 20,
  left: 20,
  ...baseMixin,
};

export function Layout() {
  const { dimensions, breakpoints } = useScreenSize();
  const { isDesktop } = useBreakpoint();
  const [openSideBar, setOpenSideBar] = useState(false);
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState(false);

  useMemo(() => {
    if (isDesktop) {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [isDesktop]);

  return (
    <PWAWrapper>
      <CssBaseline />
      <Topbar
        toggleSidebar={() => setOpenSideBar(!openSideBar)}
        openSearchDrawer={setIsSearchDrawerOpen}
      />
      {/* On the left: */}
      <Sidebar open={openSideBar} setOpen={setOpenSideBar} />
      {/* On the Right: */}
      <SearchDrawer
        data-cy="search-drawer"
        isSearchDrawerOpen={isSearchDrawerOpen}
        setIsSearchDrawerOpen={setIsSearchDrawerOpen}
      />

      <Container
        maxWidth={false}
        sx={
          dimensions.width > breakpoints.md
            ? lgMixin(openSideBar, dimensions)
            : dimensions.width > breakpoints.sm &&
              dimensions.width <= breakpoints.md
              ? mdMixin
              : smMixin
        }
      >
        <Suspense fallback={<LoadingScreen />}>
          <Breadcrumb />
          <Outlet />
        </Suspense>
      </Container>
    </PWAWrapper>
  );
}
