import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useCallback,
  useRef,
} from 'react';
import { Socket, io } from 'socket.io-client';
import { WEBSOCKET_URL } from '../constants';
import useAuth from 'hooks/useAuth';
import useProject from 'hooks/useProject';
import { isFuture, isPast, parseISO } from 'date-fns';

export type SocketContextType = {
  socketInstance: Socket | null;
  joinUnitRooms: (roomIds: string[]) => void;
  leaveUnitRooms: (roomIds: string[]) => void;
  leaveAllUnitRooms: () => void;
  disconnect: () => void;
  removeListeners: () => void;
  setTimeSeriesListener: (unitId: string, cb: (data: unknown) => void) => void;
  setStateListener: (unitId: string, cb: (data: unknown) => void) => void;
  setEventsListener: (unitId: string, cb: (data: string) => void) => void;
};

export const SocketContext = createContext<SocketContextType | undefined>(
  undefined
);

/**
 * Determines whether a connection to the provided project rooms should be stablished. If there's no project available,
 * connection is allowed because it's supposed to be outside the context of a project, where the user should have
 * authorisation. If there's a project, it shouldn't be a past or future one.
 **/
const shouldJoinProjectRooms = (project) => {
  if (!project) return true;

  if (
    (project.tsEnd && isPast(parseISO(project.tsEnd))) ||
    isFuture(parseISO(project.tsStart))
  )
    return false;

  return true;
};

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const { getTokenSilently, user } = useAuth();
  const [socket, setSocket] = useState<Socket | null>(null);
  const { project } = useProject();

  const subscribedRoomsRef = useRef<string[]>([]);

  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const clearReconnectTimeout = useCallback(() => {
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }
  }, []);

  useEffect(() => {
    const establishSocket = async () => {
      const accessToken = await getTokenSilently();

      const newSocket = io(WEBSOCKET_URL, {
        path: '/socket/socket.io',
        auth: { accessToken },
        reconnection: true,
        reconnectionAttempts: 5, // Maximum number of reconnection attempts
        reconnectionDelay: 2000, // Initial delay of reconnection (ms)
        ackTimeout: 10000,
        transports: ['websocket', 'polling'], // use WebSocket first
      });

      setSocket(newSocket);
      newSocket.on('connect', () => {
        clearReconnectTimeout();
        if (subscribedRoomsRef.current.length > 0) {
          newSocket.emit('join_units', { rooms: subscribedRoomsRef.current });
        }
      });

      newSocket.on('disconnect', (reason) => {
        if (reason !== 'transport close') {
          // Schedule a manual reconnection after 5 seconds
          reconnectTimeoutRef.current = setTimeout(() => {
            establishSocket();
          }, 5000);
        }
      });
    };

    if (user && !socket && shouldJoinProjectRooms(project)) {
      establishSocket();
    }
  }, [user, getTokenSilently, socket]);

  const joinUnitRooms = useCallback(
    (roomIds: string[]) => {
      subscribedRoomsRef.current = Array.from(
        new Set([...subscribedRoomsRef.current, ...roomIds])
      );
      socket?.emit('join_units', { rooms: roomIds });
    },
    [socket]
  );

  const leaveUnitRooms = useCallback(
    (roomIds: string[]) => {
      subscribedRoomsRef.current = subscribedRoomsRef.current.filter(
        (room) => !roomIds.includes(room)
      );
      socket?.emit('leave_units', { rooms: roomIds });
    },
    [socket]
  );

  const leaveAllUnitRooms = useCallback(() => {
    subscribedRoomsRef.current = [];
    socket?.emit('leave_units', { all: true });
  }, [socket]);

  const disconnect = useCallback(() => socket?.disconnect(), [socket]);

  //Listeners

  const setEventsListener = useCallback(
    (unitId: string, cb: (data: string) => void) => {
      const eventName = `${unitId}/events`;
      socket?.on(eventName, (data: string) => cb(data));
    },
    [socket]
  );

  const setTimeSeriesListener = (
    unitId: string,
    cb: (data: unknown) => void
  ) => {
    const eventName = `${unitId}/ts`;
    socket?.on(eventName, (data: unknown) => cb(data));
  };

  const setStateListener = (unitId: string, cb: (data: unknown) => void) => {
    const eventName = `${unitId}/state`;
    socket?.on(eventName, (data: unknown) => cb(data));
  };

  const removeListeners = () => {
    socket?.removeAllListeners();
  };

  useEffect(() => {
    // Socket will be disconnected when component unmounts
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  const contextValue: SocketContextType = {
    socketInstance: socket,
    joinUnitRooms,
    leaveUnitRooms,
    leaveAllUnitRooms,
    disconnect,
    removeListeners,
    setTimeSeriesListener,
    setStateListener,
    setEventsListener,
  };

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};
