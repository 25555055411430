import { useEffect } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSidebarItems } from 'hooks/useSidebarItems';
import useAuth from 'hooks/useAuth';
import { canSeeAnalyticalData } from 'utils/authorization';

/* Queries */
import { ProjectIcon, GraphIcon, AnalyticsIcon } from 'components/icons';

/* Pages */
import ProjectSidebarHeader from 'components/layout/ProjectSidebarHeader';
import useProject from 'hooks/useProject';

function constructProjectMenu(t, projectSlug) {
  const menuItems = [
    {
      name: t('projects:navigation.home'),
      path: `/projects/${projectSlug}`,
    },
    {
      name: t('projects:navigation.overview'),
      path: `/projects/${projectSlug}/administration`,
    },
  ];
  menuItems.push({
    name: t('projects:navigation.configurationDiagram'),
    path: `/projects/${projectSlug}/setups`,
  });

  return {
    name: t('projects:navigation.project'),
    icon: ProjectIcon,
    items: menuItems,
  };
}

function constructMonitoringMenu(t, projectSlug, preselectedUnitExtId) {
  if (!preselectedUnitExtId) {
    return null;
  }

  const menuItems = [];
  menuItems.push({
    name: t('projects:navigation.unitControl'),
    path: `/projects/${projectSlug}/monitoring/${preselectedUnitExtId}/asset`,
  });
  menuItems.push({
    name: t('projects:navigation.basicGraphs'),
    path: `/projects/${projectSlug}/monitoring/${preselectedUnitExtId}/basic-graphs`,
  });
  menuItems.push({
    name: t('projects:navigation.advancedGraphs'),
    path: `/projects/${projectSlug}/monitoring/${preselectedUnitExtId}/advanced-graphs`,
  });

  return {
    name: t('projects:navigation.unitMonitoring'),
    icon: GraphIcon,
    items: menuItems,
  };
}

function constructAnalyticsMenu(t, projectSlug, role) {
  const menuItems = [];

  if (canSeeAnalyticalData(role)) {
    menuItems.push({
      name: t('projects:navigation.reports'),
      path: `/projects/${projectSlug}/analytics/reports`,
    });
    menuItems.push({
      name: t('projects:navigation.analyticsSummary'),
      path: `/projects/${projectSlug}/analytics/summary`,
    });
    menuItems.push({
      name: t('projects:navigation.assetAnalytics'),
      path: `/projects/${projectSlug}/analytics/assets`,
    });
  }
  return {
    name: t('projects:navigation.analytics'),
    icon: AnalyticsIcon,
    items: menuItems,
  };
}

function determinePreselectedUnitExtId(unitMonitoringMatch, units) {
  if (unitMonitoringMatch)
    return unitMonitoringMatch.params.unitExternalIdentifier;
  if (units && units.length > 0) return units[0].externalIdentifier;

  return null;
}

export function SidebarItems() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { projectSlug } = useParams();
  const unitMonitoringMatch = useMatch(
    '/projects/:projectSlug/monitoring/:unitExternalIdentifier/*'
  );
  const { dispatchSidebar } = useSidebarItems();
  const { project, projectUnits } = useProject();

  const unitExternalIdentifier = determinePreselectedUnitExtId(
    unitMonitoringMatch,
    projectUnits
  );

  useEffect(() => {
    const projectMenu = constructProjectMenu(t, projectSlug);

    const monitoringMenu = constructMonitoringMenu(
      t,
      projectSlug,
      unitExternalIdentifier
    );
    const analyticsMenu =
      user?.role.canRequestProjectReports || user?.role.canSeeAnalyticalData
        ? constructAnalyticsMenu(t, projectSlug, user?.role)
        : null;

    dispatchSidebar({
      type: 'REGULAR',
      payload: {
        sidebarHeader: <ProjectSidebarHeader project={project} />,
        items: [projectMenu, monitoringMenu, analyticsMenu],
      },
    });
  }, [project, user, projectSlug, dispatchSidebar, t, unitExternalIdentifier]);

  return <></>;
}

SidebarItems.displayName = '[Project].sidebar.tsx';
