import { useState, Dispatch, SetStateAction, useCallback } from 'react';
import { LocalStorageReturn } from '../hooks.types';

export function useLocalStorage<T>(
  key: string,
  initialState: T
): LocalStorageReturn<T> {
  // Get from local storage then
  // parse stored json or return initialState
  const readValue = (): T => {
    if (typeof window === 'undefined') {
      return initialState;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialState;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialState;
    }
  };

  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that
  // persists the new value to localStorage.
  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (value) => {
      if (typeof window == 'undefined') {
        console.warn(
          `Tried setting localStorage key “${key}” even though environment is not a client`
        );
        return;
      }

      try {
        // Allow value to be a function so we have the same interface as useState
        const newValue = value instanceof Function ? value(storedValue) : value;

        window.localStorage.setItem(key, JSON.stringify(newValue));

        setStoredValue(newValue);
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error);
      }
    },
    [key, storedValue]
  );

  return [storedValue, setValue];
}
