import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LNGS } from '../../../translations';

const PREFIX = 'LanguageSelector';

const classes = {
  popover: `${PREFIX}-popover`,
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`& .${classes.popover}`]: {
    width: 200,
  },
}));

export default function LanguageSelector() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n } = useTranslation();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleSelect = async (lng) => {
    await i18n.changeLanguage(lng);
    handleClose();
  };

  return (
    <StyledMenuItem onClick={handleClick}>
      <Typography>
        {t('main:preferences.language.selected', { lng: i18n.language })}
      </Typography>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        variant="selectedMenu"
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{ className: classes.popover }}
      >
        {SUPPORTED_LNGS.map((lng) => (
          <MenuItem
            key={lng}
            selected={i18n.language === lng}
            onClick={() => handleSelect(lng)}
          >
            {lng}
          </MenuItem>
        ))}
      </Menu>
    </StyledMenuItem>
  );
}
