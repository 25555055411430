// Copyright 2021 Greener Power Solutions                 //
// This file is part of the Greener Power Solutions,      //
// and may not be altered, copied, redistributed, or sold //
// without specific permission.                           //
import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ProjectLogo from 'components/icons/ProjectLogo/ProjectLogo';
import { representDate } from 'utils/formatters';
import { styled } from '@mui/material/styles';

const ProjectInfoItem = styled(Grid)({
  width: '100%',
  alignItems: 'inherit',
  textAlign: 'center',
});

const ProjectName = styled(Typography)({
  marginLeft: 16,
  marginRight: 16,
  marginBottom: 10,
  wordWrap: 'break-word',
  whiteSpace: 'normal',
});

/**
 * Displays a project's logo, name, start and end date in a small section intended as sidebar header.
 */
export default function ProjectSidebarHeader({ project }) {
  const tsStart = representDate(project.tsStart);
  const tsEnd = representDate(project.tsEnd);

  return (
    <>
      <Grid container sx={{ alignItems: 'center', flexDirection: 'column' }}>
        <ProjectInfoItem item>
          <ProjectLogo
            projectLogoUrl={project.projectLogoUrl}
            projectName={project.name}
          />
        </ProjectInfoItem>
        <ProjectInfoItem item>
          <ProjectName variant="h6">{project.name}</ProjectName>
        </ProjectInfoItem>
        <ProjectInfoItem item>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: '1em',
              color: 'secondary.dark',
              alignItems: 'center',
            }}
          >
            {tsStart}
            <br />
            {tsEnd}
          </Typography>
        </ProjectInfoItem>
      </Grid>
      <Divider />
    </>
  );
}
