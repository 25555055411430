import { SvgIcon } from '@mui/material';
import { RoleIcon } from '../../../icons';
import { useTranslation } from 'react-i18next';

export default function Roles() {
  const { t } = useTranslation();
  return (
    <>
      <SvgIcon fontSize="small" style={{ marginRight: '0.5em' }}>
        <RoleIcon />
      </SvgIcon>
      {` ${t('navigation.roles')}`}
    </>
  );
}
