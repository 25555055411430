import React from 'react';
import { DEVICE_TYPES } from 'utils/constants';
import { GenericUnitSelector } from './GenericUnitSelector';

export function UnitSelector({
  units,
  selectedUnitExtId,
  onChange,
  groupByDevice,
  children,
}) {
  const selectors = groupByDevice ? (
    <>
      <GenericUnitSelector
        units={units}
        selectedUnitExtId={selectedUnitExtId}
        handleChange={onChange}
        unitType={DEVICE_TYPES.BATTERY}
      />
      <GenericUnitSelector
        units={units}
        selectedUnitExtId={selectedUnitExtId}
        handleChange={onChange}
        unitType={DEVICE_TYPES.ENERGY_METER}
      />
    </>
  ) : (
    <GenericUnitSelector
      units={units}
      selectedUnitExtId={selectedUnitExtId}
      handleChange={onChange}
    />
  );

  return (
    <>
      {selectors}
      {!!children && { children }}
    </>
  );
}
