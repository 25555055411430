// Copyright 2021 Greener Power Solutions                 //
// This file is part of the Greener Power Solutions,      //
// and may not be altered, copied, redistributed, or sold //
// without specific permission.                           //

import { createContext, useMemo } from 'react';
import { useGQLQuery } from '../hooks/useGQLQuery';
import { PROJECT_KEY, READ_PROJECT } from '../services/gql/projects';
import {
  PROJECT_GREENER_UNIT_INSTANCES_KEY,
  READ_PROJECT_GREENER_UNIT_INSTANCES,
} from '../services/gql/projectGreenerUnitInstances';
import {
  PROJECT_ORGANISATIONS_KEY,
  READ_PROJECT_ORGANISATIONS,
} from '../services/gql/projectOrganisations';
import { getDateStatus } from '../utils/dates';
import {
  ProjectContextProps,
  ProjectInstancesResponse,
  ProjectOrganisationResponse,
  ProjectProviderProps,
} from './contexts.types';
import { ProjectDataResponse } from './contexts.types';
import { useGetMicrogrids } from 'features/microgrid/hooks';
import useAuth from 'hooks/useAuth';
import { canManageMicrogrids } from 'utils/authorization';

export const ProjectContext = createContext<ProjectContextProps>({
  project: null,
  projectDateStatus: null,
  projectUnitInstances: [],
  projectOrganisations: [],
  projectUnits: [],
  projectMicrogrids: [],
  projectSlug: '',
  isDemo: false,
});

export function ProjectProvider({
  projectSlug,
  children,
}: ProjectProviderProps) {
  const { user } = useAuth();

  // Query data relevant for the project
  const { data: projectData } = useGQLQuery<ProjectDataResponse, Error>(
    PROJECT_KEY(projectSlug),
    READ_PROJECT,
    {
      externalIdentifier: projectSlug,
    }
  );

  const project = projectData?.project;

  const { data: projectUnitInstancesData } = useGQLQuery<
    ProjectInstancesResponse,
    Error
  >(
    PROJECT_GREENER_UNIT_INSTANCES_KEY(project?.projectId ?? ''),
    READ_PROJECT_GREENER_UNIT_INSTANCES,
    { projectId: project?.projectId }
  );

  const projectUnitInstances =
    projectUnitInstancesData?.projectGreenerUnitInstances;

  const { data: projectOrganisationsData } = useGQLQuery<
    ProjectOrganisationResponse,
    Error
  >(PROJECT_ORGANISATIONS_KEY(project?.projectId), READ_PROJECT_ORGANISATIONS, {
    projectId: project?.projectId,
  });

  const projectOrganisations = projectOrganisationsData?.projectOrganisations;

  // Get project status
  const projectDateStatus = getDateStatus(project?.tsStart, project?.tsEnd);

  // Extract units from instances
  const projectUnits = useMemo(() => {
    if (projectUnitInstances) {
      return projectUnitInstances.map((instance) => instance.greenerUnit);
    } else {
      return [];
    }
  }, [projectUnitInstances]);

  const isDemo =
    process.env.REACT_APP_DEMO_PROJECT_EXTID === project?.externalIdentifier;

  const { microgrids: projectMicrogrids } = useGetMicrogrids({
    projectId: project?.projectId,
    enabled: canManageMicrogrids(user?.role),
  });

  return (
    <ProjectContext.Provider
      value={{
        project,
        projectUnitInstances,
        projectOrganisations,
        projectDateStatus,
        projectUnits,
        projectMicrogrids,
        projectSlug,
        isDemo,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}
