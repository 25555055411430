import React from 'react';
import { styled } from '@mui/material/styles';
import { useSidebarItems } from 'hooks/useSidebarItems';
import { default as MuiDrawer } from '@mui/material/Drawer';
import { List, CssBaseline, Box } from '@mui/material';
import SidebarItem from '../SidebarItem';

export let drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  marginTop: 48,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  marginTop: 48,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
  [theme.breakpoints.down('sm')]: {
    width: 0,
  },
});

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  overflow: 'hidden',
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  marginTop: 48,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

// COMPONENT
export default function Sidebar({ open, setOpen }) {
  const { sidebarState } = useSidebarItems();

  const handleDrawerClick = () => {
    if (!open) setOpen(true);
  };

  return (
    <StyledDiv>
      <CssBaseline />
      <Drawer variant="permanent" open={open} onClick={handleDrawerClick}>
        <List component="nav" disablePadding>
          {open && sidebarState.sidebarHeader}
          <Box sx={{ pb: '5em' }}>
            {sidebarState.items
              .filter((item) => !!item)
              .map((item, index) => {
                return (
                  <SidebarItem
                    {...item}
                    openSidebar={handleDrawerClick}
                    isSidebarOpen={open}
                    key={index}
                  />
                );
              })}
          </Box>
        </List>
      </Drawer>
    </StyledDiv>
  );
}
