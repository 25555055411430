import React from 'react';
import { UnitSelector } from 'components/inputs/UnitSelector';
import { TimerangePicker } from 'components/inputs/TimerangePicker';
import { Grid } from '@mui/material';

export function UnitDetailTopbar({
  unitSelectorProps,
  timerangePickerProps,
  showRangePicker = true,
}) {
  return (
    <Grid spacing={2} alignItems="center" container py={2}>
      <Grid item xs={12} sm={6}>
        <UnitSelector {...unitSelectorProps} />
      </Grid>

      {showRangePicker && (
        <Grid container item xs={12} sm={6} justifyContent="flex-end">
          <TimerangePicker {...timerangePickerProps} />
        </Grid>
      )}
    </Grid>
  );
}
