import { Link as RouterLink } from 'react-router-dom';
import { SvgIcon } from '@mui/material';
import UnitControlIcon from '../../icons/UnitControlIcon';
import { useTranslation } from 'react-i18next';
import { ResponsiveButton } from 'components/buttons';

export default function UnitControl() {
  const { t } = useTranslation();
  return (
    <ResponsiveButton
      data-testid="assets-navigation"
      color="inherit"
      component={RouterLink}
      to="/assets"
      breakpoint="sm"
      icon={() => (
        <SvgIcon sx={{ fontSize: 18 }}>
          <UnitControlIcon strokeColor="white" />
        </SvgIcon>
      )}
      style={{ fontSize: 12 }}
    >
      {`${t('navigation.unitControl')}`}
    </ResponsiveButton>
  );
}
