import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Typography,
  CircularProgress,
  Grid,
} from '@mui/material';

import { PanelProps } from './Panel.types';

export default function Panel({
  children,
  title,
  titleVariant = 'h5',
  titleStyles,
  divider = false,
  size = false,
  containerStyles = {},
  loading = false,
  subHeader,
  ...rest
}: Partial<PanelProps>) {
  return (
    <Container maxWidth={size} sx={{ ...containerStyles }} disableGutters>
      <Card {...rest}>
        {title && (
          <CardHeader
            sx={{ paddingBottom: 0, ...titleStyles }}
            title={
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent={'space-between'}
              >
                <Grid item>
                  <Typography variant={titleVariant}>{title}</Typography>
                </Grid>
                <Grid item>
                  <div>
                    {subHeader}
                    {loading && <CircularProgress size={22} />}
                  </div>
                </Grid>
              </Grid>
            }
          />
        )}
        {divider && <Divider />}
        <CardContent>{children}</CardContent>
      </Card>
    </Container>
  );
}
