import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSidebarItems } from 'hooks/useSidebarItems';
import useAuth from 'hooks/useAuth';
import GenericSidebarHeader from '../../components/layout/Sidebar/GenericSidebarHeader';
import {
  UsersIcon,
  RoleIcon,
  UnitIcon,
  GreenerEyeIcon,
  WatcherTypeIcon,
  OrganisationIcon,
  DeviceModelIcon,
} from '../../components/icons';
import {
  canSeeGreenerEyesPages,
  canSeeGreenerUnitPages,
  canSeeWatcherTypePages,
} from 'utils/authorization';

export const useManagementSidebar = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { dispatchSidebar } = useSidebarItems();

  useEffect(() => {
    const sidebarItems = [
      {
        name: t('entities:user.namePlural'),
        path: '/users',
        icon: UsersIcon,
      },
      {
        name: t('entities:userRole.namePlural'),
        path: '/user-roles',
        icon: RoleIcon,
      },
      {
        name: t('entities:organisation.namePlural'),
        path: '/organisations',
        icon: OrganisationIcon,
      },
    ];

    if (canSeeGreenerUnitPages(user.role)) {
      sidebarItems.push({
        name: t('entities:greenerUnit.namePlural'),
        path: '/greener-units',
        icon: UnitIcon,
      });
    }
    if (canSeeGreenerEyesPages(user.role)) {
      sidebarItems.push({
        name: t('entities:greenerEye.namePlural'),
        path: '/greener-eyes',
        icon: GreenerEyeIcon,
      });
    }
    if (canSeeWatcherTypePages(user.role)) {
      sidebarItems.push({
        name: t('entities:watcherType.namePlural'),
        path: '/watcher-types',
        icon: WatcherTypeIcon,
      });
      sidebarItems.push({
        name: t('entities:deviceModel.namePlural'),
        path: '/device-models',
        icon: DeviceModelIcon,
      });
    }

    dispatchSidebar({
      type: 'REGULAR',
      payload: {
        items: sidebarItems,
        sidebarHeader: <GenericSidebarHeader title={t('entities:domain')} />,
      },
    });
  }, [user.role, dispatchSidebar, t]);
};
