import { PROJECT_DATE_STATUS } from './constants';
import { isBefore, subDays } from 'date-fns';

// Check if current time is between the two date times
export function isBetweenDates(tsStart, tsEnd) {
  const now = new Date();
  if (!tsEnd) return now > new Date(tsStart);

  return now > new Date(tsStart) && now < new Date(tsEnd);
}

export const getDateStatus = (tsStart, tsEnd) => {
  const now = new Date();

  if (new Date(tsStart) > now) {
    return 'future';
  } else if (tsEnd && new Date(tsEnd) < now) {
    return 'past';
  } else {
    return 'active';
  }
};

//get unit date range available from instances
export const getUnitDateAvailableRange = (
  instances,
  unitExternalIdentifier
) => {
  const unitInstance = instances.find(
    (instance) =>
      instance.greenerUnit.externalIdentifier === unitExternalIdentifier
  );

  if (!unitInstance) {
    throw new RangeError(
      `Unit with external identifier ${unitExternalIdentifier} not found`
    );
  }

  return {
    from: new Date(unitInstance.tsStart),
    to: new Date(unitInstance.tsEnd),
  };
};
/**
 *
 * @param {object} unitInstance
 * @param {number} maxRangeInDays - maximum range to be fetch in days
 * @returns {object}
 */

export const determineTimerangeToFetch = (unitInstance, maxRangeInDays) => {
  if (
    !unitInstance ||
    !unitInstance.tsStart ||
    isNaN(Date.parse(unitInstance.tsStart))
  ) {
    throw new Error('Invalid or missing start timestamp');
  }

  const startDate = new Date(unitInstance.tsStart);
  const dateRange = { from: startDate, to: null };

  if (unitInstance.tsEnd && isNaN(Date.parse(unitInstance.tsEnd))) {
    throw new Error('Invalid end timestamp');
  }

  const dateStatus = getDateStatus(unitInstance.tsStart, unitInstance.tsEnd);

  if (dateStatus === PROJECT_DATE_STATUS.future)
    return { from: null, to: null };

  if (dateStatus === PROJECT_DATE_STATUS.active) {
    dateRange.to = new Date();
  } else if (dateStatus === PROJECT_DATE_STATUS.past) {
    dateRange.to = new Date(unitInstance.tsEnd);
  }

  /* If a max range is specified, adjust it to dateRange.to */
  if (maxRangeInDays) {
    const newFromDate = subDays(dateRange.to, maxRangeInDays);

    dateRange.from = isBefore(newFromDate, startDate) ? startDate : newFromDate;
  }

  return dateRange;
};
