import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getQueryKeys } from 'services/queryKeys';
import { useAxios } from 'hooks/useAxios';
import { Node } from '../microgrid.types';

export function useRemoveNode(projectId: string, microgridId: string) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { del } = useAxios();
  const path = `/projects/${projectId}/microgrids/${microgridId}/`;

  const request = async (node: Node) => {
    await del(path + `nodes/${node.id}/`);
    return node.id;
  };

  const {
    mutateAsync: removeNode,
    status,
    error,
    data,
    isLoading,
    isError,
    ...others
  } = useMutation((node: Node) => request(node), {
    onSuccess: (_, node) => {
      // ✅ remove the cache
      const queryKeys = getQueryKeys(path + `nodes/${node.id}/`);
      queryClient.removeQueries(queryKeys.detail);
    },
    onError: (error, node) => {
      enqueueSnackbar(
        t('genericErrors.create', {
          entity: t('entities:microgrid.nameSingular').toLowerCase(),
          identifier: node.label,
        }) + `: ${error}`,
        { variant: 'error' }
      );
    },
  });

  return {
    removeNode,
    status,
    error,
    data,
    isLoading,
    isError,
    ...others,
  };
}
