import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAxios } from 'hooks/useAxios';
import { mutateNodeMapper, getNodeMapper } from '../microgrid.utils';
import { Node } from '../microgrid.types';
import { keysToCamelCase } from 'utils/formatters';

export function useCreateNode(projectId: string, microgridId: string) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { post } = useAxios();
  const path = `/projects/${projectId}/microgrids/${microgridId}/nodes/`;

  const fetchData = async (node: Node) => {
    const { data } = await post(path, mutateNodeMapper(node));
    return keysToCamelCase(getNodeMapper(data));
  };

  const {
    mutateAsync: createNode,
    status,
    error,
    data,
    isLoading,
    isError,
    ...others
  } = useMutation((node: Node) => fetchData(node), {
    onError: (error, vars) => {
      enqueueSnackbar(
        t('genericErrors.create', {
          entity: t('entities:microgrid.nameSingular').toLowerCase(),
          identifier: vars.label,
        }) + `: ${error}`,
        { variant: 'error' }
      );
    },
  });

  return {
    createNode,
    status,
    error,
    data,
    isLoading,
    isError,
    ...others,
  };
}
