import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DEVICE_TYPES, DEVICE_TYPE_IDS } from 'utils/constants';

export function GenericUnitSelector({
  units,
  handleChange,
  selectedUnitExtId,
  unitType,
}) {
  const { t } = useTranslation();

  // No units to display
  if (!units || units.length === 0) {
    return null;
  }

  const getLabel = (unitType) => {
    switch (unitType) {
      case DEVICE_TYPES.BATTERY:
        return t('components:unitSelector.battery');
      case DEVICE_TYPES.ENERGY_METER:
        return t('components:unitSelector.energyMeter');
      default:
        t('entities:greenerUnit.nameSingular');
    }
  };
  const fieldLabel = getLabel(unitType);
  const deviceTypeId =
    unitType === DEVICE_TYPES.BATTERY
      ? DEVICE_TYPE_IDS.BATTERY
      : DEVICE_TYPE_IDS.ENERGY_METER;

  const filteredUnits = unitType
    ? units.filter((unit) =>
      [
        unit.watcherType.deviceTypeId,
        unit.watcherType.deviceType?.deviceTypeId,
      ].includes(deviceTypeId)
    )
    : units;

  const selectedUnit =
    filteredUnits.find(
      (unit) => unit && unit.externalIdentifier === selectedUnitExtId
    ) || {};

  const onChangeUnit = (unit) => {
    unit && unit.externalIdentifier && handleChange(unit);
  };

  function getOptionLabel(option) {
    if (!!option && option.externalIdentifier && option.name) {
      return `${option.externalIdentifier}: ${option.name}`;
    }
    return '';
  }

  return (
    <Autocomplete
      id={`autocomplete-unit-selector`}
      data-testid="autocomplete-unit-selector"
      value={selectedUnit}
      options={filteredUnits}
      getOptionLabel={getOptionLabel}
      onChange={(_, value) => {
        onChangeUnit(value);
      }}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          name={'unit-selector'}
          label={fieldLabel}
          variant="outlined"
          fullWidth={true}
          InputLabelProps={{ shrink: true }}
          size="small"
        />
      )}
    />
  );
}
