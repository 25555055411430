import React from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useAssetsSidebar } from './Assets.sidebar';
import { SocketProvider } from 'contexts/SocketContext';
import { AssetLayout } from './[Asset]/[Asset].layout.tsx';
import './translations';
import { useAxiosQuery } from 'hooks/useAxiosQuery';

// const responsiveColumnVisibility = {
//   xs: ['status', 'externalIdentifier'],
//   sm: ['externalIdentifier'],
// };

const AssetsLayout = () => {
  const navigate = useNavigate();
  useAssetsSidebar();

  function onRowClick(params) {
    return navigate(`assets/${params.row.externalIdentifier}`);
  }

  function onMarkerContentClick(externalIdentifier) {
    return navigate(`assets/${externalIdentifier}`);
  }

  const { data: units } = useAxiosQuery(
    ['assets', 'greener-units'],
    '/greener-units/'
  );

  return (
    <>
      <Helmet title="Assets" />
      <SocketProvider>
        <Outlet context={{ units, onMarkerContentClick, onRowClick }} />
      </SocketProvider>
    </>
  );
};

export const assetsRoutes = [
  {
    element: <AssetsLayout />,
    children: [
      {
        path: 'assets',
        lazy: () => import('pages/Assets'),
      },
      {
        path: 'asset-location',
        lazy: () => import('pages/Assets/AssetLocation'),
      },
      {
        path: 'assets/:unitExternalIdentifier',
        element: <AssetLayout />,
        children: [
          {
            index: true,
            lazy: () => import('pages/Assets/[Asset]'),
          },
          {
            path: 'basic-graphs',
            lazy: () => import('pages/Assets/[Asset]/BasicGraphs'),
          },
          {
            path: 'advanced-graphs',
            lazy: () => import('pages/Assets/[Asset]/AdvancedGraphs'),
          },
        ],
      },
    ],
  },
];
