import _ from 'lodash';
import { keysToSnakeCase } from 'utils/formatters';
import greenerBatteryIcon from 'components/icons/assetTypes/greener-battery.svg';
import dieselGeneratorIcon from 'components/icons/assetTypes/diesel-generator.svg';
import electricityGridIcon from 'components/icons/assetTypes/electricity-grid.svg';
import energyMeterIcon from 'components/icons/assetTypes/energy-meter.svg';
import constructionIcon from 'components/icons/endUseTypes/construction.svg';
import distributionBoxIcon from 'components/icons/assetTypes/distribution-box.svg';
import hydrogenGeneratorIcon from 'components/icons/assetTypes/hydrogen-generator.svg';
import solarPanelIcon from 'components/icons/assetTypes/solar-panel.svg';
import electricalSubstationIcon from 'components/icons/assetTypes/electrical-substation.svg';
import windTurbineIcon from 'components/icons/assetTypes/wind-turbine.svg';
import { ASSET_TYPES } from 'utils/constants';
import {
  GRID_SIZE_MAP,
  NODE_META_PROPS,
  ANONYMOUS_NODES,
  ANONYMOUS_NODE,
} from './microgrid.constants';
import {
  Microgrid,
  Connection,
  Edge,
  Node,
  ErrorObject,
  NodeRead,
  EdgeRead,
  GeneratorData,
  DistributionBoxData,
  EnergyMeterData,
  LoadData,
  GridConnectionData,
  BatteryData,
  NodeTypes,
  AnonymousData,
  UpdateNodeData,
} from './microgrid.types';

//checking for the case where timestamp is empty object
const createDateFromStamp = (timestamp?: Date) => {
  if (!timestamp) {
    return undefined;
  }
  if (timestamp instanceof Date) {
    return timestamp;
  }

  return new Date(timestamp);
};

/** Get mappers
 * used to map the data from the API to the data we want to use in the app
 */

export const getMicrogridMapper = (microgrid: Microgrid) => {
  const { nodes, edges, tsStart, tsEnd, ...rest } = microgrid;

  return {
    nodes: nodes?.map((node: NodeRead) => getNodeMapper(node)) || [],
    edges: edges?.map((edge: EdgeRead) => getEdgeMapper(edge)) || [],
    tsStart: createDateFromStamp(tsStart),
    tsEnd: createDateFromStamp(tsEnd),
    ...rest,
  } as Microgrid;
};
export const getMicrogridsMapper = (microgrids: Microgrid[] | undefined) => {
  return microgrids?.map((item) => getMicrogridMapper(item));
};

export const getNodeMapper = (node: NodeRead) => {
  const {
    id,
    type,
    data,
    meta: { icon, actualType, ...meta } = { icon: undefined },
  } = node;

  const finalType = type === ANONYMOUS_NODE ? actualType : type;

  const mappedData = {
    ...data,
    icon,
    connections: data?.connections.map((connection: Connection) => {
      return connection;
    }),
  };

  return {
    id,
    type: finalType,
    data: mappedData,
    ...meta,
  };
};

export const getEdgeMapper = (edge: EdgeRead) => {
  const {
    id,
    sourceId,
    sourceConnectionId,
    targetId,
    targetConnectionId,
    meta,
    label,
  } = edge;

  return {
    id,
    source: sourceId,
    sourceHandle: sourceConnectionId,
    target: targetId,
    targetHandle: targetConnectionId,
    label,
    ...meta,
  };
};

/** Mutation mappers
 * used to map the microgrid info before sending it to the backend
 */
export const mutateMicrogridInfoMapper = (microgrid: Partial<Microgrid>) => {
  const values = _.pick(microgrid, [
    'name',
    'description',
    'label',
    'tsStart',
    'tsEnd',
    'status',
    'visibleToClients',
  ]);
  return keysToSnakeCase(values);
};

// used to map an entire microgrid object including the setup
export const mutateMicrogridMapper = ({
  nodes,
  edges,
  ...microgrid
}: Microgrid) => ({
  ...keysToSnakeCase(microgrid),
  ...(nodes && {
    //@ts-expect-error Not sure why nodes is destructured from microgrid and then accessed via microgrid
    nodes: microgrid.nodes?.map((node: Node) => mutateNodeMapper(node)),
  }),
  ...(edges && {
    //@ts-expect-error Not sure why nodes is destructured from microgrid and then accessed via microgrid
    edges: microgrid.edges?.map((edge: Edge) => mutateEdgeMapper(edge)),
  }),
});

export const mutateMicrogridsMapper = (microgrid: Microgrid[]) => {
  return microgrid.map((item) => -mutateMicrogridMapper(item));
};

export const mutateNodeMapper = (node: Node) => {
  const {
    type,
    data: { icon, ...data },
    id,
    isNew = false,
    ...meta
  } = node;

  const isAnonymous = ANONYMOUS_NODES.includes(type);

  const typeOrAnonymous = isAnonymous ? ANONYMOUS_NODE : type;

  const mappedData = {
    ...keysToSnakeCase(data),
    connections: data.connections.map((connection) =>
      keysToSnakeCase(connection)
    ),
  };

  //filter the methadata by the keys we want to send to the backend
  const mappedMetaData = keysToSnakeCase(_.pick(meta, NODE_META_PROPS));

  const baseNode = {
    type: typeOrAnonymous,
    meta: {
      ...mappedMetaData,
      icon,
      actualType: isAnonymous ? type : undefined,
    },
  };

  if (isNew) {
    return {
      data: {
        ...mappedData,
        external_id: id, // we set the reactFlowId as the externalId for new nodes
      },
      ...baseNode,
    };
  } else {
    return {
      id,
      data: mappedData,
      ...baseNode,
    };
  }
};

export const mutateEdgeMapper = (edge: Edge) => {
  const {
    id,
    source,
    target,
    sourceHandle,
    targetHandle,
    label,
    isNew = false,
    ...meta
  } = edge;

  return {
    ...((isNew && {}) || { id }), // if isNew is true, then we don't want to send the id
    source_id: source,
    source_connection_id: sourceHandle,
    target_id: target,
    target_connection_id: targetHandle,
    meta,
    label,
  };
};

/**
 * Adjust grid size based on connections
 */

export const getGridSize = (nodes: Node[] = []) => {
  const maxConnections = Math.max(
    ...nodes.map((node) => node.data.connections?.length || 0)
  );
  if (maxConnections > 8) {
    return GRID_SIZE_MAP['l'];
  } else if (maxConnections < 4) {
    return GRID_SIZE_MAP['s'];
  } else {
    return GRID_SIZE_MAP['m'];
  }
};

/**
 * Get node icon
 */

export function getNodeIcon(nodeType: string) {
  let Icon;
  switch (nodeType.toLowerCase()) {
    case ASSET_TYPES.BATTERY: {
      Icon = greenerBatteryIcon;
      break;
    }
    case ASSET_TYPES.GENERATOR: {
      Icon = dieselGeneratorIcon;
      break;
    }
    case ASSET_TYPES.GRID: {
      Icon = electricityGridIcon;
      break;
    }
    case ASSET_TYPES.ENERGY_METER: {
      Icon = energyMeterIcon;
      break;
    }
    case ASSET_TYPES.DISTRIBUTION_BOX: {
      Icon = distributionBoxIcon;
      break;
    }
    case ASSET_TYPES.LOAD: {
      Icon = constructionIcon;
      break;
    }

    case ASSET_TYPES.SOLAR_PANEL: {
      Icon = solarPanelIcon;
      break;
    }

    case ASSET_TYPES.ELECTRICAL_SUBSTATION: {
      Icon = electricalSubstationIcon;
      break;
    }

    case ASSET_TYPES.HYDROGEN_GENERATOR: {
      Icon = hydrogenGeneratorIcon;
      break;
    }

    case ASSET_TYPES.WIND_TURBINE: {
      Icon = windTurbineIcon;
      break;
    }

    //add more end use types here
    default: {
      break;
    }
  }
  return Icon;
}

export const generateErrorMessages = (
  errorObject: ErrorObject,
  t: (string: string, object?: object) => string
) => {
  const errors = [];

  const {
    microgrid_compatibility_errors = {},
    microgrid_edge_errors = {},
    microgrid_node_errors = {},
    project_microgrid_compatibility_errors = {},
    project_microgrid_errors = {},
    converter_errors = [],
  } = errorObject ?? {};

  const {
    greener_unit_not_registered = [],
    greener_unit_incompatible_start_date = [],
    greener_unit_incompatible_end_date = [],
  } = microgrid_compatibility_errors;

  if (greener_unit_not_registered.length)
    greener_unit_not_registered.forEach((unit) =>
      errors.push(t('entities:microgrid.errors.notBooked', { unit }))
    );
  if (greener_unit_incompatible_start_date.length)
    greener_unit_incompatible_start_date.forEach((unit) =>
      errors.push(
        t('entities:microgrid.errors.incompatibleUnitStartDate', { unit })
      )
    );
  if (greener_unit_incompatible_end_date.length)
    greener_unit_incompatible_end_date.forEach((unit) =>
      errors.push(
        t('entities:microgrid.errors.incompatibleUnitEndDate', { unit })
      )
    );

  const {
    connections_with_multiple_edges = [],
    edge_id_not_unique = [],
    edges_with_invalid_connections = [],
    edges_with_invalid_nodes = [],
  } = microgrid_edge_errors;

  if (connections_with_multiple_edges.length)
    connections_with_multiple_edges.forEach((connection) =>
      errors.push(t('entities:microgrid.errors.multipleEdges', { connection }))
    );
  if (edge_id_not_unique.length)
    errors.push(t('entities:microgrid.errors.edgeNotUnique'));
  if (edges_with_invalid_connections.length)
    errors.push(t('entities:microgrid.errors.edgeHasInvalidConnection'));
  if (edges_with_invalid_nodes.length)
    edges_with_invalid_nodes.forEach((edge) =>
      errors.push(t('entities:microgrid.errors.edgeHasInvalidNodes', { edge }))
    );

  const {
    microgrid_genset_node_misses_rated_apparent_power = [],
    microgrid_greener_unit_ext_id_not_unique = [],
    microgrid_grid_node_misses_rated_current = [],
    microgrid_has_no_nodes = false,
    microgrid_has_anonymous_nodes = [],
    microgrid_node_misses_greener_unit_ext_id = [],
    microgrid_nodes_not_unique = [],
    microgrid_nodes_without_edges = [],
    microgrid_genset_node_misses_relay_id = [],
    microgrid_genset_node_misses_controlled_by_greener_unit_ext_id = [],
    microgrid_genset_node_controlled_by_greener_unit_not_in_microgrid = [],
    microgrid_genset_node_misses_active_power_setting = [],
  } = microgrid_node_errors;

  if (microgrid_genset_node_misses_rated_apparent_power.length)
    errors.push(t('entities:microgrid.errors.gensetRatedApparentPower'));
  if (microgrid_greener_unit_ext_id_not_unique.length)
    microgrid_greener_unit_ext_id_not_unique.forEach((unit) =>
      errors.push(t('entities:microgrid.errors.unitNotUnique', { unit }))
    );
  if (microgrid_grid_node_misses_rated_current.length)
    errors.push(t('entities:microgrid.errors.gridMissingRatedCurrent'));
  if (microgrid_has_no_nodes)
    errors.push(t('entities:microgrid.errors.noNodes'));
  if (microgrid_has_anonymous_nodes.length)
    microgrid_has_anonymous_nodes.forEach((node) =>
      errors.push(t('entities:microgrid.errors.anonymousNode', { node }))
    );
  if (microgrid_node_misses_greener_unit_ext_id.length)
    errors.push(t('entities:microgrid.errors.nodeMissingExtId'));
  if (microgrid_nodes_not_unique.length)
    errors.push(t('entities:microgrid.errors.nodeNotUnique'));
  if (microgrid_nodes_without_edges.length)
    errors.push(t('entities:microgrid.errors.nodeHasNoEdges'));
  if (microgrid_genset_node_misses_relay_id.length)
    errors.push(t('entities:microgrid.errors.gensetRelayId'));
  if (microgrid_genset_node_misses_controlled_by_greener_unit_ext_id.length)
    errors.push(t('entities:microgrid.errors.gensetControlledBy'));
  if (microgrid_genset_node_misses_active_power_setting.length)
    errors.push(t('entities:microgrid.errors.gensetActivePower'));
  if (microgrid_genset_node_controlled_by_greener_unit_not_in_microgrid.length)
    errors.push(t('entities:microgrid.errors.gensetControlledByWrongUnit'));

  const {
    incompatible_project_start_date = false,
    incompatible_project_end_date = false,
    overlapping_valid_microgrids = [],
  } = project_microgrid_compatibility_errors;

  if (incompatible_project_start_date)
    errors.push(t('entities:microgrid.errors.incompatibleProjectStartDate'));
  if (incompatible_project_end_date)
    errors.push(t('entities:microgrid.errors.incompatibleProjectEndDate'));
  if (overlapping_valid_microgrids.length)
    overlapping_valid_microgrids.forEach((microgrid) =>
      errors.push(
        t('entities:microgrid.errors.overlappingMicrogridDates', { microgrid })
      )
    );

  const { project_microgrid_invalid_dates = false } = project_microgrid_errors;

  if (project_microgrid_invalid_dates)
    errors.push(t('entities:microgrid.errors.invalidDates'));

  if (converter_errors.length) {
    errors.push(t('entities:microgrid.errors.converterError'));
  }

  return errors;
};

const microgridErrorMessages = {
  incompatible_project_start_date:
    'projects:content.overview.overviewForms.projectStartDateIncompatibleWithProjectMicrogrid',
  incompatible_project_end_date:
    'projects:content.overview.overviewForms.projectEndDateIncompatibleWithProjectMicrogrid',
  greener_unit_incompatible_start_date:
    'projects:content.overview.overviewForms.pguiStartDateIncompatibleWithProjectMicrogrid',
  greener_unit_incompatible_end_date:
    'projects:content.overview.overviewForms.pguiEndDateIncompatibleWithProjectMicrogrid',
};

export const parseMicrogridError = (errorObject) => {
  if (!errorObject || !errorObject.detail) {
    return null;
  }

  const errors = [];
  const { detail } = errorObject;

  const errorConfig = [
    {
      category: detail.project_microgrid_compatibility_errors,
      key: 'incompatible_project_start_date',
      source: 'project',
      target: 'microgrid',
    },
    {
      category: detail.project_microgrid_compatibility_errors,
      key: 'incompatible_project_end_date',
      source: 'project',
      target: 'microgrid',
    },
    {
      category: detail.microgrid_compatibility_errors,
      key: 'greener_unit_incompatible_start_date',
      source: 'greenerUnit',
      target: 'microgrid',
    },
    {
      category: detail.microgrid_compatibility_errors,
      key: 'greener_unit_incompatible_end_date',
      source: 'greenerUnit',
      target: 'microgrid',
    },
  ];

  const processErrorField = ({ category, key, source, target }) => {
    if (category && category[key]?.length) {
      errors.push({
        field: key.includes('start_date') ? 'tsStart' : 'tsEnd',
        errorMessage: microgridErrorMessages[key],
        ids: category[key],
        source,
        target,
      });
    }
  };

  errorConfig.forEach((config) => processErrorField(config));

  return errors;
};

/**
 * Checks how the number of handles is modified and removes or adds the necessary number of connections
 **/
export const updateNumberOfConnections = (
  node: Node,
  newValues: Partial<UpdateNodeData>
) => {
  const connections = node.data.connections;

  // When the new number of connections is less than the current one, remove the ones at the end of the array
  if (newValues.connectionsNumber < connections.length) {
    const connectionsToDelete =
      connections.length - newValues.connectionsNumber;

    connections.splice(
      connections.length - connectionsToDelete,
      connectionsToDelete
    );

    return connections;
  }

  if (newValues.connectionsNumber > connections.length) {
    const connectionsToAdd = newValues.connectionsNumber - connections.length;

    return [
      ...connections,
      ...Array.from(
        // Create a new array with the length of the connections to be added, and then fill the array with the values
        Array(connectionsToAdd)
      ).map((_, i) => ({
        id: (connections.length + i).toString(),
        ratedCurrent: newValues.ratedCurrent,
      })),
    ];
  }

  return connections;
};

/**
 * When changing the number of handles that a node accepts, the existing edges need to be checked in order to know if
 * they are connected to a handle that doesn't longer exists. This function checks that and returns the edges that should
 * be removed
 **/
export const getNodeEdgesWithoutHandles = (node: Node, edges: Edge[]) => {
  const connectionsIds = node.data.connections.map(
    (connection) => connection.id
  );
  const edgesToRemove = edges
    // Check for edges that have the modified node as either the source or target
    .filter((edge) => edge.source === node.id || edge.target === node.id)
    // Check if the targetHandle or sourceHandle do not exist in the modified node
    .filter(
      (edge) =>
        !connectionsIds.includes(edge.sourceHandle) &&
        !connectionsIds.includes(edge.targetHandle)
    );

  return edgesToRemove;
};

//Node type guards
export const isBatteryNode = (
  node: Node
): node is Node & { data: BatteryData } => {
  return node.type === NodeTypes.BATTERY;
};

export const isAnonymousNode = (
  node: Node
): node is Node & { data: AnonymousData } => {
  return ANONYMOUS_NODES.includes(node.type);
};

export const isGridNode = (
  node: Node
): node is Node & { data: GridConnectionData } => {
  return node.type === NodeTypes.GRID;
};

export const isGeneratorNode = (
  node: Node
): node is Node & { data: GeneratorData } => {
  return node.type === NodeTypes.DIESEL_GENSET;
};

export const isLoadNode = (node: Node): node is Node & { data: LoadData } => {
  return node.type === NodeTypes.LOAD;
};

export const isDistributionBoxNode = (
  node: Node
): node is Node & { data: DistributionBoxData } => {
  return node.type === NodeTypes.DISTRIBUTION_BOX;
};

export const isEnergyMeterNode = (
  node: Node
): node is Node & { data: EnergyMeterData } => {
  return node.type === NodeTypes.ENERGY_METER;
};
