import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  add,
  fromUnixTime,
  getUnixTime,
  isAfter,
  max,
  sub,
  isBefore,
  isSameDay,
} from 'date-fns';
import { Range } from 'types';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useUrlState } from 'hooks/useUrlState';

const determineValidTimerange = (now: Date, initialRange?: Range): Range => {
  const defaultRangeInDays = 7;

  // Calculate a consistent 'to' date based on the logic for each case
  let toDate = now;

  // Calculate a consistent 'from' date which is always defaultRangeInDays days before 'to' date
  let fromDate = sub(toDate, { days: defaultRangeInDays });

  if (initialRange) {
    if (initialRange.from && !initialRange.to) {
      if (isAfter(now, initialRange.from)) {
        fromDate = max([fromDate, initialRange.from]);
      } else {
        fromDate = new Date(initialRange.from);
        toDate = add(fromDate, { days: defaultRangeInDays });
      }
    }

    if (initialRange.from && initialRange.to) {
      if (isAfter(now, initialRange.to) || isSameDay(now, initialRange.to)) {
        toDate = new Date(initialRange.to);
        fromDate = sub(toDate, { days: defaultRangeInDays });
      } else if (isBefore(now, initialRange.from)) {
        fromDate = new Date(initialRange.from);
        toDate = add(fromDate, { days: defaultRangeInDays });
      } else {
        fromDate = sub(toDate, { days: defaultRangeInDays });
      }
    }
  }

  // Ensure the 'from' is never before the initial 'from' if provided
  if (initialRange && initialRange.from) {
    fromDate = max([fromDate, initialRange.from]);
  }

  // Always return the range with 'to' set to 7 days after 'from'
  return { from: fromDate, to: toDate };
};

export const useTimerange = (initialRange?: Range) => {
  const [now, setNow] = useState(new Date());
  const { projectSlug } = useParams();
  const { setUrlState, clearUrlState, getUrlState } = useUrlState();

  const [storedProjectSlug, setStoredProjectSlug] = useLocalStorage<
    string | null
  >('project_slug', projectSlug);

  const validTimerange = determineValidTimerange(now, initialRange);

  const [storedRange, setStoredRange] = useLocalStorage<Range>(
    `range-${projectSlug}`,
    validTimerange
  );

  // Convert stored range to use Date instances
  const range = {
    from: new Date(storedRange.from),
    to: new Date(storedRange.to),
  };

  useEffect(() => {
    const urlParams = getUrlState();
    const tsFrom = parseInt(urlParams.from);
    const tsTo = parseInt(urlParams.to);

    if (tsFrom && tsTo) {
      const from = fromUnixTime(tsFrom);
      const to = fromUnixTime(tsTo);
      setStoredRange({ from, to });
    }
  }, [getUrlState]);

  const resetRange = useCallback(() => {
    clearUrlState(['from', 'to']);
    setStoredRange(determineValidTimerange(now, validTimerange));
  }, [clearUrlState, validTimerange, now, setStoredRange]);

  const applyRange = useCallback(
    ({ from, to }: Range) => {
      if (!from || !to) {
        console.error(`Invalid date range: from=${from}, to=${to}.`);
        resetRange();
        return;
      }

      setUrlState({
        from: getUnixTime(from).toString(),
        to: getUnixTime(to).toString(),
      });
      setStoredRange({ from, to });
    },
    [setUrlState, setStoredRange]
  );

  useEffect(() => {
    if (storedProjectSlug !== projectSlug) {
      setStoredProjectSlug(projectSlug);
      resetRange();
    }
  }, [projectSlug, storedProjectSlug, setStoredProjectSlug, resetRange]);

  return {
    range,
    applyRange,
    resetRange,
    now,
    setNow,
  };
};
