export function GreenerLogo(props) {
  return (
    <img
      height="30"
      width="150"
      alt="Logo"
      src="/static/Logo_Greener_DIAP.png"
      {...props}
    />
  );
}
