import { useEffect, useState } from 'react';
import { Button, Alert, Typography, Snackbar, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const PWAWrapper = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    const updateOnlineStatus = () => setOnlineStatus(navigator.onLine);

    const storeInstallPrompt = (event) => {
      event.preventDefault();
      window.deferredPrompt = event;
      setShowInstallButton(true);
    };

    window.addEventListener('beforeinstallprompt', storeInstallPrompt);
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('beforeinstallprompt', storeInstallPrompt);
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  const handleClick = async () => {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      setShowInstallButton(false); // Hide the button if the prompt isn't available
      return;
    }
    promptEvent.prompt();
    const result = await promptEvent.userChoice;
    window.deferredPrompt = null;
    setShowInstallButton(false); // Hide the install button after prompt

    const installMessage =
      result.outcome === 'accepted'
        ? t('main:appInstallation.success')
        : t('main:appInstallation.rejected');

    enqueueSnackbar(installMessage, {
      variant: 'info',
    });
  };

  return (
    <>
      {!onlineStatus && (
        <Alert
          severity="warning"
          sx={{
            zIndex: 9999,
            position: 'fixed',
            width: '100%',
            top: '92dvh',
          }}
        >
          <Typography variant="body1">{t('main:noInternet')}</Typography>
        </Alert>
      )}
      {showInstallButton && location.pathname.includes('login') && (
        <Snackbar open>
          <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
            <Stack direction="row" p={1}>
              <Typography variant="body1">
                {t('main:appInstallation.available')}
              </Typography>
              <Button variant="text" onClick={handleClick}>
                {t('main:appInstallation.install')}
              </Button>
            </Stack>
          </Alert>
        </Snackbar>
      )}
      {children}
    </>
  );
};
