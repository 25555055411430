import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useUrlState = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const setUrlState = useCallback(
    (newState = {}, replace = false) => {
      const searchParams = new URLSearchParams(location.search);

      // Check if newState is an empty object and clear all search parameters if true
      if (
        Object.keys(newState).length === 0 &&
        newState.constructor === Object
      ) {
        const allKeys = Array.from(searchParams.keys());
        allKeys.forEach((key) => searchParams.delete(key));
      } else {
        Object.entries(newState).forEach(([key, value]) => {
          if (value === null || value === undefined) {
            searchParams.delete(key);
          } else {
            searchParams.set(key, value.toString());
          }
        });
      }

      const newSearch = searchParams.toString();
      if (replace) {
        navigate(`${location.pathname}${newSearch ? `?${newSearch}` : ''}`, {
          replace: true,
        });
      } else {
        navigate(`${location.pathname}${newSearch ? `?${newSearch}` : ''}`);
      }
    },
    [location.search, navigate, location.pathname]
  );

  const clearUrlState = useCallback(
    (keys: string[] = []) => {
      const searchParams = new URLSearchParams(location.search);
      if (keys.length === 0) {
        Array.from(searchParams.keys()).forEach((key) =>
          searchParams.delete(key)
        );
      } else {
        keys.forEach((key) => searchParams.delete(key));
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    [navigate, location.pathname, location.search]
  );

  const getUrlState = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const params: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }, [location.search]);

  return { setUrlState, clearUrlState, getUrlState };
};
