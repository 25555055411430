import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAxios } from 'hooks/useAxios';
import { getQueryKeys } from 'services/queryKeys';
import { ErrorDetail, Microgrid } from '../microgrid.types';

export function useRemoveMicrogrid(projectId?: number) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { del } = useAxios();
  const path = `/projects/${projectId}/microgrids/`;

  const {
    mutateAsync: removeMicrogrid,
    status,
    error,
    data,
    isLoading,
    isError,
    ...others
  } = useMutation(
    (microgrid: Microgrid) => del(path + `${microgrid.microgridId}/`),
    {
      onSuccess: (_, microgrid) => {
        // ✅ remove the cache
        const queryKeys = getQueryKeys(path);
        const id = microgrid.microgridId;
        queryClient.removeQueries(
          queryKeys.mainKey.concat(['microgrids', id, 'detail'])
        );
        // remove the microgrid item in the list cache if it is present
        queryClient.setQueryData(
          queryKeys.list(),
          (oldData: Microgrid[] | undefined) => {
            if (oldData instanceof Array) {
              return oldData.filter((item) => item.microgridId !== id);
            }
            // Optionally return a default value if oldData is undefined
            return [];
          }
        );
        enqueueSnackbar(
          t('genericSuccesses.delete', {
            entity: t('entities:microgrid.nameSingular').toLowerCase(),
            identifier: microgrid.name,
          }),
          { variant: 'success' }
        );
      },
      onError: (error: ErrorDetail, microgrid) => {
        enqueueSnackbar(
          t('genericErrors.delete', {
            entity: t('entities:microgrid.nameSingular').toLowerCase(),
            identifier: microgrid.label,
          }) + `: ${error.response.data.detail}`,
          { variant: 'error' }
        );
      },
    }
  );

  return {
    removeMicrogrid,
    status,
    error,
    data,
    isLoading,
    isError,
    ...others,
  };
}
