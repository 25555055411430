import { ProjectLayout } from './[Project]/[Project].layout';
import { ProjectMonitoringLayout } from './[Project]/Monitoring/Monitoring.layout';

export const projectsRoutes = [
  {
    path: 'projects/*',
    children: [
      {
        index: true,
        lazy: () => import('pages/Projects'),
      },
      {
        path: 'overview',
        lazy: () => import('pages/Projects/Overview'),
      },
      {
        path: 'new',
        lazy: () => import('pages/Projects/New'),
      },
      {
        path: ':projectSlug/*',
        element: <ProjectLayout />,
        children: [
          {
            index: true,
            lazy: () => import('pages/Projects/[Project]'),
          },
          {
            path: 'administration',
            lazy: () => import('pages/Projects/[Project]/Administration'),
          },
          {
            path: 'setups',
            lazy: () => import('pages/Projects/[Project]/Setups'),
          },
          {
            path: 'analytics/*',
            children: [
              {
                path: 'reports',
                lazy: () =>
                  import('pages/Projects/[Project]/Analytics/Reports'),
              },
              {
                path: 'reports/new-report',
                lazy: () =>
                  import(
                    'pages/Projects/[Project]/Analytics/Reports/NewReport'
                  ),
              },
              {
                path: 'reports/new-report/:microgridId',
                lazy: () =>
                  import(
                    'pages/Projects/[Project]/Analytics/Reports/NewReport/[Microgrid]'
                  ),
              },
              {
                path: 'summary',
                lazy: () =>
                  import('pages/Projects/[Project]/Analytics/Summary'),
              },
              {
                path: 'assets',
                lazy: () => import('pages/Projects/[Project]/Analytics/Assets'),
              },
            ],
          },
          {
            path: 'monitoring',
            element: <ProjectMonitoringLayout />,
            children: [
              {
                path: ':unitExternalIdentifier/asset',
                lazy: () => import('pages/Assets/[Asset]'),
              },
              {
                path: ':unitExternalIdentifier/basic-graphs',
                lazy: () => import('pages/Assets/[Asset]/BasicGraphs'),
              },
              {
                path: ':unitExternalIdentifier/advanced-graphs',
                lazy: () => import('pages/Assets/[Asset]/AdvancedGraphs'),
              },
            ],
          },
        ],
      },
    ],
  },
];
