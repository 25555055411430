import { useAxiosQuery } from 'hooks/useAxiosQuery';
import { getQueryKeys } from 'services/queryKeys';
import { getMicrogridsMapper } from '../microgrid.utils';
import { Microgrid } from '../microgrid.types';

export function useGetMicrogrids({
  projectId,
  ...config
}: {
  projectId?: number;
  enabled?: boolean;
}) {
  const path = `/projects/${projectId}/microgrids/`;
  const queryKeys = getQueryKeys(path);
  const { data, ...other } = useAxiosQuery<Microgrid[]>(
    queryKeys.list(),
    path,
    {
      method: 'GET',
      ...config,
    }
  );

  const microgrids = getMicrogridsMapper(data) || [];

  return {
    microgrids,
    ...other,
  };
}
