import { useQueryClient } from 'react-query';
import { OptionsObject, useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getQueryKeys } from 'services/queryKeys';
import {
  getMicrogridMapper,
  mutateMicrogridInfoMapper,
} from '../microgrid.utils';
import { Microgrid, MicrogridCreate } from '../microgrid.types';
import { ApiError } from 'types';
import useAxiosMutation from 'hooks/useAxiosMutation';
import { UseMutationConfig } from 'hooks/hooks.types';

export function useCreateMicrogrid(
  successFn: (data: Microgrid) => void,
  projectId?: number
) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const path = `/projects/${projectId}/microgrids/`;
  const queryKeys = getQueryKeys(path);

  const onSuccess = ({ data: microgrid }: { data: Microgrid }) => {
    const data = getMicrogridMapper(microgrid) as Microgrid;

    queryClient.setQueryData(queryKeys.list(), (oldData?: Microgrid[]) => {
      return oldData ? [...oldData, data] : [data];
    });

    enqueueSnackbar(
      t('genericSuccesses.create', {
        entity: t('entities:microgrid.nameSingular').toLowerCase(),
        identifier: data.name,
      }),
      { variant: 'success' } as OptionsObject
    );

    !!successFn && successFn(data);
  };

  const onError = (error: ApiError, vars: UseMutationConfig) => {
    const { body } = vars;

    enqueueSnackbar(
      `${t('genericErrors.create', {
        entity: t('entities:microgrid.nameSingular').toLowerCase(),
        identifier: (body as Microgrid).name,
      })}: ${error.message}`,
      { variant: 'error' } as OptionsObject
    );
  };

  const {
    mutateAsync: createMicrogrid,
    data,
    isError,
    error,
    isLoading,
    ...others
  } = useAxiosMutation<Microgrid, ApiError>({
    onSuccess,
    onError,
  });

  const handleCreateMicrogrid = async (microgrid: MicrogridCreate) => {
    return await createMicrogrid({
      path: path,
      method: 'POST',
      body: mutateMicrogridInfoMapper(microgrid),
    });
  };

  return {
    createMicrogrid: handleCreateMicrogrid,
    data,
    isError,
    error,
    isLoading,
    ...others,
  };
}
