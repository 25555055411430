import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  Reducer,
} from 'react';
import { SplashScreen } from 'components/fallback/SplashScreen';
import {
  Auth0Client,
  User as CAuth0User,
  CacheLocation,
} from '@auth0/auth0-spa-js';
import { graphQLClient } from '../hooks/useGQLQuery';
import { GET_ME } from '../services/gql/users';
import { useTranslation } from 'react-i18next';
import { useSnackbar, OptionsObject, SnackbarKey } from 'notistack';
import * as Sentry from '@sentry/react';
import { GreenerUser, GreenerProjectsUser } from 'types';
import { GetMeResponse } from './contexts.types';
import { useAmplitude } from 'hooks/useAmplitude';

type ReducerAction =
  | { type: 'INITIALISE'; payload?: ReducerState }
  | { type: 'LOGIN'; payload?: ReducerState }
  | { type: 'LOGOUT'; payload?: ReducerState }
  | { type: 'UPDATE_USER'; payload?: ReducerState };

type ReducerState = {
  isAuthenticated?: boolean;
  isInitialised?: boolean;
  user: GreenerProjectsUser | null; // Make sure user can be null
};

const initialAuthState: ReducerState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const authClientVars = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  cacheLocation: 'localstorage' as CacheLocation,
  useRefreshTokensFallback: true,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    ui_locales: 'en nl',
    responseMode: 'token',
  },
};

const auth0Client: Auth0Client = new Auth0Client(authClientVars);

const mapGreenerUser = (greenerUser: GreenerUser) => {
  return {
    id: greenerUser.userId,
    email: greenerUser.email,
    username: greenerUser.username,
    firstName: greenerUser.firstName,
    lastName: greenerUser.lastName,
    phone: greenerUser.phone,
    role: greenerUser.userRole,
    organisation: greenerUser.organisation,
    country: greenerUser.country,
  };
};

const mapUserInfo = (greenerUser: GreenerUser, auth0User: CAuth0User) => {
  return {
    avatar: auth0User.picture,
    auth0Id: auth0User.sub,
    ...mapGreenerUser(greenerUser),
  };
};

const getMeGQL = async () => {
  const token = await getTokenSilently();
  const requestHeaders = {
    Authorization: 'Bearer ' + token,
  };

  // We explicitly set up request here manually, instead of using the useGQLQuery hook.
  // This is not strictly necessary, but the useGQLQuery calls the useAuth hook, which we are preparing here.
  // We probably should not rely on the useAuth hook if it represent incomplete state.
  const { me: greenerUser, errors } = (await graphQLClient.request(
    GET_ME,
    {},
    requestHeaders
  )) as GetMeResponse;

  return { greenerUser, errors };
};

// When user is authenticated get all info and put in context
const getAndSetUserInfo = async (
  auth0Client: Auth0Client,
  logout: () => void,
  dispatch: (action: ReducerAction) => void,
  enqueueSnackbar: (message: string, options: OptionsObject) => SnackbarKey,
  t: (key: string) => string,
  setUserId: (userId: string) => void
) => {
  const auth0User = await auth0Client?.getUser();
  const { greenerUser, errors } = await getMeGQL();

  if (errors) {
    // Likely the token is expired, log in again.
    logout();
    enqueueSnackbar(t('auth:failedLogin'), { variant: 'error' });
    return;
  }
  // It all worked, put the user-info in the context
  if (auth0User && greenerUser) {
    const mappedUser = mapUserInfo(greenerUser, auth0User);

    dispatch({
      type: 'INITIALISE',
      payload: {
        isAuthenticated: true,
        user: mappedUser,
      },
    });
    setUserId(mappedUser.username);
  }
};

const reducer = (state: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload as ReducerState;
      //Set the user data on the sentry context so we can use it in the error reporting
      user &&
        Sentry.setUser({
          name: `${user.firstName} ${user.lastName}`,
          ...user,
        } as CAuth0User);

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload as ReducerState;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'UPDATE_USER': {
      const updatedUser = action.payload?.user as GreenerProjectsUser;

      return {
        ...state,
        user: updatedUser ? { ...state.user, ...updatedUser } : state.user,
      };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'Auth0',
  login: () => Promise.resolve(),
  logout: () => null,
  auth0Client,
  getTokenSilently: () => Promise.resolve(''),
  updateUserContext: () => Promise.resolve(), // Update the type if necessary
});

const getTokenSilently = async () => {
  try {
    return await auth0Client?.getTokenSilently();
  } catch (e) {
    // See https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/3
    // Race conditions (which empirically seem to be browser-specific) can cause the `getTokenSilently` method to be
    // called before `loginWithRedirect`. This is always wrong and leads to inconsistent authentication state.
    // Enforce login before fetching a token and throw the error so the browser can further deal with it.
    if ((e as { error: string }).error === 'login_required')
      await auth0Client?.loginWithRedirect();
    throw e;
  }
};

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  // noinspection JSCheckFunctionSignatures
  const [state, dispatch] = useReducer<Reducer<ReducerState, ReducerAction>>(
    reducer,
    initialAuthState
  );
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setUserId } = useAmplitude();

  const login = async () => {
    if (!auth0Client) return;
    await auth0Client.loginWithRedirect();
  };

  const logout = useCallback(() => {
    if (!auth0Client) return null;
    auth0Client.logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      },
    }); //  Clears the application session and performs a redirect
    dispatch({
      type: 'LOGOUT',
    });
    return null;
  }, []);

  // Get the latest version of the user and put in the state
  const updateUserContext = async () => {
    const { greenerUser } = await getMeGQL();
    greenerUser &&
      dispatch({
        type: 'UPDATE_USER',
        payload: {
          user: mapUserInfo(greenerUser, auth0Client.getUser()),
        },
      });
  };

  useEffect(() => {
    const initialise = async () => {
      // Do the auth prep
      await auth0Client.checkSession();
      const query = window.location.search;
      if (query.includes('code=') && query.includes('state=')) {
        // Process the login state
        await auth0Client.handleRedirectCallback();
        window.history.replaceState({}, document.title, '/');
      }

      const isAuthenticated = await auth0Client.isAuthenticated();
      isAuthenticated
        ? await getAndSetUserInfo(
            auth0Client,
            logout,
            dispatch,
            enqueueSnackbar,
            t,
            setUserId
          )
        : dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: null,
            },
          });
    };

    initialise();
  }, [enqueueSnackbar, dispatch, logout, t, setUserId]);

  if (!state.isInitialised) return <SplashScreen />;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Auth0',
        login,
        logout,
        auth0Client,
        getTokenSilently,
        updateUserContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
