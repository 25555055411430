import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useManagementSidebar } from './Management.sidebar';
import addNamespaces from '../../translations/add-namespaces';
import en from '../../translations/entities/en.json';
import nl from '../../translations/entities/nl.json';

addNamespaces('entities', { en, nl });

export const ManagementLayout = () => {
  const { t } = useTranslation();
  useManagementSidebar();

  return (
    <>
      <Helmet title={t('main:navigation.management')} />
      <Outlet />
    </>
  );
};
