import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

type Props = {
  title: string;
  subtitle?: string;
  text?: string;
};

const GenericSidebarHeader = ({ title, subtitle, text }: Props) => {
  return (
    <>
      <Box p={2} sx={{ background: (theme) => theme.palette.secondary.light }}>
        <Typography variant="h5">{title}</Typography>
        {subtitle && (
          <Typography variant="h6" sx={{ color: 'secondary.dark' }}>
            {subtitle}
          </Typography>
        )}
        {text && <Typography>{text}</Typography>}
      </Box>
      <Divider />
    </>
  );
};

export default GenericSidebarHeader;
