import { useAxiosQuery } from 'hooks/useAxiosQuery';
import { getQueryKeys } from 'services/queryKeys';
import { getMicrogridMapper } from '../microgrid.utils';
import { Microgrid } from '../microgrid.types';

export function useGetMicrogrid({
  projectId,
  microgridId,
}: {
  projectId: string;
  microgridId: string;
}) {
  const path = `/projects/${projectId}/microgrids/${microgridId}/`;
  const queryKeys = getQueryKeys(path);
  const { data, ...other } = useAxiosQuery(queryKeys.detail, path, {
    method: 'GET',
    mapper: (data: unknown) => getMicrogridMapper(data as Microgrid),
  });
  return { microgrid: data, ...other };
}
