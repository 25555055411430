import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GenericSidebarHeader } from 'components/layout/Sidebar';
import { useSidebarItems } from 'hooks/useSidebarItems';
import { OverviewIcon, LocationIcon } from 'components/icons';
import { useTranslation } from 'react-i18next';

export function useAssetsSidebar() {
  const { pathname } = useLocation();
  const { dispatchSidebar } = useSidebarItems();
  const { t } = useTranslation();

  useEffect(() => {
    const splitUri = pathname.split('/');

    const sidebar = {
      type: 'REGULAR',
      payload: {
        sidebarHeader: <GenericSidebarHeader title={t('unitControl:domain')} />,
        items: [
          {
            name: t('unitControl:navigation.overview'),
            path: 'assets',
            icon: OverviewIcon,
          },
          {
            name: t('unitControl:navigation.locations'),
            path: 'asset-location',
            icon: LocationIcon,
          },
        ],
      },
    };

    // Only render the sidebar if we are in the current path. This prevents this sidebar from being rendered in subroutes
    if (
      sidebar.payload.items.find(
        (item) => item.path === splitUri[splitUri.length - 1]
      )
    ) {
      dispatchSidebar(sidebar);
    }
  }, [pathname, dispatchSidebar, t]);
}
