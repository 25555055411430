import i18n from './index';

export default function addNamespaces(
  ns,
  resourceMap = {},
  deep = false,
  overwrite = false
) {
  if (!ns) {
    throw new Error('Must provide a namespace');
  }

  const resources = Object.entries(resourceMap);

  resources.forEach(([lng, resource]) => {
    if (!lng) {
      throw new Error('No language provided');
    }

    i18n.addResourceBundle(lng, ns, resource, deep, overwrite);
  });
}
