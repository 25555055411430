import React from 'react';
import customColors from '../../styles/colors';

export default function OfficeFolder(props) {
  const {
    height = 24,
    width = 24,
    strokeColor = customColors['secondary-dark'],
    strokeWidth = 1.5,
    fill = 'none',
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M23.231,12.435a1,1,0,0,0-.979-1.193H10.424a1,1,0,0,1-.978-.8L9.168,9.051a1,1,0,0,0-.978-.8H1.747A1,1,0,0,0,.769,9.444l2.593,13a1,1,0,0,0,.978.8H20.258a1,1,0,0,0,.978-.8C21.647,20.392,22.744,14.869,23.231,12.435Z"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M4.5,5.25l.02-3.5a1,1,0,0,1,1-1H18.483a1,1,0,0,1,1,1l.02,6.5"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M7.512 3.742L15.75 3.75"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M12 6.75L15.75 6.75"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
      </g>
    </svg>
  );
}
