import greenerBatteryIcon from 'components/icons/assetTypes/greener-battery.svg';
import dieselGeneratorIcon from 'components/icons/assetTypes/diesel-generator.svg';
import electricityGridIcon from 'components/icons/assetTypes/electricity-grid.svg';
import energyMeterIcon from 'components/icons/assetTypes/energy-meter.svg';
import constructionIcon from 'components/icons/endUseTypes/construction.svg';
import distributionBoxIcon from 'components/icons/assetTypes/distribution-box.svg';
import solarPanelIcon from 'components/icons/assetTypes/solar-panel.svg';
import hydrogenGeneratorIcon from 'components/icons/assetTypes/hydrogen-generator.svg';
import windTurbineIcon from 'components/icons/assetTypes/wind-turbine.svg';
import electricalSubstationIcon from 'components/icons/assetTypes/electrical-substation.svg';

import eventIcon from 'components/icons/endUseTypes/event.svg';
import offshoreIcon from 'components/icons/endUseTypes/offshore.svg';
import tradingIcon from 'components/icons/endUseTypes/trading.svg';
import gridDeferralIcon from 'components/icons/endUseTypes/grid-deferral.svg';
import evIcon from 'components/icons/endUseTypes/ev.svg';
import defaultLoadIcon from 'components/icons/endUseTypes/default-load.svg';
import { PROJECT_TYPES } from 'utils/constants';

/**
 ----------------------------------------------------------------
GRID SIZE & SPACING
-----------------------------------------------------------------
 */

export const GRID_SIZE_MAP = {
  s: 32,
  m: 40,
  l: 48,
};

export const GRID_SPACING = 160;

/**
 ----------------------------------------------------------------
NODE TYPES
-----------------------------------------------------------------
 */

export const NODE_TYPES = {
  LOAD: 'LOAD',
  BATTERY: 'BATTERY',
  GRID: 'GRID',
  ENERGY_METER: 'ENERGY_METER',
  GENERATOR: 'DIESEL_GENSET',
  DISTRIBUTION_BOX: 'DISTRIBUTION_BOX',
  SOLAR_PANEL: 'SOLAR_PANEL',
  HYDROGEN_GENERATOR: 'HYDROGEN_GENERATOR',
  WIND_TURBINE: 'WIND_TURBINE',
  ELECTRICAL_SUBSTATION: 'ELECTRICAL_SUBSTATION',
};

/**
 ----------------------------------------------------------------
ANONYMOUS NODES
-----------------------------------------------------------------
 */

export const ANONYMOUS_NODE = 'ANONYMOUS_NODE';

export const ANONYMOUS_NODES = [
  NODE_TYPES.ELECTRICAL_SUBSTATION,
  NODE_TYPES.WIND_TURBINE,
  NODE_TYPES.SOLAR_PANEL,
  NODE_TYPES.HYDROGEN_GENERATOR,
];

/**
 ----------------------------------------------------------------
CONNECTOR TYPES
-----------------------------------------------------------------
 */

export const CONNECTOR_TYPES = {
  POWER_LOCK: 'powerlock',
  EE: 'ee-1p',
  CEE: 'cee-3p',
  CABLE_LUG: 'cable_lug',
  SCREW_TERMINAL: 'screw_terminal',
};

/**
 * ----------------------------------------------------------------
 * NODE TYPES
 * -----------------------------------------------------------------
 * */
export const NODE_META_PROPS = [
  'xPos',
  'yPos',
  'sourcePosition',
  'targetPosition',
  'height',
  'width',
  'style',
  'minHeight',
  'minWidth',
  'position',
  'positionAbsolute',
  'icon',
];

/**
 * ----------------------------------------------------------------
 * NODE ICONS
 * -----------------------------------------------------------------
 * */

export const NODE_ICONS = {
  [NODE_TYPES.LOAD]: constructionIcon,
  [NODE_TYPES.BATTERY]: greenerBatteryIcon,
  [NODE_TYPES.GRID]: electricityGridIcon,
  [NODE_TYPES.ENERGY_METER]: energyMeterIcon,
  [NODE_TYPES.GENERATOR]: dieselGeneratorIcon,
  [NODE_TYPES.DISTRIBUTION_BOX]: distributionBoxIcon,
  [NODE_TYPES.HYDROGEN_GENERATOR]: hydrogenGeneratorIcon,
  [NODE_TYPES.WIND_TURBINE]: windTurbineIcon,
  [NODE_TYPES.ELECTRICAL_SUBSTATION]: electricalSubstationIcon,
  [NODE_TYPES.SOLAR_PANEL]: solarPanelIcon,
};

/**
 * ----------------------------------------------------------------
 * LOAD ICONS
 * -----------------------------------------------------------------
 * */

export const LOAD_ICONS = {
  [PROJECT_TYPES.EVENT]: eventIcon,
  [PROJECT_TYPES.OFFSHORE]: offshoreIcon,
  [PROJECT_TYPES.TRADING]: tradingIcon,
  [PROJECT_TYPES.GRID_DEFERRAL]: gridDeferralIcon,
  [PROJECT_TYPES.CONSTRUCTION]: constructionIcon,
  [PROJECT_TYPES.ELECTRIC_VEHICLES]: evIcon,
  [PROJECT_TYPES.OTHER]: defaultLoadIcon,
};

/**
 * ----------------------------------------------------------------
 * NODE SPECIFICATION FIELDS
 */

export const NODE_FIELDS = {
  LABEL: 'label',
  ICON: 'icon',
  DESCRIPTION: 'description',
  GREENER_UNIT_EXT_ID: 'greenerUnitExtId',
  STORAGE_CAPACITY: 'storageCapacity',
  RATED_ACTIVE_POWER: 'ratedActivePower',
  RATED_APPARENT_POWER: 'ratedApparentPower',
  ACTIVE_POWER_SETTING: 'activePowerSetting',
  CONTROLLED_BY_GREENER_UNIT_EXT_ID: 'controlledByGreenerUnitExtId',
  RELAY_ID: 'relayId',
  RATED_CURRENT: 'ratedCurrent',
  CONNECTIONS: 'connections',
  CONNECTIONS_NUMBER: 'connectionsNumber',
  IS_PRIMARY: 'isPrimary',
  IS_BATTERY_SWAP: 'isBatterySwap',
};

export const NODE_TYPE_FIELDS = {
  [NODE_TYPES.LOAD]: [
    NODE_FIELDS.LABEL,
    NODE_FIELDS.ICON,
    NODE_FIELDS.RATED_CURRENT,
    NODE_FIELDS.RATED_ACTIVE_POWER,
    NODE_FIELDS.CONNECTIONS,
  ],
  [NODE_TYPES.BATTERY]: [
    NODE_FIELDS.LABEL,
    NODE_FIELDS.GREENER_UNIT_EXT_ID,
    NODE_FIELDS.RATED_ACTIVE_POWER,
    NODE_FIELDS.RATED_APPARENT_POWER,
    NODE_FIELDS.STORAGE_CAPACITY,
    NODE_FIELDS.CONNECTIONS,
    NODE_FIELDS.IS_PRIMARY,
    NODE_FIELDS.IS_BATTERY_SWAP,
  ],
  [NODE_TYPES.GRID]: [
    NODE_FIELDS.LABEL,
    NODE_FIELDS.RATED_CURRENT,
    NODE_FIELDS.RATED_APPARENT_POWER,
    NODE_FIELDS.CONNECTIONS,
  ],
  [NODE_TYPES.ENERGY_METER]: [
    NODE_FIELDS.LABEL,
    NODE_FIELDS.GREENER_UNIT_EXT_ID,
    NODE_FIELDS.RATED_CURRENT,
    NODE_FIELDS.CONNECTIONS,
  ],
  [NODE_TYPES.GENERATOR]: [
    NODE_FIELDS.LABEL,
    NODE_FIELDS.RATED_ACTIVE_POWER,
    NODE_FIELDS.RATED_APPARENT_POWER,
    NODE_FIELDS.CONNECTIONS,
    NODE_FIELDS.ACTIVE_POWER_SETTING,
    NODE_FIELDS.CONTROLLED_BY_GREENER_UNIT_EXT_ID,
    NODE_FIELDS.RELAY_ID,
  ],
  [NODE_TYPES.DISTRIBUTION_BOX]: [
    NODE_FIELDS.LABEL,
    NODE_FIELDS.CONNECTIONS,
    NODE_FIELDS.RATED_CURRENT,
    NODE_FIELDS.CONNECTIONS_NUMBER,
  ],
  [NODE_TYPES.SOLAR_PANEL]: [NODE_FIELDS.LABEL, NODE_FIELDS.DESCRIPTION],
  [NODE_TYPES.ELECTRICAL_SUBSTATION]: [
    NODE_FIELDS.LABEL,
    NODE_FIELDS.DESCRIPTION,
  ],
  [NODE_TYPES.HYDROGEN_GENERATOR]: [NODE_FIELDS.LABEL, NODE_FIELDS.DESCRIPTION],
  [NODE_TYPES.WIND_TURBINE]: [NODE_FIELDS.LABEL, NODE_FIELDS.DESCRIPTION],
};

export const MICROGRID_STATUS = {
  VALID: 'valid',
  DRAFT: 'draft',
  INVALID: 'invalid',
  ARCHIVED: 'archived',
};
