import { Link as RouterLink } from 'react-router-dom';
import { SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProjectIcon } from '../../icons';
import { ResponsiveButton } from 'components/buttons';

export default function Home() {
  const { t } = useTranslation();

  return (
    <ResponsiveButton
      data-testid="projects-navigation"
      color="inherit"
      component={RouterLink}
      to="/projects"
      breakpoint="sm"
      icon={() => (
        <SvgIcon sx={{ fontSize: 18 }}>
          <ProjectIcon strokeColor="white" />
        </SvgIcon>
      )}
    >
      {`${t('navigation.projects')}`}
    </ResponsiveButton>
  );
}
