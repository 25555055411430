import { LoadingButton } from 'components/buttons';
import { useTranslation } from 'react-i18next';
import { SubmitButtonProps } from './buttons.types';

export function SubmitButton({
  color = 'primary',
  variant = 'contained',
  children,
  isSubmitting,
  onClick,
  ...props
}: SubmitButtonProps) {
  const { t } = useTranslation();
  const buttonContent = children ? children : t('components:form.submit');

  return (
    <LoadingButton
      type="submit"
      color={color}
      variant={variant}
      onClick={onClick}
      loading={isSubmitting}
      style={{ whiteSpace: 'nowrap' }}
      {...props}
    >
      {buttonContent}
    </LoadingButton>
  );
}
