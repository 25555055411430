import addNamespaces from '../../translations/add-namespaces';
import projectsEn from '../../translations/projects/en.json';
import projectsNl from '../../translations/projects/nl.json';
import assetsEn from '../../translations/unitControl/en.json';
import assetsNl from '../../translations/unitControl/nl.json';
import analyticsEn from '../../translations/analytics/en.json';
import analyticsNl from '../../translations/analytics/nl.json';

addNamespaces('projects', { en: projectsEn, nl: projectsNl });
addNamespaces('unitControl', { en: assetsEn, nl: assetsNl });
addNamespaces('analytics', { en: analyticsEn, nl: analyticsNl });
