import React from 'react';

export default function Report(props) {
  const {
    height = 24,
    width = 24,
    strokeColor = 'black',
    strokeWidth = 1.5,
    fill = 'none',
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M21.748,21.748a1.5,1.5,0,0,1-1.5,1.5h-18a1.5,1.5,0,0,1-1.5-1.5V2.248a1.5,1.5,0,0,1,1.5-1.5h15a1.5,1.5,0,0,1,1.047.426l3,2.883a1.5,1.5,0,0,1,.453,1.074Z"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M16.498 9.748L17.248 9.748"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M14.998 5.248L17.248 5.248"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M13.498 14.248L17.248 14.248"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M5.248 18.748L17.248 18.748"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M3.748 8.998 A4.500 4.500 0 1 0 12.748 8.998 A4.500 4.500 0 1 0 3.748 8.998 Z"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
        <path
          d="M4.544 11.467L8.248 8.998 8.248 4.498"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        ></path>
      </g>
    </svg>
  );
}
