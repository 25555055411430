import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';

export const useBreakpoint = () => {
  const theme = useTheme();
  const breakpoints = theme.breakpoints;

  const isXs = useMediaQuery(breakpoints.only('xs'));
  const isSm = useMediaQuery(breakpoints.only('sm'));
  const isMd = useMediaQuery(breakpoints.only('md'));
  const isLg = useMediaQuery(breakpoints.only('lg'));
  const isXl = useMediaQuery(breakpoints.only('xl'));

  const isDesktop = isLg || isXl;

  const width: string = useMemo(() => {
    if (isXs) return 'xs';
    if (isSm) return 'sm';
    if (isMd) return 'md';
    if (isLg) return 'lg';
    if (isXl) return 'xl';
    return '';
  }, [isXs, isSm, isMd, isLg, isXl]);

  const orientation: string = useMediaQuery('(orientation: portrait)')
    ? 'portrait'
    : 'landscape';

  return { width, orientation, breakpoints, isDesktop };
};
