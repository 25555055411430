/**
 * This module contains queries and mutations for Users.
 */

import { gql } from 'graphql-request';

export const USERS_KEY = 'USERS';
export const USER_FORM_OPTIONS_KEY = 'USER_FORM_OPTIONS';

export const READ_USERS = gql`
  query {
    users {
      firstName
      lastName
      email
      phone
      organisation {
        externalIdentifier
      }
      username
      userId
    }
  }
`;

export const GET_SELECT_OPTIONS = gql`
  query GetFormSelectInputs {
    userRoles {
      externalIdentifier
      userRoleId
    }
    organisations {
      externalIdentifier
      organisationId
    }
    countries {
      name
      countryId
    }
  }
`;

export const GET_USER = gql`
  query ($username: String!) {
    user(username: $username) {
      firstName
      lastName
      username
      email
      phone
      userId
      userRoleId
      organisation {
        organisationId
        name
      }
      country {
        countryId
        name
      }
    }
  }
`;

export const GET_ME = gql`
  query {
    me {
      userId
      username
      firstName
      lastName
      email
      phone
      userRole {
        userRoleId
        externalIdentifier
        description
        canDeleteProjects
        canManageEventProjects
        canManageTradingProjects
        canManageGridDeferralProjects
        canManageGreenerUnitInstances
        canManageMicrogrids
        canManageUsers
        canManageUserRoles
        canManageOrganisations
        canManageGreenerUnits
        canAssignGreenerUnitInstances
        canSeeOverviewData
        canSeeAnalyticalData
        canSeeGreenerEyeConfiguration
        canSendDirectCommands
        canSendTradingCommands
        canStartOperatorPrograms
        canConfigureGreenerEyes
        canRequestProjectReports
        canRequestTradingReports
        canRequestAnalyticalReports
      }
      organisation {
        organisationId
        externalIdentifier
        name
      }
      country {
        countryId
        name
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $countryId: Int!
    $email: String50!
    $firstName: String50!
    $lastName: String50!
    $organisationId: Int!
    $phone: String50!
    $userRoleId: Int!
    $username: String50!
    $userId: Int!
  ) {
    updateUser(
      user: {
        countryId: $countryId
        email: $email
        firstName: $firstName
        lastName: $lastName
        organisationId: $organisationId
        phone: $phone
        userRoleId: $userRoleId
        username: $username
      }
      userId: $userId
    ) {
      user {
        username
      }
    }
  }
`;

export const INVITE_USER = gql`
  mutation InviteUser(
    $countryId: Int!
    $email: String50!
    $firstName: String50!
    $lastName: String50!
    $organisationId: Int!
    $phone: String50!
    $userRoleId: Int!
    $username: String50!
  ) {
    inviteUser(
      user: {
        countryId: $countryId
        email: $email
        firstName: $firstName
        lastName: $lastName
        organisationId: $organisationId
        phone: $phone
        userRoleId: $userRoleId
        username: $username
      }
    ) {
      user {
        username
      }
    }
  }
`;
