import { get } from 'lodash';

// ---------------------------------------------------------------------------------------------------------------------
// Battery data related
// ---------------------------------------------------------------------------------------------------------------------

export function batteryTimeSeriesArrayToDict(arr) {
  return {
    soc: arr[1],
    soh: arr[2],
    temperature: arr[3],
    activePowerIn: arr[4],
    reactivePowerIn: arr[5],
    currentL1In: arr[6],
    currentL2In: arr[7],
    currentL3In: arr[8],
    voltageL1L2In: arr[9],
    voltageL2L3In: arr[10],
    voltageL3L1In: arr[11],
    frequencyIn: arr[12],
    activePowerOut: arr[13],
    reactivePowerOut: arr[14],
    currentL1Out: arr[15],
    currentL2Out: arr[16],
    currentL3Out: arr[17],
    voltageL1L2Out: arr[18],
    voltageL2L3Out: arr[19],
    voltageL3L1Out: arr[20],
    frequencyOut: arr[21],
    activePowerInverter: arr[22],
    reactivePowerInverter: arr[23],
    maxInputCurrent: arr[24],
    constantPowerSetpoint: arr[25],
  };
}

export function batteryStateDataArrayToDict(arr) {
  return {
    systemStatus: arr[1],
    alarm: arr[2],
    warning: arr[3],
    remoteControl: arr[4],
    systemMode: arr[5],
    acceptValues: arr[6],
    inverterMode: arr[7],
    inverterStarted: arr[8],
    inputConnected: arr[9],
    systemOnBackupBattery: arr[10],
    turnedOnGenSet: arr[11],
    turnedOffGenSet: arr[12],
  };
}

export function getAlfenSystemModeString(mode) {
  const modes = [
    'Standby',
    'P/Q',
    'P(f)',
    'P(f)Q(U)',
    'P(f)Q(U) / PQ',
    'Peakshaving',
    'Microgrid',
  ];

  if (0 <= mode && mode <= modes.length - 1) {
    return modes[mode];
  }

  throw Error('Unknown system mode identified by ' + mode.toString());
}

export function getAlfenSystemStatusString(status) {
  const statusses = [
    'Off',
    'On',
    'Busy',
    'Waiting for reset',
    'Suspended',
    'Reset busy',
    'Starting up',
  ];

  if (0 <= status && status <= statusses.length - 1) {
    return statusses[status];
  }

  throw Error('Unknown system status identified by ' + status.toString());
}

/**
 *
 * @param {object[]} array
 * @param {string} key - represents the value that wants to be retrieved from each object in the array
 * @returns {array}
 */
export const getSingleKeyFromObjectArray = (
  array,
  path,
  defaultValue = null
) => {
  if (!array || !array.length) {
    return [];
  } else {
    return array.map((object) => get(object, path, defaultValue));
  }
};
