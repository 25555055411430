import {
  useRef,
  useState,
  useEffect,
  MouseEvent,
  TouchEvent,
  useCallback,
} from 'react';
import DatePicker from 'react-datepicker';
import {
  isBefore,
  isPast,
  roundToNearestMinutes,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
} from 'date-fns';
//@ts-expect-error missing lodash types
import _ from 'lodash';
import { SHORT_DATE_TIME_FORMAT } from 'utils/constants';
import 'react-datepicker/dist/react-datepicker.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemText,
  Popper,
  Alert,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Panel from 'components/panels/Panel/Panel';
import TimeButton from 'components/buttons/TimeButton';
import useScreenSize from 'hooks/useScreenSize';
import { useTimerange } from 'hooks/useTimerange';
import { ChevronDown, ChevronUp } from 'react-feather';
import { TimerangePickerProps, SelectMenuProps } from './TimerangePicker.types';
import { formatRangeCap, isRangeExceedingCap } from './TimerangePicker.utils';
import { useSnackbar } from 'notistack';

function SelectMenu({
  options,
  onClick,
  selectedOption,
  availableRange,
}: SelectMenuProps) {
  const handleSelectedIndex = (timePeriod: {
    value: number;
    label: string;
  }) => {
    onClick(timePeriod);
  };

  function isInValidOption(option: { value: number; label: string }) {
    const optionFrom = new Date(Date.now() - option.value);

    if (!availableRange?.from) {
      return true;
    }

    if (isPast(availableRange?.to)) {
      return true;
    } else {
      return isBefore(optionFrom, availableRange?.from);
    }
  }

  return (
    <List>
      {options.map((option) => (
        <ListItemButton
          key={option.label}
          selected={selectedOption?.value === option?.value}
          onClick={() => handleSelectedIndex(option)}
          disabled={isInValidOption(option)}
        >
          <ListItemText
            primary={option.label}
            primaryTypographyProps={{ variant: 'h1' }}
          />
        </ListItemButton>
      ))}
    </List>
  );
}

/**
 * Widget for selecting a time range.
 * @param range The selected range
 * @param applyRange Function to be called when a new range should be applied
 * @param resetRange Function to reset the range to some preferred state.
 * @param availableRange Min and max datetimes that can be selected.
 * @param showApplyAvailableRange If true, shows a button to set the range to the whole available range.
 * @returns {JSX.Element}
 */

export function TimerangePicker({
  range,
  applyRange,
  resetRange,
  availableRange,
  showApplyAvailableRange = false,
  showTimes = true,
  rangeCap,
  buttonTextRangeFormatter,
}: TimerangePickerProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const formattedRangeCap = formatRangeCap(rangeCap);
  const rangeCapMsg = !!formattedRangeCap
    ? t(formattedRangeCap.msg, formattedRangeCap.options)
    : null;

  //State to store wether the current rage exceeds a rangeCap (if provided)
  const [exceedsCap, setExceedsCap] = useState(false);

  // Store a copy of the provided range.
  // We need this to see if the provided range was changed through different means than via this component.
  // We prefer this approach over a useEffect as it requires less rerenders.
  const [providedRange, setProvidedRange] = useState({
    from: range?.from,
    to: range?.to,
  });

  /*
  State used to change the selected date in the input, but preventing the range to change immediately,
  thus preventing a rerender. Range should only change when clicking on "apply range",
  or by clicking an option in the "relative ranges" menu
  */
  const [localRange, setLocalRange] = useState({
    from: range?.from,
    to: range?.to,
  });

  const anchorRef = useRef<HTMLDivElement | null>(null);
  const { breakpoints } = useScreenSize();
  const { now } = useTimerange(availableRange);

  // Make sure to update the state once a new valid range is passed.
  useEffect(() => {
    if (!!range?.from && !_.isEqual(range, providedRange)) {
      // A valid new range has been passed via the prop! Update the copy and local value.
      setProvidedRange(range);
      setLocalRange(range);
    }
  }, [range?.from, providedRange, setProvidedRange, setLocalRange, range]);

  const rangeFromNowOptions = [
    { value: 3600000, label: t('widgets.timerangePicker.lastHour') },
    { value: 10800000, label: t('widgets.timerangePicker.last3Hours') },
    { value: 21600000, label: t('widgets.timerangePicker.last6Hours') },
    { value: 32400000, label: t('widgets.timerangePicker.last9Hours') },
    { value: 43200000, label: t('widgets.timerangePicker.last12Hours') },
    { value: 86400000, label: t('widgets.timerangePicker.lastDay') },
    { value: 172800000, label: t('widgets.timerangePicker.last2Days') },
    { value: 604800000, label: t('widgets.timerangePicker.last7Days') },
    { value: 2592000000, label: t('widgets.timerangePicker.last30Days') },
  ];

  /* 
  Determine if an option should be selected based on the difference between defaultValue.to and defaultValue.from 
  Compares the values of the rangeFromNowOptions array and also checks if the current value relative from "now",
  if not it means that it is the past.
  */
  const selectedOption = rangeFromNowOptions.find(
    (option) =>
      option.value === (range?.to as number) - (range?.from as number) &&
      roundToNearestMinutes(range?.to ?? new Date()).getTime() ===
        roundToNearestMinutes(now).getTime()
  );

  // Opens/close the popper
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Closes the popper
  const handleClose = (
    event: globalThis.MouseEvent | globalThis.TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
  };

  // Resets the range to the default range
  const handleReset = (event: MouseEvent | TouchEvent) => {
    if (resetRange) resetRange();

    //Double casting since handle close expects the global mouse events,
    // but Button expects the react mouse events.

    handleClose(
      event as unknown as globalThis.MouseEvent | globalThis.TouchEvent
    );
  };

  // Sets the 'from' value of range to apply, adjusts time to 00:00 if times arent selectable
  function onFromChange(from: Date | number) {
    if (from >= localRange.to) {
      return;
    }

    if (
      !!rangeCap &&
      isRangeExceedingCap({ from, to: localRange.to }, rangeCap)
    ) {
      setExceedsCap(true);
    } else {
      setExceedsCap(false);
    }

    // Adjust time to 00:00 if times aren't selectable
    if (!showTimes) {
      const startOfDay = setHours(
        setMinutes(setSeconds(setMilliseconds(from, 0), 0), 0),
        0
      );
      setLocalRange({
        ...localRange,
        from: startOfDay,
      });
    } else {
      setLocalRange({
        ...localRange,
        from,
      });
    }
  }

  // Sets the 'to' value of range to apply
  function onToChange(to: number | Date) {
    if (localRange.from >= to) {
      return;
    }

    if (
      !!rangeCap &&
      isRangeExceedingCap({ from: localRange.from, to }, rangeCap)
    ) {
      setExceedsCap(true);
    } else {
      setExceedsCap(false);
    }

    if (!showTimes) {
      const endOfDay = setMilliseconds(
        setSeconds(setMinutes(setHours(to, 23), 59), 59),
        999
      );

      setLocalRange({
        ...localRange,
        to: endOfDay,
      });
    } else {
      setLocalRange({
        ...localRange,
        to,
      });
    }
  }

  // Apply the selected range
  function onClickApplyChanges() {
    applyRange(localRange);
    setOpen(false);
  }

  // Sets range through the relative ranges menu
  const onChangeRelative = (timePeriod: { value: number; label: string }) => {
    const newValue = {
      //@ts-expect-error date conversion
      from: now - timePeriod.value,
      to: now,
    };

    setLocalRange(newValue);
    applyRange(newValue);
    setOpen(false);
  };

  // Sets range to the available range passed as a prop
  const applyAvailableRange = () => {
    setLocalRange(availableRange);
    applyRange(availableRange);
    setOpen(false);
  };

  //Reset the range if the provided range exceeds the rangeCap
  useEffect(() => {
    if (!!rangeCap && !!rangeCapMsg && isRangeExceedingCap(range, rangeCap)) {
      enqueueSnackbar(rangeCapMsg, {
        variant: 'info',
      });
      resetRange();
    }
  }, [
    resetRange,
    range,
    rangeCap,
    rangeCapMsg,
    enqueueSnackbar,
    formatRangeCap,
    isRangeExceedingCap,
  ]);

  return (
    <>
      <div ref={anchorRef}>
        <TimeButton
          range={localRange}
          onClick={handleToggle}
          selectedOption={selectedOption}
          variant="outlined"
          endIcon={!!open ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          rangeFormatter={buttonTextRangeFormatter}
        />
      </div>
      <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseUp">
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-end"
          style={{ minHeight: 300, maxWidth: breakpoints.sm }}
        >
          <Panel>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                id="datePickerContainer"
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: 20,
                }}
              >
                <DatePicker
                  key="from"
                  selected={localRange.from ? new Date(localRange.from) : null}
                  onChange={(date: Date | null) => onFromChange(date as Date)}
                  selectsStart
                  showTimeSelect={showTimes}
                  showTimeInput={showTimes}
                  dateFormat={SHORT_DATE_TIME_FORMAT}
                  minDate={
                    availableRange?.from ? new Date(availableRange?.from) : null
                  }
                  maxDate={localRange.to ? new Date(localRange.to) : null}
                  disabled={availableRange === null}
                  customInput={
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{ marginBottom: 10 }}
                    />
                  }
                />
                <DatePicker
                  key="to"
                  selected={localRange.to ? new Date(localRange.to) : null}
                  onChange={(date: Date | null) => onToChange(date as Date)}
                  selectsEnd
                  showTimeSelect={showTimes}
                  showTimeInput={showTimes}
                  minDate={localRange.from ? new Date(localRange.from) : null}
                  maxDate={
                    availableRange?.to ? new Date(availableRange?.to) : null
                  }
                  dateFormat={SHORT_DATE_TIME_FORMAT}
                  disabled={availableRange === null}
                  customInput={
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{ marginRight: 20 }}
                    />
                  }
                />

                {showApplyAvailableRange && (
                  <Button color="primary" onClick={applyAvailableRange}>
                    {t('widgets.timerangePicker.availableRange')}
                  </Button>
                )}
              </div>
              <SelectMenu
                options={rangeFromNowOptions}
                onClick={onChangeRelative}
                selectedOption={selectedOption}
                availableRange={availableRange}
              />
            </div>
            <Stack spacing={1}>
              {exceedsCap && !!rangeCapMsg && (
                <Alert severity="warning">{rangeCapMsg}</Alert>
              )}
              <Stack direction="row" justifyContent="space-between">
                <Button
                  onClick={onClickApplyChanges}
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={exceedsCap}
                >
                  {t('widgets.timerangePicker.applyChange')}
                </Button>
                <Button
                  onClick={handleReset}
                  variant="text"
                  color="primary"
                  size="small"
                >
                  {t('widgets.timerangePicker.reset')}
                </Button>
              </Stack>
            </Stack>
          </Panel>
        </Popper>
      </ClickAwayListener>
    </>
  );
}
