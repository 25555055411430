import { ReactNode, createContext, useEffect } from 'react';
import {
  init,
  Identify,
  setDeviceId,
  setUserId,
  identify,
  track,
} from '@amplitude/analytics-browser';
import UAParser from 'ua-parser-js';
import { EventProps } from './contexts.types';

// Amplitutde initialization
const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY || '';

init(AMPLITUDE_API_KEY, {
  defaultTracking: true,
});

type AmplitudeContextProps = {
  logEvent: (props: EventProps) => void;
  setUserId: (userId: string) => void;
  setDeviceId: (deviceId: string) => void;
};

const logEvent = ({ eventType, eventProperties }: EventProps) => {
  const event = {
    event_type: eventType,
    event_properties: { source: 'Projects', ...eventProperties },
  };

  track(event);
};

// Context
export const AmplitudeContext = createContext<AmplitudeContextProps>({
  logEvent,
  setUserId,
  setDeviceId,
});

export const AmplitudeProvider = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    const parser = new UAParser();
    const userEvent = new Identify();
    const browser = parser.getBrowser();
    const os = parser.getOS();
    const device = parser.getDevice();

    // Setting user properties
    userEvent.set('osName', os.name ?? 'Unknown OS');
    userEvent.set('osVersion', os.version ?? 'Unknown Version');
    userEvent.set('deviceBrand', device.vendor ?? 'Unknown Brand');
    userEvent.set('deviceModel', device.model ?? 'Unknown Model');
    userEvent.set('browserName', browser.name ?? 'Unknown Browser');
    userEvent.set('browserVersion', browser.version ?? 'Unknown Version');
    userEvent.set('source', 'Projects');

    identify(userEvent);
  }, []);

  return (
    <AmplitudeContext.Provider value={{ logEvent, setUserId, setDeviceId }}>
      {children}
    </AmplitudeContext.Provider>
  );
};

export const ProductionAmplitudeProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  if (process.env.NODE_ENV === 'production') {
    return <AmplitudeProvider>{children}</AmplitudeProvider>;
  }
  return children;
};
