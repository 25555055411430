import PropTypes from 'prop-types';
import { getSingleKeyFromObjectArray } from 'utils/mappers';
import { BATTERY_TS_MODEL } from 'utils/constants';
import { formatPercentage } from 'utils/formatters';

function track(...args) {
  if (process.env.NODE_ENV !== 'production' || !window.gtag) {
    return undefined;
  }

  window.gtag(...args);
}

export function pageview(props) {
  track('config', process.env.REACT_APP_GA_MEASUREMENT_ID, props);
}

export function event(type, props) {
  track('event', type, props);
}

export const AGGREGATOR_FUNCTIONS = {
  sum: (arr) => arr.reduce((a, b) => a + b, 0),
  mean: (arr) => arr.reduce((a, b) => a + b, 0) / arr.length,
  min: (arr) => Math.min(...arr),
  max: (arr) => Math.max(...arr),
  first: (arr) => arr.find((e) => e !== null),
  last: (arr) => arr.reverse().find((e) => e !== null),
};

export function getNetTotalEnergyCountFromRange(energyData) {
  PropTypes.checkPropTypes(getNetTotalEnergyCountFromRangePropTypes, {
    energyData,
  });
  /* Extract 'y' from energy data*/
  const energyFirstArr = getSingleKeyFromObjectArray(energyData?.first, 'y');

  /* Get the first value of the array, assuming that we have data for the whole range that the unit was active */
  const firstEnergyCount = AGGREGATOR_FUNCTIONS.first(energyFirstArr);
  const energyLastArr = getSingleKeyFromObjectArray(energyData?.LAST, 'y');
  /* Get the last value of the array, assuming that we have data for the whole range that the unit was active */
  const lastEnergyCount = AGGREGATOR_FUNCTIONS.last(energyLastArr);

  if (!lastEnergyCount || !firstEnergyCount) {
    return NaN;
  } else {
    return lastEnergyCount - firstEnergyCount;
  }
}

const getNetTotalEnergyCountFromRangePropTypes = {
  energyData: PropTypes.shape({
    first: PropTypes.object,
    last: PropTypes.object,
  }),
};

export const getSystemEfficiency = (data) => {
  const energyIn = data[BATTERY_TS_MODEL.ACTIVE_POWER_IN][0].integral;
  const energyOut = data[BATTERY_TS_MODEL.ACTIVE_POWER_OUT][0].integral;
  // the converter measurent of 2.b batteries is not accurate. Later we can improve this calculation by specifying the battery type
  // const energyStored = (data[BATTERY_TS_MODEL.SOC][0].last - data[BATTERY_TS_MODEL.SOC][0].last) * unit.storageCapacity;
  return formatPercentage(energyOut / energyIn);
};
