import { isBefore, isFuture, isPast, max, min } from 'date-fns';
import {
  DEVICE_TYPE_IDS,
  maxTimeWithoutResponse,
  PROJECT_UNIT_INSTANCES_DATE_STATUS,
} from './constants';

import { Unit, UnitInstance, Project } from 'types';

import { ConfigData, UnitTsData } from './utils.types';

export function extractUnitsByDevice(
  units: Unit[],
  deviceTypeId: number
): Unit[] {
  if (!Object.values(DEVICE_TYPE_IDS).includes(deviceTypeId)) {
    throw new Error(`Invalid device type id: ${deviceTypeId}`);
  }

  return units.filter(
    (unit) =>
      unit.watcherType.deviceTypeId === deviceTypeId ||
      unit.watcherType.deviceType?.deviceTypeId === deviceTypeId
  );
}

export function extractBatteries(units: Unit[]): Unit[] {
  return extractUnitsByDevice(units, DEVICE_TYPE_IDS.BATTERY);
}

export function extractEnergyMeters(units: Unit[]): Unit[] {
  return extractUnitsByDevice(units, DEVICE_TYPE_IDS.ENERGY_METER);
}

export function filterActiveUnits(
  unitInstances: UnitInstance[],
  latestDate: Date
): Unit[] {
  const activeUnits: Unit[] = [];
  const now = new Date();
  const refDate = new Date(Math.min(...[latestDate.getTime(), now.getTime()]));

  unitInstances.forEach((instance) => {
    if (
      new Date(instance.tsStart) < refDate &&
      new Date(instance.tsEnd) > refDate
    ) {
      activeUnits.push(instance.greenerUnit);
    }
  });
  return activeUnits;
}

export const getExternalIdentifierFromUnitInstance = (
  instance: UnitInstance
): string | undefined => instance && instance.greenerUnit.externalIdentifier;

export const getUnitTypeFromUnitInstance = (
  instance: UnitInstance
): number | undefined =>
  instance &&
  (instance.greenerUnit.watcherType.deviceTypeId ||
    instance.greenerUnit.watcherType.deviceType?.deviceTypeId);

export function constructConfigTableObject(
  configData: ConfigData[]
): Record<string, unknown> {
  const result: Record<string, unknown> = {};
  configData.forEach((configOption) => {
    result[configOption.name] = configOption.val;
  });

  return result;
}
export function getIsOnline(unitTsData: UnitTsData): boolean {
  const before = Date.now() - maxTimeWithoutResponse;
  const tsMeasured = unitTsData?.ts_measured
    ? unitTsData?.ts_measured * 1000
    : 0;

  return isBefore(before, tsMeasured);
}

export function getUnitInstancesDateStatus(
  unitInstances: UnitInstance[],
  unitExtId: string
): string | undefined {
  let unitWillBecomeActive = false;
  let unitWasActive = false;
  let unitIsActive = false;

  const unitSelectedInstances = unitInstances.filter(
    (instance) => instance.greenerUnit.externalIdentifier === unitExtId
  );
  for (const instance of unitSelectedInstances) {
    if (
      isPast(new Date(instance.tsStart)) &&
      isFuture(new Date(instance.tsEnd))
    ) {
      unitIsActive = true;
    } else if (!instance.endDate && isPast(new Date(instance.tsStart))) {
      unitIsActive = true;
    } else if (isFuture(new Date(instance.tsStart))) {
      unitWillBecomeActive = true;
    } else if (instance.endDate && isPast(new Date(instance.tsEnd))) {
      unitWasActive = true;
    }
  }

  if (unitIsActive) {
    return PROJECT_UNIT_INSTANCES_DATE_STATUS.active;
  } else if (unitWasActive && unitWillBecomeActive) {
    return PROJECT_UNIT_INSTANCES_DATE_STATUS.inBetween;
  } else if (unitWillBecomeActive) {
    return PROJECT_UNIT_INSTANCES_DATE_STATUS.future;
  } else if (unitWasActive) {
    return PROJECT_UNIT_INSTANCES_DATE_STATUS.past;
  }
}

export function determineUnitAvailableDateRange(
  project: Project,
  unitInstances: UnitInstance[],
  unitExtId: string
): { from: Date | null; to: Date | null } {
  if (!unitInstances.length) return { from: null, to: null };

  const currentOrPastInstances = unitInstances.filter(
    (instance) =>
      instance.greenerUnit.externalIdentifier === unitExtId &&
      isPast(new Date(instance.tsStart))
  );

  if (!currentOrPastInstances.length) {
    const firstStartDate = min(
      unitInstances.map((instance) => new Date(instance.tsStart))
    );

    const firstInstance = unitInstances.find(
      (instance) =>
        new Date(instance.tsStart).getTime() === firstStartDate.getTime()
    );

    return {
      from: new Date(firstInstance!.tsStart),
      to: firstInstance!.tsEnd ? new Date(firstInstance!.tsEnd) : null,
    };
  }

  const latestStartDate = max(
    currentOrPastInstances.map((instance) => new Date(instance.tsStart))
  );

  const latestInstance = currentOrPastInstances.find(
    (instance) =>
      new Date(instance.tsStart).getTime() === latestStartDate.getTime()
  );

  if (project.tsEnd && isPast(new Date(project.tsEnd))) {
    return {
      from: new Date(latestInstance!.tsStart || project.tsStart!),
      to: new Date(latestInstance!.tsEnd || project.tsEnd!),
    };
  }

  return {
    from: new Date(latestInstance!.tsStart),
    to: latestInstance!.tsEnd ? new Date(latestInstance!.tsEnd) : null,
  };
}
