/**
 * This module contains queries for project types.
 */

import { gql } from 'graphql-request';

export const MY_PROJECTS_KEY = 'my_projects';
export const READ_MY_PROJECTS = gql`
  query {
    myProjects {
      projectId
      externalIdentifier
      name
      tsStart
      tsEnd
      location
      projectTypeId
      location
      projectOrganisation {
        organisationId
        organisation {
          externalIdentifier
          name
        }
        organisationRoleId
        organisationRole {
          externalIdentifier
          name
        }
      }
      projectGreenerUnits {
        greenerUnit {
          externalIdentifier
          name
        }
      }
    }
  }
`;

export const MY_PROJECTS_TABLE_KEY = 'my_projects_table';
export const READ_MY_PROJECTS_TABLE = gql`
  query {
    myProjects {
      projectId
      externalIdentifier
      name
      tsStart
      tsEnd
      location
      projectTypeId
      location
      projectOrganisation {
        organisationId
        organisation {
          externalIdentifier
          name
        }
        organisationRoleId
        organisationRole {
          externalIdentifier
          name
        }
      }
      projectGreenerUnits {
        projectUnitInstanceId
        tsStart
        tsEnd
        greenerEyeId
        greenerUnitId
        greenerUnit {
          externalIdentifier
          name
        }
      }
    }
  }
`;

export const PROJECT_KEY = (projectIdentifier) => [
  'project',
  'externalIdentifier',
  projectIdentifier,
];
export const READ_PROJECT = gql`
  query ProjectInfo($externalIdentifier: String) {
    project(externalIdentifier: $externalIdentifier) {
      projectId
      externalIdentifier
      name
      projectLogoUrl
      tsStart
      tsEnd
      location
      projectType {
        externalIdentifier
        name
        projectTypeId
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject(
    $projectName: String50!
    $externalIdentifier: String50
    $projectLogoUrl: String255
    $tsStart: DateTime!
    $tsEnd: DateTime
    $projectTypeId: Int!
    $location: Geometry
  ) {
    createProject(
      project: {
        name: $projectName
        externalIdentifier: $externalIdentifier
        projectLogoUrl: $projectLogoUrl
        tsStart: $tsStart
        tsEnd: $tsEnd
        projectTypeId: $projectTypeId
        location: $location
      }
    ) {
      project {
        externalIdentifier
        projectId
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($project: ProjectInput!, $projectId: Int!) {
    updateProject(project: $project, projectId: $projectId) {
      project {
        projectId
        externalIdentifier
        name
        projectLogoUrl
        tsStart
        tsEnd
        projectTypeId
        location
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($projectId: Int!) {
    deleteProject(projectId: $projectId) {
      projectId
    }
  }
`;
